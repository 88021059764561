<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
      <img
        :src="require('../assets/img/RR1.png')"
        alt="Logotipo Truework"
        class="brand-image"
        style="opacity: 1; cursor: pointer;"
        @click="redirectToLogin()"
      />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "HeaderUnsigned",
  mounted() {
    let a = document.querySelectorAll('.content-wrapper,.main-footer,.main-header');
    a.forEach(b => {
      b.style = "margin-left: 0px!important;";
    });
  },
  methods: {
    redirectToLogin(){
      window.location="/login";
    }
  }
};
</script>