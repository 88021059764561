<template>
  <div>
    <b-modal v-if="employeeModal" v-model="employeeModal" hide-footer size="xl">
      <template #modal-header="{ close }">
        <h5>Treinamentos</h5>
        <b-button variant="link" @click="close()">
          <span>X</span>
        </b-button>
      </template>
      <form @submit.prevent="saveTrainings">
        <div>
          <b-table
            striped
            hover
            :items="selectedEmployee.trainings"
            :fields="fields_training"
            ref="employee_trainings_table"
          >
            <template
              v-slot:cell(name)="data"
            >
              <select
                v-model="
                data.item.name"
                class="form-control"
                id="trainingSelect"
                :class="data.item.errors.name ? 'error-input' : ''"
                @change="onSelectTraining(data.item)"
              >
                <option value="">Selecione</option>
                <option
                  v-for="
                    training in customerTrainings"
                    :key="training.id"
                    :value="training.name"
                    :disabled="training.selected
                  "
                >
                  {{ training.name }}
                </option>
              </select>
              <div v-if="data.item.errors.name" class="error-message">
                {{ data.item.errors.name }}
              </div>
            </template>
            <template
              v-slot:cell(session)="data"
            >
              <select
                v-model="data.item.session"
                class="form-control"
                id="trainingScheduleSelect"
                :class="data.item.errors.session ? 'error-input' : ''"
                @change="onSelectSession(data.item)"
              >
                <option value="">Selecione</option>
                <option
                  v-for="
                  schedule in data.item.schedules"
                  :key="schedule.id"
                  :value="schedule"
                >
                  {{ "Instrutor: " + schedule.instructor + ". Início: " + schedule.t_start + ". Término: " + schedule.t_end}}
                </option>
              </select>
              <div v-if="data.item.errors.session" class="error-message">
                {{ data.item.errors.session }}
              </div>
            </template>
            <template v-slot:cell(expiration)="data">
              <b-form-input
                type="date"
                v-model="data.item.expiration"
                disabled
                placeholder="Validade do treinamento"
                id="trainingExpiration"
                :class="data.item.errors.expiration ? 'error-input' : ''"
              />
              <div v-if="data.item.errors.expiration" class="error-message">
                {{ data.item.errors.expiration }}
              </div>
            </template>
            <template
                v-slot:cell(attendence)="data"
              >
                <b-form-input
                  type="number"
                  v-model="data.item.attendence"
                  placeholder="Frequência"
                  id="trainingFrequency"
                  step="1"
                  max="100"
                  min="0"
                  :class="data.item.errors.attendence ? 'error-input' : ''"
                />
                <div v-if="data.item.errors.attendence" class="error-message">
                  {{ data.item.errors.attendence }}
                </div>
            </template>
            <template v-slot:cell(grade)="data">
              <b-form-input
                type="number"
                v-model="data.item.grade"
                placeholder="Nota"
                id="trainingGrade"
                max="100"
                min="0"
                :class="data.item.errors.grade ? 'error-input' : ''"
              />
              <div v-if="data.item.errors.grade" class="error-message">
                {{ data.item.errors.grade }}
              </div>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button
                variant="danger"
                @click="removeRow(data.index)"
              >
                <i class="far fa-trash-alt"></i>
              </b-button>
            </template>
            <template #table-caption>
              <div style="display: flex; justify-content: center;">
                <b-button
                  class="btn btn-primary"
                  @click="addRow()"
                >
                <i class="fa fa-add"></i></b-button>
              </div>
            </template>
          </b-table>
          <div class="row p-1" style="display: flex; justify-content: flex-end;">
            <button
              id="zones-save-btn"
              class="btn btn-primary px-5"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
            </button>
          </div>
        </div>
      </form>
    </b-modal>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Importar colaboradores</h1>
    </div>
    <EmployeeImportConfig
      :entireEmployee="editEmployeeConfig"
      :area_options="area_options"
      :department_options="department_options"
      :position_options="position_options"
    ></EmployeeImportConfig>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <div class="col-12 pr-4">
                <div class="form-group">
                  <UploadExcel
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :draggable="true"
                  />
                </div>
              </div>
            </div>

            <div v-show="tableData.length">
              <div class="content-header px-0">
                <h4>Colaboradores encontrados</h4>
              </div>
              <b-table
                id="importEmployeeTable"
                responsive="sm"
                striped
                borderless
                hover
                :fields="fields"
                :items="tableData"
                :busy="!verifyImport"
                class="mt-3 rounded"
                selectable
                @row-selected="onRowSelected"
                select-mode="multi"
                ref="selectableTable"
                editableFields
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <select
                    class="form-control"
                    v-if="field.key === 'gender'"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'g-' + field.key"
                  >
                    <option v-for="opt in generos" :key="opt.gender">
                      {{ opt }}
                    </option>
                  </select>

                  <input
                    type="text"
                    class="form-control"
                    v-if="
                      field.key !== 'gender' &&
                      field.key !== 'cpf' &&
                      field.key !== 'cellphone'
                    "
                    @change="validate(tableData)"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                  />

                  <input
                    type="text"
                    class="form-control"
                    v-mask="'+NNNNNNNNNNNNNNN'"
                    v-if="field.key == 'cellphone'"
                    @change="validate(tableData)"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                  />

                  <input
                    type="text"
                    class="form-control"
                    v-mask="'NNN.NNN.NNN-NN'"
                    v-if="field.key == 'cpf'"
                    @change="validate(tableData)"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                  />

                  <span
                    v-if="item.errors"
                    style="color: red"
                    :key="'erro-' + field.key"
                    >{{ item.errors[field.key] }}</span
                  >
                </template>
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true" class="check-symbol">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{
                      width:
                        field.key === 'selected' ||
                        field.key === 'actions' ||
                        field.key === 'employee_info'
                          ? '250px'
                          : '550px',
                    }"
                  />
                </template>
                <template v-slot:cell(invalid)="data">
                  <span
                    v-if="data.item.invalid"
                    class="valid-import badge badge-danger"
                    >Inválido</span
                  >
                  <span
                    v-if="!data.item.invalid"
                    class="valid-import badge badge-success"
                    >Válido</span
                  >
                </template>
                <template v-slot:cell(employee_info)="data">
                  <div style="text-align: center">
                    <a
                      @click="selectEmployee(data.item)"
                      href="#"
                      class="mr-3"
                      data-toggle="modal"
                      data-target="#modalDeleteUser"
                      title="Treinamentos"
                      :style="{
                        color: data.item.errors.trainings ? 'red' : ''
                      }"
                    >
                      <i class="fa-solid fa-helmet-safety"></i>
                    </a>
                    <a
                      class="fas fa-xl fa-edit fa-1x"
                      style="margin: 2px"
                      @click="editEmployeeConfig = data.item"
                      data-toggle="modal"
                      data-target="#import-config-modal"
                      title="Informações do Colaborador"
                    ></a>
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <a
                    @click="user = data.item"
                    href="#"
                    class="mr-3"
                    data-toggle="modal"
                    data-target="#modalDeleteUser"
                    title="Excluir"
                    v-if="
                      storage
                        ? storage.getItem('email') != data.item.email
                        : false
                    "
                  >
                    <i class="far fa-trash-alt"></i>
                  </a>
                  <router-link
                    :to="{ name: 'user-edit', params: { user: data.item } }"
                    title="Editar"
                  >
                    <i class="fas fa-pen"></i>
                  </router-link>
                </template>
              </b-table>

              <div class="row p-1">
                <button
                  id="selectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="selectAllRows"
                >
                  Selecionar tudo
                </button>
                <button
                  id="desselectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="clearSelected"
                >
                  Desselecionar tudo
                </button>
                <button
                  type="button"
                  class="btn btn-outlined btn-danger mr-2"
                  @click="deleteSelected"
                  id="btn-delete-selected"
                >
                  Remover selecionados
                </button>
                <button
                  id="btn-saveEmployees-selected"
                  @click="saveEmployees"
                  class="btn btn-success px-5"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </div>
            <span v-if="!tableData.length" style="color: blue">
              Selecione um arquivo de colaboradores |
              <a v-bind:href="item.loc" download>Modelo de importação xlsx</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel/index.vue";
import Breadcrumb from "@/components/Breadcrumb";
import EmployeeImportConfig from "@/views/Employee/EmployeeConfig.vue";
import { validCPF } from "../../services/helpers";
import { formatDate, formatDateAPI, trimObjFields } from "@/utils/format-fields.js";
import TwInput from '../../components/TwInput.vue';

export default {
  components: { Breadcrumb, UploadExcel, EmployeeImportConfig, TwInput },
  data() {
    return {
      breadcrumbList: [],
      generos: ["", "MASCULINO", "FEMININO", "NAO-IDENTIFICADO"],
      tableData: [],
      tableHeader: [],
      storage: null,
      employeeModal: false,
      user: "",
      sortBy: "name",
      currentPage: 1,
      selectedEmployee: {},
      fields: [
        { key: "selected", sortable: false, label: "Selecionado" },
        {
          key: "name",
          editable: true,
          sortable: true,
          label: "Nome",
        },
        {
          key: "registration_number",
          editable: true,
          sortable: false,
          label: "Número de Registro",
        },
        { key: "gender", editable: true, sortable: false, label: "Gênero" },
        // {
        //   key: "date_of_birth",
        //   editable: true,
        //   sortable: true,
        //   label: "Data de Nascimento",
        // },
        {
          key: "cpf",
          editable: true,
          sortable: true,
          label: "CPF",
        },
        {
          key: "cellphone",
          editable: true,
          sortable: true,
          label: "Celular",
        },
        {
          key: "email",
          editable: true,
          sortable: true,
          label: "E-mail",
        },
        {
          key: "employee_info",
          label: "Ações",
        },
        {
          key: "invalid",
          label: "Validade",
        },
      ],
      fields_training: [
        {
          key: "name",
          label: "Treinamento",
        },
        {
          key: "session",
          label: "Turma",
        },
        {
          key: "expiration",
          label: "Expiração",
        },
        {
          key: "attendence",
          label: "Frequência",
        },
        {
          key: "grade",
          label: "Nota",
        },
        {
          key: "actions",
          label: "Ações"
        },
      ],
      fieldsHeader: [
        {
          key: "name", label: "Nome",
        },
        {
          key: "registration_number", label: "Número de Registro",
        },
        {
          key: "gender", label: "Gênero",
        },
        {
          key: "date_of_birth", label: "Data de Nascimento",
        },
        {
          key: "cpf", label: "CPF",
        },
        {
          key: "cellphone", label: "Telefone",
        },
        {
          key: "email", label: "E-mail",
        },
        {
          key: "anamnesis_data.height", label: "Altura (m)",
        },
        {
          key: "anamnesis_data.weight", label: "Peso (kg)",
        },
        {
          key: "anamnesis_data.smoking", label: "Fumante?",
        },
        {
          key: "anamnesis_data.preexisting_conditions", label: "Doenças Pré-Existentes",
        },
        {
          key: "anamnesis_data.physical_activity", label: "Atividades Físicas",
        },
        {
          key: "anamnesis_data.medicines_for_continuous_use", label: "Medicamentos de Uso Contínuo?",
        },
        {
          key: "anamnesis_data.blood_pressure_level", label: "Pressão Arterial",
        },
        {
          key: "anamnesis_data.pregnancy", label: "Gravidez?",
        },
        {
          key: "functional_data.area", label: "Área",
        },
        {
          key: "functional_data.department", label: "Departamento",
        },
        {
          key: "functional_data.position", label: "Cargo",
        },
        {
          key: "functional_data.work_shift", label: "Turno",
        },
        {
          key: "functional_data.work_week", label: "Jornada",
        },
        {
          key: "date_of_birth", label: "Data de Nascimento",
        },
        {
          key: "functional_data.date_of_hire", label: "Data de Admissão",
        },
        {
          key: "functional_data.supervisor.name", label: "Gestor",
        },
        {
          key: "functional_data.last_ohc", label: "Última ASO",
        },
        {
          key: "functional_data.health_plan", label: "Plano de Saúde",
        },
        {
          key: "training.name", label: "Nome do Treinamento",
        },
        {
          key: "training.instructor", label: "Instrutor",
        },
        {
          key: "training.t_start", label: "Horário de Início",
        },
        {
          key: "training.t_end", label: "Horário de Fim",
        },
        {
          key: "training.session", label: "Turma",
        },
        {
          key: "training.expiration", label: "Expiração",
        },
        {
          key: "training.attendence", label: "Frequência",
        },
        {
          key: "training.grade", label: "Nota",
        },
      ],
      selected: [],
      item: {
        title: "Excel template",
        loc: "/assets/files/model_import_employees.xlsx",
      },
      editEmployeeConfig: {},
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    editEmployeeConfig() {
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    verifyImport() {
      if (this.verifyImport) this.validate(this.tableData);
    },
  },
  mounted() {
    this.updateList();

    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
      this.$store.dispatch("employee/getEmployees", {
        customer_id: this.$route.params.customerId,
      });
      this.$store.dispatch("training/getCustomerAllTrainings", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }
  },
  methods: {
    saveEmployees() {
      let dadosSelecionados;

      if (this.selected.length > 0) {
        dadosSelecionados = this.selected;
      } else {
        window.frontMsg("FRNT_MUST_SELECT", { field: "Colaborador" });

        return;
      }
      if (this.validate(dadosSelecionados)) {
        let sendEmployees = [...dadosSelecionados];

        sendEmployees.forEach((element) => {
          if (
            element.cpf !== "" &&
            element.cpf !== null &&
            element.cpf !== undefined
          ) {
            element.cpf = element.cpf.toString();
          } else {
            element.cpf = null;
          }
          if (
            element.cellphone !== "" &&
            element.cellphone !== null &&
            element.cellphone !== undefined
          ) {
            element.cellphone = element.cellphone.toString();
          } else {
            element.cellphone = null;
          }

          if (!element?.email){
            element.email = null;
          }
          
          element.registration_number = element.registration_number.toString();
          if (element.gender == "" || element.gender == null) {
            element.gender = "NAO-IDENTIFICADO";
          }

          // Change date to ISO format !!!
          if (element.date_of_birth != null) {
            element.date_of_birth = formatDateAPI(element.date_of_birth);
          }
          if (element.functional_data.last_ohc != null) {
            element.functional_data.last_ohc = formatDateAPI(
              element.functional_data.last_ohc
            );
          }
          
          if (element.functional_data.date_of_dismissal){
            element.functional_data.date_of_dismissal = formatDateAPI(element.functional_data.date_of_dismissal)
          }

          if (element.functional_data.date_of_hire){
            element.functional_data.date_of_hire = formatDateAPI(element.functional_data.date_of_hire)
          }

          let originalData = JSON.parse(JSON.stringify(dadosSelecionados.filter(dado => dado == element)[0]))
          element.trainings.length = 0;

          originalData.trainings.forEach((training) => {
            let trainingFromDb = this.customerTrainings.filter((dbTraining) => dbTraining.name === training.name)[0]

            let trainingId = trainingFromDb.id;
            let trainingDuration = trainingFromDb.duration;

            element.trainings.push({
              id: trainingId,
              name: training.name,
              t_start: training.session.t_start,
              t_end: training.session.t_end,
              instructor: training.session.instructor,
              duration: training.duration,
              attendence: training.attendence,
              grade: training.grade,
              duration: trainingDuration,
              expiration: formatDateAPI(training.expiration),
            })
          })

          delete element.invalid;
          delete element.errors;
          delete element.training;
        });

        this.$store.dispatch("employee/saveListEmployee", {
          customer_id: this.$route.params.customerId,
          data: { employees: sendEmployees },
        });

        this.deleteSelected();
      } else {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Colaboradores",
        });

        this.validate(this.tableData);
        return;
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteSelected() {
      this.tableData = this.tableData.filter((e) => {
        return !this.selected.includes(e);
      });
      this.$refs.selectableTable.refresh();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    transformDotNotation(data) {
      let transformedData = [];
      data.forEach((element) => {
        let transformedElement = {};
        for (let key in element) {
          let keyArray = key.split(".");
          let currentElement = transformedElement;
          for (let i = 0; i < keyArray.length; i++) {
            if (i === keyArray.length - 1) {
              currentElement[keyArray[i]] = element[key];
            } else {
              if (!currentElement[keyArray[i]]) {
                currentElement[keyArray[i]] = {};
              }
              currentElement = currentElement[keyArray[i]];
            }
          }
        }

        trimObjFields(transformedElement)
        this.adaptTrainingData(transformedElement)

        transformedData.push(transformedElement);
      });
      return transformedData;
    },
    changeKeys(obj_array) {
      obj_array.forEach((elem) => {
        Object.keys(elem).forEach((key) => {
          for (let y in this.fieldsHeader) {
            if (key.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
              /* Create new key with the same values/properties */
              Object.defineProperty(
                elem,
                this.fieldsHeader[y].key,
                Object.getOwnPropertyDescriptor(elem, key)
              );
              /*Delete old key*/
              delete elem[key];
              break;
            }
          }
        });
      });

      return obj_array;
    },
    handleSuccess({ results, header }) {
      if (results.length < 250) {
        this.tableData = this.transformDotNotation(this.changeKeys(results));

        let originalTableDataLength = this.tableData.length;
        let totalVerifiedEmployees = 0;

        while (totalVerifiedEmployees < originalTableDataLength) {
          // All the verified trainings goes to end of the vector, so only the first position has to be verified
          totalVerifiedEmployees += this.unifyRepeatedEmployees(this.tableData[0])
        }

        let employeeFields = {
          name: "",
          email: "",
          cellphone: "",
          cpf: "",
          registration_number: "",
          gender: "",
          date_of_birth: null,
          anamnesis_data: {
            height: null,
            weight: null,
            smoking: null,
            preexisting_conditions: [],
            physical_activity: null,
            medicines_for_continuous_use: null,
            blood_pressure_level: null,
            pregnancy: null,
          },
          functional_data: {
            area: null,
            department: null,
            position: null,
            work_shift: null,
            work_week: null,
            state: "HIRED",
            date_of_hire: null,
            date_of_dismissal: null,
            supervisor: {
              name: null,
              registration_number: null,
            },
            last_ohc: null,
            health_plan: null,
          },
        };
        // use the ... spread operator to add the fields to each result
        this.tableData = this.tableData.map((result) => {
          //JSON.parse(JSON.stringify()) to make a deep copy
          return { ...JSON.parse(JSON.stringify(employeeFields)), ...result };
        });

        //Fix data that is in wrong format
        this.tableData.forEach((elem, index) => {
          let input = results[index];

          if (input.hasOwnProperty("anamnesis_data.preexisting_conditions")) {
            let unsplit = elem.anamnesis_data.preexisting_conditions;
            elem.anamnesis_data.preexisting_conditions = unsplit.split(", ");
          }

          if (input.hasOwnProperty("functional_data.last_ohc")) {
            elem.functional_data.last_ohc = formatDate(elem.functional_data.last_ohc)
          }

          if (input.hasOwnProperty("functional_data.date_of_hire")) {
            elem.functional_data.date_of_hire = formatDate(elem.functional_data.date_of_hire)
          }

          if (input.hasOwnProperty("date_of_birth")) {
            elem.date_of_birth = formatDate(elem.date_of_birth)
          }

          elem.functional_data.area = this.area_options.includes(elem.functional_data.area) ? elem.functional_data.area : null;
          elem.functional_data.department = this.department_options.includes(elem.functional_data.department) ? elem.functional_data.department : null;
          elem.functional_data.position = this.position_options.includes(elem.functional_data.position) ? elem.functional_data.position : null;
          elem.functional_data.state = "HIRED"
        });

        this.tableHeader = header;
        this.validate(this.tableData);
      } else {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason: "a quantidade de linhas na planilha excede o limite de 50",
        });
      }
    },
    adaptTrainingData(employee){
      if (employee.training){
        employee.training.name = employee.training.name?.toUpperCase()
        employee.training.instructor = employee.training.instructor?.toUpperCase()

        if (employee.training.instructor ||
            employee.training.t_start ||
            employee.training.t_end
          ){
            employee.training.session = {
            instructor: employee.training.instructor,
            t_start: employee.training.t_start,
            t_end: employee.training.t_end,
          }
        }

        employee.training.errors = {
          name: "",
          session: "",
          expiration: "",
          attendence: "",
          grade: "",
        }
      }
    },
    selectEmployee(employee){
      this.selectedEmployee = employee;
      this.employeeModal = true;

      this.selectedEmployee.trainings.forEach((training) => {
        this.onSelectTraining(training)
      })
    },
    unifyRepeatedEmployees(item){
      let repeatedEmployee = this.tableData.filter((employee) => 
        item.name === employee.name &&
        item.registration_number === employee.registration_number &&
        item.gender === employee.gender &&
        item.cpf === employee.cpf &&
        item.cellphone === employee.cellphone &&
        item.email === employee.email
      )

      this.tableData = this.tableData.filter((employee) => 
        item.name !== employee.name ||
        item.registration_number !== employee.registration_number ||
        item.gender !== employee.gender ||
        item.cpf !== employee.cpf ||
        item.cellphone !== employee.cellphone ||
        item.email !== employee.email
      )

      repeatedEmployee[0].trainings = [];

      repeatedEmployee.forEach((employee) => {
        if (
            employee.training && 
            (employee.training.name || 
            employee.training.attendence || 
            employee.training.duration || 
            employee.training.expiration || 
            employee.training.grade ||
            employee.training.state ||
            employee.training.session)
          ){
            repeatedEmployee[0].trainings.push(employee.training)
        }
      })

      this.tableData.push(repeatedEmployee[0]);
      return repeatedEmployee.length // Number of verified trainings from data table.
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    createErrorAttribute(employee) {
      employee.errors = {
        name: null,
        registration_number: null,
        cpf: null,
        gender: null,
        cellphone: null,
        email: null,
        trainings: null,
      };

      employee.invalid = false;
    },
    cpfMaskReconfig(cpf) {
      let cpfMascarado = cpf?.toString().replace(/[.]|-/g, "");
      cpfMascarado = cpfMascarado?.replace(/(.{3})/, "$1.");
      cpfMascarado = cpfMascarado?.replace(/(.{7})/, "$1.");
      cpfMascarado = cpfMascarado?.replace(/(.{11})/, "$1-");

      return cpfMascarado;
    },
    cellphoneMaskReconfig(cellphone) {
      let cellphoneMask;

      if (cellphone?.toString().charAt(0) !== "+" && cellphone?.length > 0)
        cellphoneMask = cellphone?.toString().replace(/(.{0})/, "$1+");
      else cellphoneMask = cellphone;

      return cellphoneMask;
    },
    getFieldLabel(fieldKey) {
      for (let field of this.fields)
        if (field.key === fieldKey) return field.label;
    },
    verifyDoubleData(employee, employeeList, attr, initialIndex) {
      let employeeAttribute = employee[attr]?.toString().trim(); // Backup variable without spaces in start and end of the string.

      // Checks if the data isn't empty.
      if (employeeAttribute) {
        for (let j = parseInt(initialIndex) + 1; j < employeeList.length; j++) {
          //if (employeeList[j][attr]) continue;

          let employeeAttributeCompared = employeeList[j][attr]
            ?.toString()
            .trim(); // Backup variable without spaces in start and end of the string.
          // Checks if has another data with the same input in the table.
          if (
            employeeAttributeCompared &&
            employeeAttribute === employeeAttributeCompared
          ) {
            //employee.invalid = true;
            employeeList[j].invalid = true;

            // Insert the error message in both inputs.
            //employee.errors[attr] = `${this.getFieldLabel(attr)} duplicado nesta importação`;
            employeeList[j].errors[attr] = `${this.getFieldLabel(
              attr
            )} duplicado nesta importação`;
          }
        }
      }
    },
    verifyEmptyInput(employee, attr) {
      employee[attr] = employee[attr]?.toString().trim(); // Backup variable without spaces in start and end of the string.

      if (!employee[attr]) {
        employee.errors[attr] = `${this.getFieldLabel(
          attr
        )} não pode ser vazio`;
        employee.invalid = true;
      }
    },
    verifyRegisteredData(employee, attr) {
      let checkAttribute = (obj) =>
        obj[attr] ? obj[attr] === employee[attr]?.toString() : false;

      if (employee[attr] && this.employees.some(checkAttribute)) {
        employee.invalid = true;
        employee.errors[attr] = `${this.getFieldLabel(attr)} já está em uso`;
      }
    },
    verifyInputLength(employee, attr, minLen, maxLen) {
      if (
        (employee[attr]?.toString() &&
          employee[attr]?.toString().length < minLen) ||
        employee[attr]?.toString().length > maxLen
      ) {
        employee.invalid = true;
        employee.errors[attr] = `${this.getFieldLabel(attr)} inválido`;
      }
    },
    verifyInputCaracters(employee, attr) {
      if (
        employee[attr] &&
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          employee[attr]?.trim()
        )
      ) {
        employee.invalid = true;
        employee.errors[attr] = "Email Inválido";
      }
    },
    verifyCpfValidation(employee) {
      if (employee.cpf && !validCPF(employee.cpf?.toString())) {
        employee.invalid = true;
        employee.errors["cpf"] = "CPF Inválido";
      }
    },
    isEmployeeValid(employeeList, field, index) {
      let employee = employeeList[index];
      if (field === "name") {
        this.verifyEmptyInput(employee, field);
      } else if (field === "registration_number") {
        this.verifyEmptyInput(employee, field);
        this.verifyDoubleData(employee, employeeList, field, index);
        this.verifyRegisteredData(employee, field);
      } else if (field === "cpf") {
        this.verifyCpfValidation(employee);
        this.verifyDoubleData(employee, employeeList, field, index);
        this.verifyRegisteredData(employee, field);
      } else if (field === "cellphone") {
        this.verifyDoubleData(employee, employeeList, field, index);
        this.verifyInputLength(employee, field, 13, 14);
        this.verifyRegisteredData(employee, field);
      } else if (field === "email") {
        this.verifyDoubleData(employee, employeeList, field, index);
        this.verifyInputCaracters(employee, field);
        this.verifyRegisteredData(employee, field);
      }

      return !employee.invalid;
    },
    validate(employeeList) {
      // This for-loop configures the attributes of errors and the CPF data.
      for (let employee of this.tableData) {
        if (!this.generos.includes(employee.gender)) {
          employee.gender = "";
        }

        this.createErrorAttribute(employee);
        employee.cpf = this.cpfMaskReconfig(employee.cpf);
        employee.cellphone = this.cellphoneMaskReconfig(employee.cellphone);
      }

      for (let field of this.fields) {
        if (field.key != "selected" && field.key != "invalid") {
          // Those fields doesn't need to be verificated.
          for (let index in employeeList) {
            this.isEmployeeValid(employeeList, field.key, index);
          }
        }
      }

      employeeList.forEach((employee) => {
        if (employee.trainings.length > 0){
          
          this.selectedEmployee = employee;
          employee.trainings.forEach((training) => {
            if (!this.isTrainingValid(training)){
              employee.errors.trainings = true;
              employee.invalid = true;
            }
          })
        }
      })

      const valido = !this.tableData.some((e) => e.invalid === true);

      this.$refs.selectableTable.refresh();
      return valido;
    },
    addRow() {
      document.activeElement.blur(); /* focus out the element (macumba) */
      this.selectedEmployee.trainings.push({
        attendence: 0,
        duration: 0,
        expiration: "",
        grade: 0,
        id: 0,
        name: "",
        schedules: [],
        session: "",
        errors: {
          name: "",
          session: "",
          expiration: "",
          attendence: "",
          grade: "",
        }
      });
      this.$refs.employee_trainings_table.refresh();
    },
    removeRow(index) {
      if(this.selectedEmployee.trainings[index].id !== 0){
        for (let training of this.customerTrainings){
          if(training.id == this.selectedEmployee.trainings[index].id){
            training.selected = false;
          }
        }
      }

      this.selectedEmployee.trainings.splice(index, 1);
      this.$refs.employee_trainings_table.refresh();
    },
    onSelectTraining(item){
      if(item.id !== 0){
        for (let training of this.customerTrainings){
          if(training.id == item.id){
           training.selected = false;
          }
        }
      }
      if(item.name !== ""){
        for (let training of this.customerTrainings){
          if(training.name == item.name){
           item.id = training.id;
           item.duration = training.duration;
           item.schedules = training.schedules;
           item.expires_at = training.expires_at;
           training.selected = true;
          }
        }
      } else {
        item.id = 0;
        item.duration = 0;
        item.schedules = [];
      }
    },
    onSelectSession(item){
      let sche = item.session;
      let end_date = new Date(formatDate(sche.t_end?.split(" ")[0]) + "T" + sche.t_end?.split(" ")[1]+"Z");
      end_date.setDate(end_date.getUTCDate() + item.expires_at);
      item.expiration = end_date.toISOString()?.split("T")[0];
    },
    isTrainingValid(training){
      training.errors = {
        name: "",
        session: "",
        expiration: "",
        attendence: "",
        grade: "",
      }

      let valid = true;

      let trainingNameOccurrences = this.customerTrainings.filter(trainingDb => trainingDb.name === training.name?.trim())
      let trainingSessionOccurrences = this.customerTrainings.filter(trainingDb => (
        trainingDb.name === training.name?.trim() &&
        trainingDb.schedules.filter((trainingSchedule) => (
          trainingSchedule.instructor === training.session.instructor &&
          trainingSchedule.t_start === training.session.t_start &&
          trainingSchedule.t_end === training.session.t_end
          )
        ).length > 0
      )).length

      if (trainingNameOccurrences.length === 0){
        training.name = ""
      }

      else {
        training.expires_at = trainingNameOccurrences[0].expires_at;
      }

      if (trainingSessionOccurrences === 0){
        training.session = ""
      }

      else {
        this.onSelectSession(training)
      }

      if (!training.name?.trim() || training.name === "Selecione"){
        training.errors.name = "Treinamento não pode ser vazio."
        valid = false
      }

      if (!training.session || training.session === "Selecione"){
        training.errors.session = "Turma não pode ser vazia."
        valid = false
      }

      if (!training.expiration){
        training.errors.expiration = "Data de expiração não pode ser vazio."
        valid = false
      }
      
      else if (
          new Date(training.expiration) < new Date("01/01/1900") ||
          new Date(training.expiration) > new Date("01/01/2099")
        ) {
          training.errors.expiration = "Data de expiração fora do intervalo aceito."
          valid = false
      }
      

      if (training.attendence === null || training.attendence === undefined || training.attendence === ""){
        training.errors.attendence = "Frequência não pode ser vazia."
        valid = false
      }

      else if (training.attendence < 0 || training.attendence > 100){
        training.errors.attendence = "Frequência deve ser um valor entre 0 a 100.";
        valid = false;
      }

      if (training.grade === null || training.grade === undefined || training.grade === ""){
        training.errors.grade = "Nota não pode ser vazia."
        valid = false;
      }
      
      else if (training.grade < 0 || training.grade > 100){
        training.errors.grade = "Nota deve ser um valor entre 0 a 100.";
        valid = false;
      }

      if (!training.errors.name){
        let repeatedTrainingsQuant = this.selectedEmployee.trainings.filter((employeeTraining) => 
          employeeTraining.name === training.name
        ).length

        if (repeatedTrainingsQuant > 1){
          training.errors.name = "Este treinamento já foi importado!"
          valid = false;
        }
      }
      return valid;
    },
    saveTrainings(){
      let closeModal = true
      this.selectedEmployee.errors.trainings = false;
      this.selectedEmployee.invalid = false;

      this.selectedEmployee.trainings.length > 0 && this.selectedEmployee.trainings.forEach((training) => {
        if (!this.isTrainingValid(training)){
          closeModal = false
          this.selectedEmployee.errors.trainings = true;
        }
      })

      if (closeModal){
        this.employeeModal = false;
      }

      for (let keyAlves in this.selectedEmployee.errors){
        if (this.selectedEmployee.errors[keyAlves]) this.selectedEmployee.invalid = true;
      }
    }
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    editableFields() {
      return this.fields.filter((field) => field.editable);
    },
    employees() {
      return this.$store.getters["employee/employees"];
    },
    verifyImport() {
      return this.$store.getters["employee/verifyImport"];
    },
    customerTrainings(){
      return this.$store.getters["training/trainings"];
    },
    area_options() {
      return this.$store.getters["employee/areas"].map((area) => area.area);
    },
    department_options() {
      return this.$store.getters["employee/departments"].map(
        (department) => department.department
      );
    },
    position_options() {
      return this.$store.getters["employee/positions"].map(
        (position) => position.position
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.check-symbol {
  font-size: 3rem;
  margin-top: -30px;
  margin-left: 15px;
  position: absolute;
  display: block;
  text-align: center;
  user-select: none;
}

.valid-import {
  width: 100px;
  font-size: 20px;
  padding: 10px;
  border-radius: 50px;
  transition: none;
}
</style>
