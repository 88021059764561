<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK', 'DASHBOARD', 'USER']">
    <TrainingScheduleModal :entireTraining="this.selectedTraining"/>
    <b-modal v-if="assignedGeofencesModal" v-model="assignedGeofencesModal" hide-footer>
      <template #modal-header="{ close }">
        <h5>Geofences associadas</h5>
        <b-button variant="link" @click="close()">
          <span>X</span>
        </b-button>
      </template>
        <div>
          <b-table
            striped
            hover
            :items="selectedTraining.geofences"
            show-empty
            empty-text="Nenhuma geofence associada."
            :fields="assignedGeofencesFields"
            ref="monitoring_geofences_table"
          >
          </b-table>
        </div>
    </b-modal>
    <TrainingAlertsReason
      :customer="this.customer"
    />
    <div class="row px-0">
      <TwInput label="Busca" :col_lg="hasRole(['USER']) ? '8' : '4'">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control"
            style="max-width: 900px"
            id="filterInputEmployee"
            placeholder="Buscar"
          />
        </b-input-group>
      </TwInput>

      <div 
        class="col-2 d-flex flex-column-reverse pb-3"
        :style="{
          display: hasRole(['USER']) ? 'none !important' : 'block',
        }"
      >
        <b-button
          id="addTrainingbtn"
          variant="primary"
          title="Adicionar treinamento"
          v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']"
          href="#"
          @click="
            () => {
              this.$router.push({ name: 'customer-add-training' });
            }
          "
          >Adicionar</b-button
        >
      </div>
      <div 
        class="col-2 d-flex flex-column-reverse pb-3"
        :style="{
          display: hasRole(['USER']) ? 'none !important' : 'block',
        }"
      >
        <b-button
          variant="primary"
          v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']"
          href="#"
          title="Importar treinamentos"
          @click="
            () => {
              this.$router.push({ name: 'customer-import-training' });
            }
          "
          >Importar</b-button
        >
      </div>
      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          variant="primary"
          title="Exportar treinamentos"
          v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"
          href="#"
          @click="exportTrainings()"
          >Exportar</b-button
        >
      </div>
      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          id="alertModals"
          variant="primary"
          v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"
          href="#"
          title="Alertas gerados por treinamentos"
          data-toggle="modal"
          :data-target="'#modalTrainingAlerts'"
          @click="openTrainingModal()"
          >Alertas</b-button
        >
      </div>
    </div>
    <div>
      <Modal
        id="modalDeleteTraining"
        title="Excluir treinamento"
        :msg="'Tem certeza que deseja excluir o Treinamento?'"
        button="Excluir"
        :method="deleteTraining"
      />
    </div>

    <b-table
      responsive="sm"
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum registro encontrado para essa busca"
      head-variant="light"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortByTrainings"
      :busy="load"
      :filter="filter"
      :fields="fieldsTrainings"
      :items="customerTrainings"
      @filtered="onFiltered"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-colgroup>
        <col
          v-for="field in fieldsTrainings"
          :key="field.key"
          :style="{
            width: field.key === 'actions' ? '100px' : '550px',
          }"
        />
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>
      <template v-slot:cell(geofences)="data">
        <div style="margin-left: 20px;">
          <a class="table-button active"
            @click="openGeofenceModal(data.item)"
            title="Ver geofences associadas ao monitoramento"
            >
            <i class="fa-solid fa-location-dot table-icon"></i>
          </a>
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <a
          id="deleteTraining"
          v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']"
          href="#"
          class="mr-3"
          data-toggle="modal"
          :data-target="'#modalDeleteTraining'"
          @click="trainingDelete = data.item"
          title="Excluir"
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>
        <a
          v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"
          class="mr-3"
          href="#"
          id="addtag"
          data-toggle="modal"
          :data-target="'#training-schedule-modal'"
          :title="!hasRole('USER') ? 'Associar horários' : 'Ver horários associados'"
          @click="selectTrainingToModal(data.item)"
        >
          <i class="fa-solid fa-clock"></i>
        </a>
        <router-link
          v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']"
          :to="{
            name: 'customer-edit-training',
            params: {
              customerId: customer ? customer.id : -1,
              trainingId: data.item.id,
            },
          }"
          title="Editar"
          id="customer-employee-edit-a"
        >
          <i id="editEmployee" class="fas fa-pen iconspace"></i>
        </router-link>
      </template>
    </b-table>

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import ModalThree from "../../../components/ModalThree.vue";
import TwInput from "@/components/TwInput.vue";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import { mapGetters } from "vuex";
import hasRole from "@/utils/hasRole";
import TrainingScheduleModal from "../../Training/TrainingScheduleModal.vue";
import TrainingAlertsReason from "../../Training/TrainingAlertsReason.vue";
const XLSX = require("xlsx");

export default {
  name: "TrainingTab",
  data() {
    return {
      filter: null,
      dadoTrainings: null,
      trainingDelete: "",
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortByTrainings: "name",
      filteredTrainings: [],
      sortByTrainings: "name",
      selectedTraining: {},
      assignedGeofencesModal: false,
      assignedGeofencesFields: [
        { label: "Geofence", key: "geofence_name", sortable: true }
      ],
      assignedTrainingsAlertsModal: false,
      fieldsTrainings: [
        { key: "name", sortable: true, label: "Nome" },
        { key: "category", sortable: true, label: "Categoria de Treinamento" },
        { key: "area",sortable: true, label: "Área" },
        { key: "duration", label: "Duração (h)" },
        { key: "expires_at", label: "Validade (dias)" },
        { key: "geofences", label: "Geofences" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
      worksheetFields: [
        { key: "name", label: "Nome" },
        { key: "category", label: "Categoria" },
        { key: "area", label: "Área" },
        { key: "duration", label: "Duração (h)" },
        { key: "expires_at", label: "Validade (dias)" },
        { key: "description", label: "Descrição" },
        { key: "instructor", label: "Instrutor" },
        { key: "min_grade", label: "Nota Mínima"},
        { key: "min_attendence", label: "Frequência Mínima"},
        { key: "t_start", label: "Horário de Início" },
        { key: "t_end", label: "Horário de Término" },
      ],
      alertsTrainingsFields: [
        { label: "Colaborador", key: "employee", sortable: true },
        { label: "Treinamento", key: "training", sortable: true },
        { label: "Estado", key: "status", sortable: true },
        { label: "Sala da Ocorrência", key: "geofence", sortable: true },
        { label: "Hora da Ocorrência", key: "datetime", sortable: true },
      ],
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.$store.dispatch("training/getCustomerAllTrainings", {
          customer_id: this.customer.id,
        });

        this.$store.dispatch("training/getCustomerTrainingAlerts", {
          customer_id: this.customer.id,
        });
      }
    },
    customerTrainings() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          return;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
      }
      this.rows = this.customerTrainings.length;
    },
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(customerTrainings) {
      this.rows = customerTrainings.length;
    },
    deleteTraining() {
      event.preventDefault();
      this.$store.dispatch("training/deleteCustomerTrainings", {
        customer_id: this.customer.id,
        id: this.trainingDelete.id,
      });
    },
    openTrainingModal(){
      this.assignedTrainingsAlertsModal = true;
    },
    hasRole,
    selectTrainingToModal(training){
      this.selectedTraining = {...training};
    },
    openGeofenceModal(training){
      this.selectTrainingToModal(training)
      this.assignedGeofencesModal = true;
    },
    exportTrainings(){
      const exportTrainings = JSON.parse(JSON.stringify(this.customerTrainings))

      const trainings = exportTrainings.flatMap(training => {
        if (training.schedules.length === 0){
          training.schedules.push({
            instructor: "",
            t_start: "",
            t_end: "",
          })
        }

        const separatedSchedules = training.schedules.map(schedule => {
          return ({
            name: training.name ?? "",
            category: training.category ?? "",
            area: training.area ?? "",
            duration: training.duration ?? "",
            expires_at: training.expires_at ?? "",
            description: training.description ?? "",
            min_grade: training.min_grade ?? "",
            min_attendence: training.min_attendence ?? "",
            schedule: {
              instructor: schedule.instructor,
              t_start: schedule.t_start,
              t_end: schedule.t_end,
            }
          })
        })

        return separatedSchedules;
      })

      let non_nested_objects = this.flattenObject(trainings);
      let arr_of_objs = this.flat_obj_to_array_of_objs(non_nested_objects);
      
      let worksheet = XLSX.utils.json_to_sheet(arr_of_objs, {});

      const wb_cols = Array(9).fill({ wch: 20 });
      worksheet["!cols"] = wb_cols;

      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      let header = [];
      header.push(this.key_to_label_header(trainings));
      XLSX.utils.sheet_add_aoa(worksheet, header, { origin: "A1" });

      XLSX.writeFile(new_workbook, "TrainingExport.xlsx");
    },
    key_to_label_header(trainings) {
      let header = [];
      Object.keys(trainings[0]).forEach((field) => {
        /* Cannot use "break" in forEach*/
        for (let y in this.worksheetFields) {
          if (
            field == this.worksheetFields[y].key &&
            field !== "actions"
          ) {
            header.push(this.worksheetFields[y].label);
            break;
          }
          else if (field === 'schedule'){
            this.key_to_label_header([trainings[0].schedule]).map(field => {
              header.push(field);
            })

            break;
          }
        }
      });

      let t_end_position = header.indexOf("Horário de Término");
      let t_start_position = header.indexOf("Horário de Início");

      header[t_end_position] = "Horário de Início";
      header[t_start_position] = "Horário de Término";

      return header;
    },
    flattenObject(obj) {
      let toReturn = {};

      for (let i in obj) {
        if (!obj.hasOwnProperty(i)) continue;

        if (typeof obj[i] == "object" && obj[i] !== null) {
          let flatObject = this.flattenObject(obj[i]);
          for (let x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + "." + x] = flatObject[x];
          }
        } else {
          toReturn[i] = obj[i];
        }
      }
      return toReturn;
    },
    flat_obj_to_array_of_objs(obj) {
      let arr = [];
      for (let key in obj) {
        // Check if the first character is a number
        if (key.split(".")[0] != null && !isNaN(key.split(".")[0])) {
          if (arr[key.split(".")[0]] == null) {
            arr[key.split(".")[0]] = {};
          }
          let indx = parseInt(key.split(".")[0]);
          let new_key = key.split(".").slice(1).join(".");
          if (obj[key] != null) {
            arr[indx][new_key] = "" + obj[key];
          }
        }
      }
      return arr;
    },
  },
  computed: {
    ...mapGetters({
      load: "load",
      customerTags: "tags/customerTags",
      allTags: "tags/tags",
    }),
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    getTraining(){
      getCustomerAllTrainings
    },
    customerTrainings(){
      return this.$store.getters["training/trainings"];
    },
    customerAlertsTrainings(){
      return this.$store.getters["training/alertTrainings"];
    },
  },
  components: {
    Modal,
    TwInput,
    BDropdown,
    BDropdownItem,
    ModalThree,
    TrainingScheduleModal,
    TrainingAlertsReason
  },
};
</script>

<style scoped>
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}


.table-icon{
  font-size: 20px;
  cursor: pointer;
}
</style>
