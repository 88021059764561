<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Adicionar Notificações</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <div class="card-body">
            <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="card direct-chat direct-chat-primary">
                    <div class="card-body custom-card overflow-visible">
                      <div class="p-4 bg-white rounded">
                        <form @submit.prevent="saveAlert" novalidate="true">
                          <div class="row">
                            <TwInput label="Classe" required="false">
                              <select
                                id="classe"
                                class="form-control"
                                v-model="alert.class_"
                              >
                                <option
                                  v-for="(classe, index) in classes"
                                  :key="index"
                                  class="p-2"
                                  :value="classe.name"
                                >
                                  {{ classe.name }}
                                </option>
                              </select>
                            </TwInput>
                            <TwInput
                              label="Código - Tipo de Alerta"
                              required="false"
                            >
                              <input
                                v-model="alert.code"
                                class="form-control"
                                placeholder="Código"
                                id="codigo"
                              />
                            </TwInput>
                            <TwInput
                              label="Mensagem associada à notificação"
                              required="false"
                            >
                              <input
                                type="text"
                                v-model="alert.msg"
                                class="form-control"
                                placeholder="Mensagem"
                                id="mensagem"
                              />
                            </TwInput>
                          </div>
                          <div class="row p-1">
                            <button
                              id="saveAlert"
                              type="submit"
                              class="btn btn-primary px-5"
                              :disabled="load"
                            >
                              <i
                                v-if="load"
                                class="fas fa-sync-alt fa-spin"
                              ></i>
                              Salvar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwInput from "@/components/TwInput.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import "./style.scss";

export default {
  name: "AddAlert",
  data() {
    return {
      breadcrumbList: [],
      alert: {
        class_: "",
        code: null,
        msg: "",
      },
    };
  },
  mounted() {
    this.updateList();
    this.$store.dispatch("alert/getAlertsClasses");
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },


    validateAlert() {
      if (!this.alert.class_ || this.alert.class_.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Classe" });
        return false;
      }

      if (this.alert.code && isNaN(Number(this.alert.code))){
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Código - Tipo de Alerta",
          extra: "O campo deve ser preenchido apenas com números"
        });
        return false;
      }

      if (!this.alert.code || this.alert.code <= 0) {
        window.frontMsg("FRNT_EMPTY_WARNING", {
          field: "Código - Tipo de Alerta",
        });
        return false;
      }

      if (!this.alert.msg || this.alert.msg.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", {
          field: "Mensagem associada à notificação",
        });

        return false;
        
      }

      return true;
      
    },  


    saveAlert() {
      if (this.validateAlert()) {
        this.$store.dispatch("alert/postAlert", this.alert);
        this.$router.push("/notification")
      }   
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    classes() {
      return this.$store.getters["alert/classes"];
    },
  },
};
</script>
