<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Aplicações</h1>
      {{ load ? "carregando" : "" }}
    </div>
    <div class="form-inline col-12 p-0">
      <div class="pl-0 col-10 filterInput">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control col-11 w-100"
            id="filterInput"
            placeholder="Buscar"
          />
        </b-input-group>
      </div>
      <router-link
        v-can="['ADMIN']"
        title="Adicionar Aplicação"
        to="/appID/add"
        class="btn btn-primary add-user col-2"
        id="addAppID"
      >
        <span class="d-none d-md-block d-lg-block">Adicionar </span>
        <span class="d-block d-md-none d-lg-none">
          <i class="fas fa-plus-circle"></i>
        </span>
      </router-link>
    </div>
    <b-table
      responsive="sm"
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhuma aplicação encontrada para essa busca"
      show-empty
      :filter="filter"
      :busy="load"
      :fields="fields"
      :sort-by.sync="sortBy"
      :items="appIDs"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>
      <template v-slot:table-colgroup>
        <col v-for="field in fields" :key="field.key" :style="{ width: field.key === 'actions' ? '100px' : '550px' }" />
      </template>
      <template v-slot:cell(actions)="data">
        <a
          v-can="['ADMIN']"
          @click="appDelete = data.item"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#ModalDeleteApplication"
          title="Excluir"
          :id="'excluir'"
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>
        <router-link
          id="editApplication"
          v-can="['ADMIN']"
          :to="{ name: 'appID-edit', params: {appIdName: data.item.name }}"
          title="Editar"
          v-if="data.item.level != 'ADMIN'"
        >
          <i class="fas fa-pen iconspace"></i>
        </router-link>
      </template>
    </b-table>
    <Modal
      id="ModalDeleteApplication"
      @click="appDelete = data.item.name"
      title="Excluir Notificações"
      :msg="'Tem certeza que deseja excluir a aplicação ' + appDelete.name + ' ?'"
      style="white-space: pre-wrap"
      button="Excluir"
      :method="deleteApps"
    />
    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            id="linhasDropdown"
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item href="#" @click="() => { this.perPage = 10; }">10</b-dropdown-item>
            <b-dropdown-item href="#" @click="() => { this.perPage = 20; }">20</b-dropdown-item>
            <b-dropdown-item href="#" @click="() => { this.perPage = 50; }">50</b-dropdown-item>
            <b-dropdown-item href="#" @click="() => { this.perPage = 1000; }">1000</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
import { BDropdown, BDropdownItem } from "bootstrap-vue";

export default {
  name: "AppID",
  components: {
    TwInput,
    BDropdown,
    BDropdownItem,
    Breadcrumb,
  },
  data() {
    return {
      breadcrumbList: [],
      filter: null,
      perPage: 10,
      rows: 1,
      sortBy: "name",
      appDelete: "",
      currentPage: 1,
      fields: [
        { key: "name", sortable: true, label: "Nome" },
        { key: "level", sortable: true, label: "Nível de acesso" },
        { key: "group", sortable: true, label: "Grupo" },
        { key: "actions", sortable: false, label: "Ações", class: "actions" },
      ],
    };
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    appIDs() {
      if (this.filter) {
        return this.$store.getters["applications/appIdList"].filter((appIDs) => {
          return Object.values(appIDs).some((value) =>
            String(value).toLowerCase().includes(this.filter.toLowerCase())
          );
        });
      }
      return this.$store.getters["applications/appIdList"];
    },
  },


  methods: {
    onFiltered(appIDs) {
      this.rows = this.appIDs.length;
    },
    deleteApps() {
      this.$store.dispatch("applications/deleteAppIds", this.appDelete.name);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;      
    },
  },
  mounted() {
    this.$store.dispatch("applications/getAppIds");
    this.updateList()
  },
};
</script>

<style scoped>
.iconspace {
  margin-left: 10px;
  margin-right: 10px;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
</style>
