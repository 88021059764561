import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";

//Removido todo o código comentado para manter limpo e legível

export default {
  namespaced: true,
  state: {
    alert: [],
    alerts: [],
    classes: [],
    customerAlerts: [],
    alertTypes: [],
  },

  mutations: {
    async FETCH_ALERTS_TYPES(state) {
      await api.get(`alerts/types`).then((res) => {
        state.alerts = res.data;
      }).catch((err) => {
        displayErrMsg(err.response.data.code, "Notificação", "FETCH_ALERTS_TYPES");
      });
    },

    async GET_ALERTS_CLASSES(state) {
      await api.get("alerts/classes").then((res) => {
        state.classes = res.data;
      }).catch((err) => {
        displayErrMsg(err.response.data.code, "Notificação", "GET_ALERTS_CLASSES");
      });
    },

    async GET_CUSTOMER_ALERTS(state, data) {
      await api.get(`alerts/customer/${data.customer_id}`).then((res) => {
        state.customerAlerts = res.data;
      }).catch((err) => {
        displayErrMsg(err.response.data.code, "Notificação", "GET_CUSTOMER_ALERTS");
      });
    },
    
    async POST_ALERT_TYPES(state, data) {
      await api.post(`alerts/types`, data).then(() => {
        displayErrMsg("GLOBAL_SUCCESS_ADD", "Notificação", "POST_ALERT_TYPES");
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, "Notificação", "POST_ALERT_TYPES");
      });       

    },

    async PUT_ALERT_TYPES(state, data) {   
       await api.put(`alerts/types`, data).then(() => 
      {
        displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Notificação", "PUT_ALERT_TYPES");
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, "Notificação", "PUT_ALERT_TYPES");
      });
    },

    async DELETE_ALERT_TYPES(state, id) {
     await api.delete(`alerts/types?id=${id}`).then(() =>
     {
       displayErrMsg("GLOBAL_SUCCESS_DELETE", "Notificação", "DELETE_ALERT_TYPES");
     })
     .catch((err) => {
       displayErrMsg(err.response.data.code, "Notificação", "DELETE_ALERT_TYPES");
     });    
      
    },

    async GET_BY_ID(state, data) {
      state.alert = data;
    },
  },
    getters: {
      alert: (state) => state.alert,
      alerts: (state) => state.alerts,
      classes: (state) => state.classes,
      customerAlerts: (state) => state.customerAlerts,
      alertTypes: (state) => state.alertTypes,
      
    },

    actions: {
      async get(context) {
        try {
          const response = await api.get(`alerts/types`);
          context.commit("FETCH_ALERTS_TYPES", response.data);
        } catch (error) {
          console.error("Erro ao buscar tipos de alertas:", error);
        }
      },

      async getAlertsClasses(context, classe) {
        try {
          const response = await api.get(
            `/alerts/types?sortBy=code&class_=${classe}`
          );
          context.commit("GET_ALERTS_CLASSES", response.data);
          context.state.alerts = response.data;
        } catch (error) {
          console.error("Erro ao buscar classes de alertas:", error);
        }
      },

      getAlertTypes(context) {
        context.dispatch("get");
      },

      postAlert(context, data) {
        context.commit("POST_ALERT_TYPES", data);
      },

      putAlert(context, data) {
        context.commit("PUT_ALERT_TYPES", data);
      },

      deleteAlert(context, data) {
        context.commit("DELETE_ALERT_TYPES", data);
      },

      async getById(context, alertId) {
        await api
        .get(
          `/alerts/types?sortBy=code&code=${alertId}`)
        .then((res) => {
          context.commit("GET_BY_ID", res.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Notificação", "GET_BY_ID");
        });
      },
    },
}
