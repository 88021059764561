<template>
    <div data-dismiss="modal" aria-label="Close">
      <!-- Modal -->
      <div
        class="modal fade"
        id="modalTrainingAlerts"
        tabindex="-1"
        aria-labelledby="id"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-width"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalTitleLabel">
                Alertas de treinamento
              </h5>
              <button
                id="ast-config-close-modal"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" id="ast-config-close-modal-text"
                  >&times;</span
                >
              </button>
            </div>
  
              <div class="modal-body modal-height">
                <div class="col-12 col-sm-12 p-0 col-md-12">
                  <div class="inside-card">
                    <div class="row">
                      <TwInput label="Busca" col_lg="12">
                        <b-input-group style="display: flex" >
                          <b-input-group-prepend is-text
                            ><i class="fas fa-search"></i>
                          </b-input-group-prepend>
                          <input
                            v-model="filter"
                            type="search"
                            class="form-control"
                            id="filterSeachTrainingAlerts"
                            placeholder="Buscar"
                          />
                        </b-input-group>
                      </TwInput>
                    </div>
                    <div>
                      <b-table
                        striped
                        hover
                        :filter="filter"
                        sticky-header="500px"
                        :items="itemsAlertsTrainings"
                        :fields="alertsTrainingsFields"
                        ref="alertsTrainingsTable"
                        :per-page="perPage"
                        :current-page="currentPage"
                        @filtered="onFiltered"
                      >
                        <template v-slot:table-colgroup>
                          <col
                            v-for="field in alertsTrainingsFields"
                            :key="field.key"
                            :style="{
                              width: field.key === 'actions' ? '90px' :
                                    field.key !== 'frequency' ? '220px' :
                                    '550px',
                            }"
                          />
                        </template>
                        <template
                          v-slot:cell(employee)="data"
                        >
                          <div v-if="data.item.employee" id="trainingAlertsEmployee">
                            {{data.item.employee}}
                          </div>
                        </template>
                        <template
                          v-slot:cell(training)="data"
                        >
                          <div v-if="data.item.training" id="trainingAlertsTraining">
                            {{data.item.training}}
                          </div>
                        </template>
                        <template v-slot:cell(status)="data">
                          <div v-if="data.item.status" id="trainingAlertsState">
                            {{training_status_options.filter((item) => {return data.item.status == item.val})[0]?.option ?? "Estado não reconhecido"}}
                          </div>
                        </template>
                        <template v-slot:cell(geofence)="data">
                          <div v-if="data.item.geofence" id="trainingAlertsGeofence">
                            {{data.item.geofence}}
                          </div>
                        </template>
                        <template v-slot:cell(datetime)="data">
                          <div v-if="data.item.datetime" id="trainingAlertsDatetime">
                            {{data.item.datetime}}
                          </div>
                        </template>
                        <template #cell(note)="data">
                            <b-button
                              data-toggle="modal"
                              :id="`justificationButton${data.index}`"
                              :ref="`justificationButton${data.index}`"
                              :class="data.item.errors?.note ? 'error-input button-errorg' : ''"
                              :title="`${data.detailsShowing ? 'Esconder' : 'Mostrar'} justificativa do alerta`"
                              :variant="`${(data.item?.note) ? 'primary' : ''}`"
                              :disabled="hasRole('USER') && !data.item.note"
                              @click="data.toggleDetails"
                            >
                              <i v-if="data.detailsShowing" class="fa-solid fa-chevron-up"></i>
                              <i v-else class="fa-solid fa-chevron-down"></i>
                            </b-button>
                        </template>
                        <template #row-details="data">
                          <div v-if="!hasRole('USER')">
                            <input
                              class="form-control mr-1"
                              type="text"
                              placeholder="Motivo do Alerta"
                              v-model="data.item.note"
                              :class="data.item.errors?.note ? 'error-input' : ''"
                            >
                            <div v-if="data.item.errors?.note" class="error-message">
                              {{ data.item.errors?.note }}
                            </div>
                          </div>
                          <div v-else>
                            <div>
                              {{ data.item.note }}
                            </div>
                          </div>
                        </template>
                      </b-table>
                      <div class="row">
                        <div v-if="rows" class="col-12 col-md-6 mb-2 pt-1">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="pagination"
                          ></b-pagination>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="btn-group" role="group">
                            <b-dropdown
                              variant="primary"
                              v-model="perPage"
                              :text="`Linhas: ${perPage}`"
                            >
                              <b-dropdown-item
                                href="#"
                                @click="
                                  () => {
                                    this.perPage = 10;
                                  }
                                "
                                >10</b-dropdown-item
                              >
                              <b-dropdown-item
                                href="#"
                                @click="
                                  () => {
                                    this.perPage = 20;
                                  }
                                "
                                >20</b-dropdown-item
                              >
                              <b-dropdown-item
                                href="#"
                                @click="
                                  () => {
                                    this.perPage = 50;
                                  }
                                "
                                >50</b-dropdown-item
                              >
                              <b-dropdown-item
                                href="#"
                                @click="
                                  () => {
                                    this.perPage = '1000';
                                  }
                                "
                                >1000</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; justify-content: flex-end;" v-cant="['USER']">
                      <b-button
                        variant="primary"
                        @click="saveTrainingAlerts()"
                        :data-dismiss="this.isAllValid ? 'modal' : ''"
                        aria-label="Close"
                        id="saveAlertsReasonButton"
                      >
                        Salvar
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
</template>
    
    <script>
    import TwInput from "@/components/TwInput.vue";
    import { formatDate, formatDateAPI } from "../../utils/format-fields";
    import Modal from "../../components/Modal.vue";
    import hasRole from '../../utils/hasRole';
    
    export default {
      name: "TrainingAlertsReason",
      props: {
        customer: {
          type: Object,
          required: false,
        },
      },
      components: {
        TwInput,
        Modal,
      },
      data: function () {
        return {
          filter: null,
          perPage: 10,
          currentPage: 1,
          rows: 1,
          filteredTraining: [],
          sendExclusions: [],
          sortByTrainings: "id",
          training_status_options:[
            { val: "UNASSIGNED", option: "Não Definido" },
            { val: "SCHEDULED", option: "Agendado" },
            { val: "IN-PROGRESS", option: "Em progresso" },
            { val: "COMPLETED", option: "Aprovado" },
            { val: "EXPIRED", option: "Expirado" },
            { val: "CANCELED", option: "Reprovado" },
            { val: "REMOVED", option: "Removido"}
          ],
          alertsTrainingsFields: [
            { label: "Colaborador", key: "employee", sortable: true },
            { label: "Treinamento", key: "training", sortable: true },
            { label: "Estado", key: "status", sortable: true },
            { label: "Sala da Ocorrência", key: "geofence", sortable: true },
            { label: "Hora da Ocorrência", key: "datetime", sortable: true },
            { label: "Motivo", key: "note", sortable: false},
          ],
          itemsAlertsTrainings: [],
          isAllValid: true,
        };
      },
      watch: {
        customer() {
          if (this.customer) {
            this.$store.dispatch("training/getCustomerTrainingAlerts", {
              customer_id: this.customer.id,
            });
          }
        },
        customerAlertsTrainings(){
          //this.itemsAlertsTrainings.length = 0;
          this.itemsAlertsTrainings= [];
          this.customerAlertsTrainings.map((alert) => {
            this.itemsAlertsTrainings = [...this.itemsAlertsTrainings, {...alert}]
          })
          this.customerAlertsTrainings?.map((alert) => {
            alert.errors = {
              note: "",
            }
          })
          this.$refs.alertsTrainingsTable.refresh();

          if (this.$options.name) {
            const ctx = JSON.parse(
              localStorage.getItem(`bstable-state-${this.$options.name}`)
            );
            if (!ctx){
              this.perPage = 10;
              this.currentPage = 1;
              this.sortBy = "";
              this.rows = this.customerAlertsTrainings.length;
              return;
            }
            if (ctx.perPage) {
              this.perPage = ctx.perPage;
            }
            if (ctx.sortBy !== "") {
              this.sortBy = ctx.sortBy;
            }
          }

          this.rows = this.customerAlertsTrainings.length;
        },
      },
      methods: {
        saveTrainingAlerts(){
          let sendAlerts = [];
          this.isAllValid = true;
          this.itemsAlertsTrainings.forEach((alert) => {
              alert.note = alert.note?.trim();
              if(!this.isAlertValid(alert)) {
                this.isAllValid = false; 
              }
              else if (alert.note && alert.note != ""){
                let unit = { 'id': alert.id, 'note': alert.note};
                sendAlerts = [...sendAlerts, {...unit}]
              }
          })
          if (sendAlerts.length > 0 && this.isAllValid){             
            this.$store.dispatch("training/updateCustomerTrainingAlerts", {
              customer_id: this.customer.id,
              alerts_list: { 'logs': sendAlerts}
            }).then(() => {
              this.toggleMonitoring()
            })
          }
        },
        onFiltered(alerts) {
          this.rows = alerts.length;
        },
        isAlertValid(exclusion){
          let valid = true;
          exclusion.errors = {
            note: "",
          }
          if(exclusion.note?.length > 256){
            exclusion.errors.note = "Motivo não pode ter mais do que 256 caracteres."
            valid = false;
          }
          return valid;
        },
        toggleMonitoring(){
          this.$emit('clearMonitoringTarget', true)
        },
        formatDateAPI,
        hasRole,
      },
      computed: {
        customerAlertsTrainings(){
          return this.$store.getters["training/alertTrainings"];
       },
      },
    };
    </script>
    <style>
      .modal-width{
        max-width: 1000px !important;
        
      }
      
      .modal-height{
        max-height: 800px !important;
        overflow-y: auto;
      }
  
      .inside-card{
        padding: 15px;
      }
      .error-message{
        font-size: 10px;
        color: red;
        padding-left: 3px;
      }
      .error-input{
        border: solid 2px red;
      }
      .error-input.button-error{
        color: red;
      }
    </style>
