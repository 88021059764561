const CryptoJS = require('crypto-js/core');
const AES = require('crypto-js/aes');
const Utf8 = require('crypto-js/enc-utf8');
const Hex = require('crypto-js/enc-hex');
const Base64 = require('crypto-js/enc-base64');
const HmacSHA256 = require('crypto-js/hmac-sha256');
const buffer = require('buffer')

//lpad a string for some hex conversions
String.prototype.lpad = function (padString, length) {
  let str = this;
  while (str.length < length) str = padString + str;
  return str;
}

const decode = function decode(base64) {

  // Add removed at end '='
  base64 += Array(5 - base64.length % 4).join('=');

  base64 = base64
    .replace(/\-/g, '+') // Convert '-' to '+'
    .replace(/\_/g, '/'); // Convert '_' to '/'

  return buffer.Buffer.from(base64, 'base64')

};

//Makes a Base64 string a url-safe base64 string
const urlsafe = function urlsafe(string) {
  return string.replace(/\+/g, '-').replace(/\//g, '_')
}

// parse a Hex string to an Int
const parseHex = function parseHex(hexString) {
  return parseInt('0x' + hexString);
}

// turn bits into number of chars in a hex string
const hexBits = function hexBits(bits) {
  return bits / 8 * 2;
}

// convert base64 string to hex string
const decode64toHex = function decode64(string) {
  var s = decode(string.replace(/=+$/, ''));
  return buffer.Buffer.from(s).toString('hex')
}

// convert array to hex string
const ArrayToHex = function ArrayToHex(array) {
  var hex = '';
  for (var _byte in array) {
    hex += Number(_byte).toString(16).lpad('0', 2);
  }
  return hex;
}

const randomHex = function (size) {
  const randomBytes = CryptoJS.lib.WordArray.random(128 / 8);
  return randomBytes.toString(CryptoJS.enc.Hex);
}

const setIV = function setIV(iv_array) {
  if (iv_array) {
    this.ivHex = ArrayToHex(iv_array);
  } else {
    this.ivHex = randomHex(128 / 8);
  }
  this.iv = Hex.parse(this.ivHex);
  return this.ivHex;
}

//convert Time object or now into WordArray
const timeBytes = function timeBytes(time) {
  if (time) {
    time = (time / 1000)
  } else {
    time = (Math.round(new Date() / 1000))
  }
  let hexTime = time.toString(16).lpad('0', '16')
  return Hex.parse(hexTime);
}

const fernet = function fernet(opts) {
  this.Hex = Hex;
  this.Base64 = Base64;
  this.parseHex = parseHex;
  this.decode64toHex = decode64toHex;
  this.hexBits = hexBits;
  this.urlsafe = urlsafe;

  //Sets the secret from base64 encoded value
  this.setSecret = function setSecret(secret64) {
    this.secret = new this.Secret(secret64);
    return this.secret;
  }

  this.ArrayToHex = ArrayToHex;
  this.setIV = setIV;

  this.decryptMessage = function (cipherText, encryptionKey, iv) {
    let encrypted = {};
    encrypted.key = encryptionKey;
    encrypted.iv = iv;
    encrypted.ciphertext = cipherText;

    let decrypted = AES.decrypt(encrypted, encryptionKey, { iv: iv });
    return decrypted.toString(Utf8);
  }

  this.timeBytes = timeBytes;

  this.createToken = function (signingKey, time, iv, cipherText) {
    const hmac = this.createHmac(signingKey, time, iv, cipherText);
    let tokenWords = Hex.parse(this.versionHex);
    tokenWords = tokenWords.concat(time);
    tokenWords = tokenWords.concat(iv);
    tokenWords = tokenWords.concat(cipherText);
    tokenWords = tokenWords.concat(hmac);
    return urlsafe(tokenWords.toString(Base64));
  }

  this.createHmac = function createHmac(signingKey, time, iv, cipherText) {
    let hmacWords = Hex.parse(this.versionHex);
    hmacWords = hmacWords.concat(time);
    hmacWords = hmacWords.concat(iv);
    hmacWords = hmacWords.concat(cipherText);

    return HmacSHA256(hmacWords, signingKey);
  }

  this.Secret = Secret;
  this.Token = Token;

  opts = opts || {};
  this.ttl = opts.ttl || 60;
  // because (0 || x) always equals x
  if (opts.ttl === 0) this.ttl = 0;
  this.versionHex = '80';
  this.setIV(opts.iv);
  if (opts.secret) { this.setSecret(opts.secret) }
}

const Secret = function (secret64 = 'pRH5xbzo2TRgK7elWEsWrDmndBqJ7BgXpVbWVwhz8EM=') {
  const secret = fernet.decode64toHex(secret64);
  if (secret.length !== fernet.hexBits(256)) {
    throw new Error('Secret must be 32 url-safe base64-encoded bytes.');
  }
  this.signingKeyHex = secret.slice(0, fernet.hexBits(128));
  this.signingKey = fernet.Hex.parse(this.signingKeyHex);
  this.encryptionKeyHex = secret.slice(fernet.hexBits(128));
  this.encryptionKey = fernet.Hex.parse(this.encryptionKeyHex);
}

var Token = function Token(opts) {
  opts = opts || {};
  this.secret = opts.secret || fernet.secret;
  this.ttl = opts.ttl || fernet.ttl;
  if (opts.ttl === 0) this.ttl = 0;
  this.message = opts.message;
  this.cipherText = opts.cipherText;
  this.token = opts.token;
  this.version = opts.version || fernet.parseHex(fernet.versionHex);
  this.optsIV = opts.iv;
  this.maxClockSkew = 60;

  if (opts.time) this.setTime(Date.parse(opts.time));
  else this.setTime();
}

Token.prototype = {
  setTime: function tokenSetTime(time) {
    this.time = fernet.timeBytes(time);
  },
  decode: function decodeToken() {
    if (!this.secret) throw (new Error("Secret not set"));
    this.encoded = false;
    // this.token = token || this.token;

    var tokenString = fernet.decode64toHex(this.token);
    var versionOffset = fernet.hexBits(8);
    var timeOffset = versionOffset + fernet.hexBits(64);
    var ivOffset = timeOffset + fernet.hexBits(128);
    var hmacOffset = tokenString.length - fernet.hexBits(256);
    var timeInt = fernet.parseHex(tokenString.slice(versionOffset, timeOffset));

    this.version = fernet.parseHex(tokenString.slice(0, versionOffset));

    if (this.version != 128) {
      throw new Error("Invalid version");
    }

    this.time = new Date(timeInt * 1000);

    var currentTime = new Date()
    var timeDiff = (currentTime - this.time) / 1000;

    if (this.ttl > 0) {
      if (timeDiff > this.ttl) {
        throw new Error("Invalid Token: TTL");
      }

      if (((currentTime / 1000) + this.maxClockSkew) < timeInt) {
        throw new Error("far-future timestamp");
      }
    }
    
    this.ivHex = tokenString.slice(timeOffset, ivOffset);
    this.iv = fernet.Hex.parse(this.ivHex);
    this.cipherTextHex = tokenString.slice(ivOffset, hmacOffset);
    this.cipherText = fernet.Hex.parse(this.cipherTextHex);
    this.hmacHex = tokenString.slice(hmacOffset);
    var decodedHmac = fernet.createHmac(this.secret.signingKey, fernet.timeBytes(this.time), this.iv, this.cipherText);
    var decodedHmacHex = decodedHmac.toString(fernet.Hex);

    var accum = 0
    for (var i = 0; i < 64; i++) {
      accum += decodedHmacHex.charCodeAt(i) ^ this.hmacHex.charCodeAt(i)
    }

    if (accum != 0) throw new Error("Invalid Token: HMAC");

    this.message = fernet.decryptMessage(this.cipherText, this.secret.encryptionKey, this.iv)
    return this.message;
  }
}

exports = module.exports = fernet;
fernet.call(exports)
