<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="id"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button
            id="close-modal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">{{ msg }}</div>

        <div class="modal-footer">
          <slot name="footer"> </slot>
          <button
            id="close-modal2"
            class="btn btn-outline-dark"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            Cancelar
          </button>
          <button
            id="modalexcluir"
            @click="method()"
            type="button"
            :class="`btn btn-${buttonMod || 'danger'}`"
            data-dismiss="modal"
          >
            {{ button }}
          </button>
          <button
            v-if="id === 'ExportModal'"
            type="button"
            class="btn btn-success"
            data-dismiss="modal"
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    id: String,
    msg: String,
    title: String,
    button: String,
    buttonMod: String,
    method: Function,
  },
};
</script>
