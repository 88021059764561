<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Adicionar aplicação</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <div class="card-body">
            <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="card direct-chat direct-chat-primary">
                    <div class="card-body custom-card overflow-visible">
                      <div class="p-4 bg-white rounded">
                        <form
                          @submit.prevent="saveApplication"
                          novalidate="true"
                        >
                          <div class="row">
                            <TwInput label="Nome da Aplicação" required="true">
                              <input
                                type="text"
                                v-model="application.name"
                                class="form-control"
                                placeholder="Nome da Aplicação"
                                id="name"
                              />
                            </TwInput>
                            <TwInput label="Nível de Acesso" required="true">
                              <select
                                id="level"
                                class="form-control"
                                v-model="application.level"
                              >
                                <option
                                  v-for="level in levels"
                                  :key="level"
                                  :value="level"
                                >
                                  {{ level }}
                                </option>
                              </select>
                            </TwInput>
                            <TwInput label="Grupo" required="true">
                              <select
                                id="group"
                                class="form-control"
                                v-model="application.group"
                              >
                                <option
                                  v-for="group in groups"
                                  :key="group"
                                  :value="group"
                                >
                                  {{ group }}
                                </option>
                              </select>
                            </TwInput>
                            <TwInput label="Senha" required="true">
                              <input
                                type="password"
                                v-model="application.password"
                                class="form-control"
                                placeholder="Senha"
                                id="password"
                              />
                            </TwInput>
                          </div>
                          <div class="row p-1">
                            <button
                              id="saveApplication"
                              type="submit"
                              class="btn btn-primary px-5"
                              :disabled="load"
                            >
                              <i
                                v-if="load"
                                class="fas fa-sync-alt fa-spin"
                              ></i>
                              Salvar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      id="ModalAppId"
      title="App ID Gerado"
      style="white-space: pre-wrap; font-size: 18px"
      :msg="'O app_id gerado é: ' + appId + '\nLembre-se de anotá-lo!'"
      button="Finalizar"
      :method="closeModal"
    >
      <template v-slot:footer>
        <button
          id="copyAppId"
          type="button"
          class="btn btn-secondary"
          @click="copyAppId"
        >
          Copiar App-ID
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import TwInput from "@/components/TwInput.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import Modal from "../../components/Modal.vue";

export default {
  name: "AppIDAdd",
  data() {
    return {
      breadcrumbList: [],
      application: {
        name: "",
        level: "",
        group: "",
        password: "",
      },
      isAppIdValid: false,
      levels: ["DASHBOARD", "USER", "USRADMIN"],
    };
  },
  mounted() {
    this.updateList();
    this.$store.dispatch("user/getGroups");
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    validateApplication() {
      this.isAppIdValid = false;
      if (!this.application.name.trim()) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome da Aplicação" });
        return false;
      }
      if (!this.application.level) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nível de Acesso" });
        return false;
      }
      if (!this.application.group) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Grupo" });
        return false;
      }
      if (!this.application.password.trim()) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Senha" });
        return false;
      }
      return true;
    },
    async saveApplication() {
      if (this.validateApplication()) {
        this.isAppIdValid = true;
        await this.$store.dispatch("applications/saveAppIds", this.application);
        $("#ModalAppId").modal("show");
      }
    },
    closeModal() {
      this.$router.push("/appID");
    },
    copyAppId() {
      if (this.appId) {
        navigator.clipboard
          .writeText(this.appId)
          .then(() => {
            window.frontMsg("GLOBAL_SUCCESS_COPY");
          })
      }
    },
  },
  components: {
    Breadcrumb,
    TwInput,
    Modal,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    groups() {
      return this.$store.getters["user/groups"].map((group) => group.name);
    },

    appId() {
      return this.$store.getters["applications/app_id_result"];
    },
  },
};
</script>

<style scoped>
button.btn-secondary {
  margin-right: 10px;
}
</style>
