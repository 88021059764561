<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Configuração do Coletor</h1>
    </div>

    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card-body">
        <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
          <div class="row mb-2">
            <div class="col-12">
              <div class="card direct-chat direct-chat-primary employee">
                <div class="card-body custom-card overflow-visible">
                  <ul class="nav nav-tabs bg-main">
                    <li class="nav-item">
                      <a
                        id="info-tab"
                        class="nav-link first"
                        v-bind:class="{
                          active: infoTabSelected,
                        }"
                        v-on:click="changeTab('infoTabSelected')"
                        href="#"
                        aria-current="infoTabSelected"
                        tabindex="-1"
                        >Coletor</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        id="addGateway-tab"
                        class="nav-link"
                        v-bind:class="{
                          active: addGatewaySelected,
                        }"
                        v-on:click="changeTab('addGateway')"
                        href="#"
                        aria-current="addGateway"
                        tabindex="-1"
                        >Bluetooth</a
                      >
                    </li>
                    <li class="nav-item active">
                      <a
                        class="nav-link"
                        v-bind:class="{
                          active: this.tabSelected === 'servidor',
                        }"
                        v-on:click="changeTab('servidor')"
                        href="#"
                        aria-current="servidor"
                        tabindex="-1"
                        id="servidor"
                        >Servidor</a
                      >
                    </li>
                    <li class="nav-item active">
                      <a
                        class="nav-link"
                        v-bind:class="{
                          active: this.tabSelected === 'transmissão',
                        }"
                        v-on:click="changeTab('transmissão')"
                        href="#"
                        aria-current="transmissão"
                        tabindex="-1"
                        id="transmission"
                        >Transmissão</a
                      >
                    </li>
                  </ul>
                  <div class="p-4 bg-white rounded">
                    <div class="tab-content">
                      <div
                        class="tab-pane fade"
                        v-bind:class="{
                          show: infoTabSelected,
                          active: infoTabSelected,
                        }"
                      >
                        <div
                          class="title-s nav-tabs pt-4"
                          style="font-size: 20px; padding-bottom: 10px"
                        >
                          Informações do Coletor
                        </div>
                        <div class="row">
                          <div class="card-body">
                            <div class="p-4 bg-white rounded">
                              <div class="col-6">
                                <div class="row">
                                  <TwInput label="Nome do Coletor" disabled>
                                    <input
                                      id="name"
                                      v-model="gateway.gateway_name"
                                      type="text"
                                      class="form-control"
                                      disabled
                                    />
                                  </TwInput>
                                </div>
                                <div class="row">
                                  <TwInput label="Identificador MAC" disabled>
                                    <input
                                      id="mac_address"
                                      v-model="gateway.mac_address"
                                      type="text"
                                      class="form-control"
                                      v-mask="'NN:NN:NN:NN:NN:NN'"
                                      placeholder="MAC"
                                      disabled
                                    />
                                  </TwInput>
                                </div>
                                <div class="row">
                                  <!-- Do the same for administrative state -->
                                  <TwInput
                                    label="Estado Administrativo"
                                    disabled
                                  >
                                    <div class="d-flex align-items-center">
                                      <div class="mr-2">
                                        <i
                                          class="fas fa-circle"
                                          v-if="
                                            gateway.gateway_state &&
                                            gateway.gateway_state
                                              .administrative_state ===
                                              'IN-SERVICE'
                                          "
                                          style="color: #00c853"
                                          :title="
                                            gateway.gateway_state
                                              .administrative_state
                                          "
                                        >
                                        </i>
                                        <i
                                          class="fas fa-circle"
                                          v-else
                                          style="color: #d50000"
                                          :title="
                                            gateway.gateway_state
                                              .administrative_state
                                          "
                                        ></i>
                                      </div>
                                      <div>
                                        <p
                                          class="same-size-ps form-control"
                                          style="margin-bottom: 0px"
                                        >
                                          {{
                                            gateway.gateway_state
                                              .administrative_state
                                          }}
                                        </p>
                                      </div>
                                    </div>
                                  </TwInput>
                                </div>
                                <div class="row">
                                  <TwInput label="Estado Operacional" disabled>
                                    <div class="d-flex align-items-center">
                                      <div class="mr-2">
                                        <i
                                          class="fas fa-circle"
                                          v-if="
                                            gateway.gateway_state &&
                                            gateway.gateway_state
                                              .operational_state === 'ONLINE'
                                          "
                                          style="color: #00c853"
                                          :title="
                                            gateway.gateway_state
                                              .operational_state
                                          "
                                        ></i>
                                        <i
                                          class="fas fa-circle"
                                          v-else
                                          style="color: #d50000"
                                          :title="
                                            gateway.gateway_state
                                              .operational_state
                                          "
                                        ></i>
                                      </div>
                                      <div>
                                        <p
                                          class="same-size-ps form-control"
                                          style="margin-bottom: 0px"
                                        >
                                          {{
                                            gateway.gateway_state
                                              .operational_state
                                          }}
                                        </p>
                                      </div>
                                    </div>
                                  </TwInput>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        v-bind:class="{
                          show: addGatewaySelected,
                          active: addGatewaySelected,
                        }"
                      >
                        <div
                          class="title-s nav-tabs pt-4"
                          style="font-size: 20px; padding-bottom: 10px"
                        >
                          Configurações do Bluetooth
                        </div>
                        <div class="row">
                          <div class="card-body">
                            <div class="p-4 bg-white rounded">
                              <!-- <form @submit="updateGateway" novalidate="true"> -->
                              <div class="col-12">
                                <div class="row">
                                  <TwInput
                                    label="Limite mínimo RSSI"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Nível máximo de potência detectável do sinal na antena interna"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="limitRSSI"
                                      v-model="ble.rssi_limit"
                                      type="number"
                                      min="-100"
                                      max="0"
                                      class="form-control"
                                      placeholder="Limite mínimo RSSI"
                                      required
                                    />
                                  </TwInput>
                                  <TwInput
                                    label="Limite mínimo RSSI (Antena 1)"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Nível máximo de potência detectável do sinal na antena 1"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="limitRSSI_1"
                                      v-model="ble.rssi_limit_usb1"
                                      type="number"
                                      min="-100"
                                      max="0"
                                      class="form-control"
                                      placeholder="Limite mínimo RSSI (Antena 1)"
                                      required
                                    />
                                  </TwInput>
                                  <TwInput
                                    label="Limite mínimo RSSI (Antena 2)"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Nível máximo de potência detectável do sinal na antena 2"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="limitRSSI_2"
                                      v-model="ble.rssi_limit_usb2"
                                      type="number"
                                      min="-100"
                                      max="0"
                                      class="form-control"
                                      placeholder="Limite mínimo RSSI (Antena 2)"
                                      required
                                    />
                                  </TwInput>
                                  
                                </div>
                                <div class="row">
                                  <TwInput
                                    label="Limite mínimo RSSI (Antena 3)"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Nível máximo de potência detectável do sinal na antena 3"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="limitRSSI_3"
                                      v-model="ble.rssi_limit_usb3"
                                      type="number"
                                      min="-100"
                                      max="0"
                                      class="form-control"
                                      placeholder="Limite mínimo RSSI (Antena 3)"
                                      required
                                    />
                                  </TwInput>
                                  <TwInput
                                    label="Limite mínimo RSSI (Antena 4)"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Nível máximo de potência detectável do sinal na antena 4"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="limitRSSI_4"
                                      v-model="ble.rssi_limit_usb4"
                                      type="number"
                                      min="-100"
                                      max="0"
                                      class="form-control"
                                      placeholder="Limite mínimo RSSI (Antena 4)"
                                      required
                                    />
                                  </TwInput>
                                  <div class="col-4">
                                    <TwInput
                                      label="Tempo de inatividade das antenas"
                                      required="true"
                                      v-show="use_timeout"
                                      col_lg="12"
                                    >
                                    <i
                                      title=" Tempo, em segundos, até as antenas ficarem inativas"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                      <input
                                        id="inactivityTimeLimit"
                                        v-model="ble.inactivity_timeout"
                                        type="text"
                                        class="form-control"
                                        placeholder="Limite mínimo RSSI (Antena 1)"
                                        required
                                      />
                                    </TwInput>
                                  </div>
                                </div>
                                <!-- TEMPO DE INATIVIDADE-->
                                <div class="row">
                                  <label style="padding-left: 2rem">
                                        <input
                                          v-model="use_timeout"
                                          type="checkbox"
                                          class="form-check-input"
                                          id="check-activate-inactive_time"
                                          label="Tempo de Inatividade"
                                          name="inactivityTime"
                                        />
                                        <label
                                          for="check-activate-inactive_time"
                                          class="form-check-label"
                                          style="margin-left: 10px"
                                        >
                                          Utilizar tempo de inatividade
                                        </label>
                                    </label>
                                </div>
                                <!-- FIM TEMPO DE INATIVIDADE-->
                                <!-- ANTENA -->
                                <div class="margin-left-zero row">
                                  <div class="col-12 mb-0 pt-3">
                                    <div class="form-group mb-0">
                                      <label
                                        >Antena externa
                                        <span class="required">*</span></label
                                      >
                                      <b-form-group>
                                        <b-form-radio-group
                                          v-model="selectedStatus"
                                          :options="statusOptions"
                                          plain
                                          name="antena"
                                        />
                                      </b-form-group>
                                    </div>
                                  </div>
                                </div>

                                <!-- ANTENA -->
                                <!-- AUTOPROVISIONAMENTO -->
                                <div class="margin-left-zero row">
                                  <div class="col-4 mb-0 pt-3">
                                    <div class="form-group mb-0">
                                      <label
                                        >Auto provisionamento
                                        <span class="required">*</span></label
                                      >
                                      <b-form-group>
                                        <b-form-radio-group
                                          v-model="selectedAutoprovision"
                                          :options="statusAutoprovision"
                                          plain
                                          name="autoprovision"
                                        />
                                      </b-form-group>
                                    </div>
                                  </div>
                                </div>
                                <!-- AUTOPROVISIONAMENTO -->
                              </div>
                              <!-- </form> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade"
                        v-bind:class="{
                          show: servidorSelected,
                          active: servidorSelected,
                        }"
                      >
                        <div
                          class="title-s nav-tabs pt-4"
                          style="font-size: 20px; padding-bottom: 10px"
                        >
                          Configurações do Servidor
                        </div>
                        <div class="row mt-3">
                          <TwInput col_lg="4" label="App Id" required="true">
                            <i
                              title=" Identificador da aplicação para o acesso ao servidor de api"
                              class="fas fa-question-circle"
                              style="margin-left: 5px"
                            >
                            </i>
                            <input
                              id="appId"
                              v-model="app.id"
                              type="text"
                              maxlength="64"
                              class="form-control"
                              placeholder="App Id"
                              required
                            />
                          </TwInput>

                          <TwInput
                            col_lg="4"
                            label="Nome da aplicação"
                            required="true"
                          >
                            <input
                              id="appName"
                              name="appName"
                              v-model="app.name"
                              type="text"
                              maxlength="64"
                              class="form-control"
                              placeholder="Nome da aplicação"
                              required
                            />
                          </TwInput>
                          <TwInput
                            col_lg="4"
                            label="Senha da aplicação"
                            class="secured"
                            required="true"
                          >
                            <input
                              id="appPassword"
                              name="appPassword"
                              v-model="app.pwd"
                              :type="showAppPassword ? 'text' : 'password'"
                              maxlength="64"
                              class="form-control"
                              placeholder="Senha da aplicação"
                              required
                            />
                            <i
                              class="eyesight fas"
                              style="margin-left: 5px"
                              v-on:click="showAppPassword = !showAppPassword"
                              v-bind:class="{
                                'fa-eye-slash': showAppPassword,
                                'fa-eye': !showAppPassword,
                              }"
                            ></i>
                          </TwInput>
                          <TwInput
                            col_lg="4"
                            label="Endereço da API"
                            required="true"
                          >
                            <i
                              title="Endereço do servidor de api: http(s)://<IP>:<PORT>"
                              class="fas fa-question-circle"
                              style="margin-left: 5px"
                            >
                            </i>
                            <input
                              id="appServer"
                              v-model="app.server"
                              type="text"
                              maxlength="128"
                              class="form-control"
                              placeholder="Endereço da API"
                            />
                          </TwInput>
                        </div>
                        <div
                          class="title-s nav-tabs pt-4"
                          style="font-size: 20px; padding-bottom: 10px"
                        >
                          Intervalos de tempo
                        </div>

                        <div class="row mt-3">
                          <TwInput
                            col_lg="4"
                            label="Status do Coletor"
                            required="true"
                          >
                            <i
                              title="Intervalo de tempo (ms) para o envio de status do Coletor"
                              class="fas fa-question-circle"
                              style="margin-left: 5px"
                            >
                            </i>
                            <input
                              id="statusInterval"
                              v-model="app.send_status_interval"
                              type="text"
                              class="form-control"
                              required
                            />
                          </TwInput>

                          <TwInput col_lg="4" label="Distância" required="true">
                            <i
                              title="Intervalo de tempo (ms) para o envio de dados de distância"
                              class="fas fa-question-circle"
                              style="margin-left: 5px"
                            >
                            </i>
                            <input
                              id="intervalDistance"
                              v-model="app.send_data_interval"
                              type="text"
                              class="form-control"
                              required
                            />
                          </TwInput>
                          <TwInput col_lg="4" label="Bateria" required="true">
                            <i
                              title="Intervalo de tempp (ms) para o envio dos níveis de bateria"
                              class="fas fa-question-circle"
                              style="margin-left: 5px"
                            >
                            </i>
                            <input
                              id="batteryInterval"
                              v-model="app.send_battery_interval"
                              type="text"
                              class="form-control"
                              required
                            />
                          </TwInput>
                          <TwInput
                            col_lg="4"
                            label="Sinais Vitais"
                            required="true"
                          >
                            <i
                              title="Intervalo de tempo (ms) para o envio de sinais vitais"
                              class="fas fa-question-circle"
                              style="margin-left: 5px"
                            >
                            </i>
                            <input
                              id="sinaisvitais"
                              v-model="app.send_health_interval"
                              type="text"
                              class="form-control"
                              required
                            />
                          </TwInput>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        v-bind:class="{
                          show: transmissãoSelected,
                          active: transmissãoSelected,
                        }"
                      >
                        <div
                          class="title-s nav-tabs pt-4"
                          style="font-size: 20px; padding-bottom: 10px"
                        >
                          Configurações da Transmissão
                        </div>
                        <div class="mt-3">
                          <div
                            id="check"
                            class="form-check row"
                            style="margin-left: 10px; margin-top: 20px"
                          >
                            <label for="">
                              <input
                                v-on:click="Show_http"
                                v-model="ass_http"
                                plain
                                type="radio"
                                class="form-check-input"
                                id="check-http"
                                name="check-trans"
                              />

                              <label
                                for="check-skip-2factor"
                                class="form-check-label"
                                style="margin-left: 5px"
                              >
                                HTTP
                              </label>
                            </label>
                            <label for="">
                              <input
                                v-on:click="Show_mqtt"
                                v-model="ass_mqtt"
                                plain
                                type="radio"
                                class="form-check-input"
                                id="check-mqtt"
                                name="check-trans"
                                style="margin-left: 40px"
                              />

                              <label
                                for="check-skip-2factor"
                                class="form-check-label"
                                style="margin-left: 65px"
                              >
                                MQTT
                              </label>
                            </label>
                            <!-- HTTP -->
                            <div
                              class="title-s nav-tabs pt-4 pb-2"
                              style="font-size: 20px"
                              v-if="passhttp"
                            >
                              HTTP
                            </div>
                            <div
                              class="row pb-2 mt-2"
                              id="esc"
                              v-show="passhttp"
                            >
                              <TwInput
                                col_lg="4"
                                label="Endereço do servidor"
                                required="true"
                              >
                                <input
                                  placeholder="Endereço do servidor HTTP"
                                  id="httpServer"
                                  v-if="passhttp"
                                  v-model="http.url"
                                  type="text"
                                  maxlength="128"
                                  class="form-control"
                                />
                              </TwInput>

                              <TwInput col_lg="4" label="Token" required="true">
                                <i
                                  title=" Token de autorização entre o cliente e o servidor http"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  placeholder="Token de autorização"
                                  id="httpToken"
                                  v-if="passhttp"
                                  v-model="http.token"
                                  :type="showHttpToken ? 'text' : 'password'"
                                  class="form-control"
                                  maxlength="512"
                                />
                                <i
                                  class="eyesight fas"
                                  style="margin-left: 5px"
                                  v-on:click="showHttpToken = !showHttpToken"
                                  v-bind:class="{
                                    'fa-eye-slash': showHttpToken,
                                    'fa-eye': !showHttpToken,
                                  }"
                                ></i>
                              </TwInput>
                              <TwInput
                                col_lg="4"
                                label="Tamanho do buffer"
                                required="true"
                              >
                                <i
                                  title=" Número de mensagens enviadas a cada requisição"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="bufferSize"
                                  v-if="passhttp"
                                  v-model="http.entries"
                                  type="text"
                                  class="form-control"
                                />
                              </TwInput>
                            </div>
                            <div
                              class="title-s nav-tabs pt-4"
                              style="font-size: 20px"
                              v-if="passhttp"
                            >
                              Rotas
                            </div>
                            <div
                              class="row nav-tabs pb-3 mt-3"
                              id="esc"
                              v-show="passhttp"
                            >
                              <TwInput
                                col_lg="4"
                                label="Status de coletor"
                                required="true"
                              >
                                <i
                                  title=" Rota para o envio de status de COLETOR ao servidor http"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="httpStatus"
                                  v-if="passhttp"
                                  v-model="http.status_route"
                                  type="text"
                                  maxlength="128"
                                  class="form-control"
                                />
                              </TwInput>

                              <TwInput
                                col_lg="4"
                                label="Dados de distância"
                                required="true"
                              >
                                <i
                                  title=" Rota para o envio de dados de distância ao servidor http"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="httpDistance"
                                  v-if="passhttp"
                                  v-model="http.data_route"
                                  type="text"
                                  maxlength="128"
                                  class="form-control"
                                />
                              </TwInput>
                              <TwInput
                                col_lg="4"
                                label="Níveis de bateria"
                                required="true"
                              >
                                <i
                                  title="  Rota para o envio dos níveis de bateria ao servidor http."
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="httpBattery"
                                  v-if="passhttp"
                                  v-model="http.battery_route"
                                  type="text"
                                  maxlength="128"
                                  class="form-control"
                                />
                              </TwInput>
                              <TwInput
                                col_lg="4"
                                label="Sinais Vitais"
                                required="true"
                              >
                                <i
                                  title="Rota para o envio de sinais vitais."
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="sinaisvitais"
                                  v-if="passhttp"
                                  v-model="http.health_route"
                                  type="text"
                                  maxlength="128"
                                  class="form-control"
                                />
                              </TwInput>
                            </div>
                            <!-- HTTP -->

                            <!-- MQTT -->
                            <div
                              class="title-s nav-tabs pt-4 pb-2"
                              style="font-size: 20px"
                              v-if="passmqtt"
                            >
                              MQTT
                            </div>
                            <div
                              class="row pb-2 mt-2"
                              id="esc"
                              v-show="passmqtt"
                            >
                              <TwInput
                                col_lg="4"
                                label="Usuário"
                                required="true"
                              >
                                <i
                                  title=" Usuário para a autenticação no mqtt broker"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="mqttUsername"
                                  placeholder="Usuário para autenticação mqtt"
                                  v-if="passmqtt"
                                  v-model="mqtt.username"
                                  type="text"
                                  maxlength="64"
                                  class="form-control"
                                />
                              </TwInput>

                              <TwInput col_lg="4" label="Senha" required="true">
                                <i
                                  title=" Senha para a autenticação mqtt"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  placeholder="Senha para a autenticação no mqtt broker"
                                  id="mqttPassword"
                                  v-if="passmqtt"
                                  v-model="mqtt.password"
                                  :type="showMqttPassword ? 'text' : 'password'"
                                  class="form-control"
                                  maxlength="64"
                                />
                                <i
                                  class="eyesight fas"
                                  style="margin-left: 5px"
                                  v-on:click="
                                    showMqttPassword = !showMqttPassword
                                  "
                                  v-bind:class="{
                                    'fa-eye-slash': showMqttPassword,
                                    'fa-eye': !showMqttPassword,
                                  }"
                                ></i>
                              </TwInput>
                              <TwInput col_lg="4" label="QoS" required="true">
                                <i
                                  title=" Nível da qualidade de serviço (0,1,2)"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="mqttQos"
                                  v-if="passmqtt"
                                  v-model="mqtt.qos"
                                  type="text"
                                  class="form-control"
                                />
                              </TwInput>
                              <TwInput
                                col_lg="4"
                                label="Endereço"
                                required="true"
                              >
                                <i
                                  title=" Endereço do broker mqtt. Ex: 169.254.10.10"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                ></i>
                                <input
                                  id="mqttHost"
                                  placeholder="Endereço do broker mqtt"
                                  v-if="passmqtt"
                                  v-model="mqtt.host"
                                  type="text"
                                  class="form-control"
                                  maxlength="128"
                                />
                              </TwInput>
                              <TwInput col_lg="4" label="Porta" required="true">
                                <input
                                  id="mqttPort"
                                  v-if="passmqtt"
                                  v-model="mqtt.port"
                                  type="text"
                                  class="form-control"
                                />
                              </TwInput>
                              <TwInput
                                col_lg="4"
                                label="keepalive"
                                required="true"
                              >
                                <i
                                  title=" Tempo de envio do keepalive ao mqtt broker"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="mqttKeepalive"
                                  v-if="passmqtt"
                                  v-model="mqtt.keepalive"
                                  type="text"
                                  class="form-control"
                                />
                              </TwInput>
                            </div>
                            <div
                              class="title-s nav-tabs pt-4"
                              style="font-size: 20px"
                              v-if="passmqtt"
                            >
                              Tópicos MQTT para publicação
                            </div>
                            <div
                              class="row nav-tabs pb-3 mt-3"
                              id="esc"
                              v-show="passmqtt"
                            >
                              <TwInput
                                col_lg="4"
                                label="Status"
                                required="true"
                              >
                                <i
                                  title=" Tópico mqtt para a publicação dos status de COLETOR"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="mqttStatus"
                                  v-if="passmqtt"
                                  v-model="mqtt.status_topic"
                                  type="text"
                                  class="form-control"
                                  maxlength="64"
                                />
                              </TwInput>

                              <TwInput
                                col_lg="4"
                                label="Dados de distância"
                                required="true"
                              >
                                <i
                                  title="Tópico mqtt para a publicação dos dados de distância"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="mqttDistance"
                                  v-if="passmqtt"
                                  v-model="mqtt.data_topic"
                                  type="text"
                                  class="form-control"
                                  maxlength="64"
                                />
                              </TwInput>
                              <TwInput
                                col_lg="4"
                                label="Níveis de bateria"
                                required="true"
                              >
                                <i
                                  title="Tópico mqtt para a publicação dos niveis de bateria"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="mqttBattery"
                                  v-if="passmqtt"
                                  v-model="mqtt.battery_topic"
                                  type="text"
                                  class="form-control"
                                  maxlength="64"
                                />
                              </TwInput>
                              <TwInput
                                col_lg="4"
                                label="Sinais Vitais"
                                required="true"
                              >
                                <i
                                  title="Tópico para o envio de sinais vitais"
                                  class="fas fa-question-circle"
                                  style="margin-left: 5px"
                                >
                                </i>
                                <input
                                  id="sinaisvitais"
                                  v-if="passmqtt"
                                  v-model="mqtt.health_topic"
                                  type="text"
                                  class="form-control"
                                  maxlength="64"
                                />
                              </TwInput>
                            </div>
                            <!-- MQTT -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row p-1">
            <button
              id="saveEditGateway"
              class="btn btn-primary px-5"
              v-on:click="updateGateway"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
import fernet from "../../utils/fernet";

export default {
  name: "GatewayConfig",
  data: function () {
    return {
      tabSelected: "infoTabSelected",
      breadcrumbList: [],
      DEFAULT: true,
      MIN_RSSI: -100,
      MAX_RSSI:0,
      use_timeout: true,
      passinais: false,
      ass_sinais_mqtt: false,
      ass_sinais_http: false,
      passantena: true,
      passinais_mqtt: false,
      passinais_http: false,
      send_health_interval: false,
      passhttp: false,
      passmqtt: true,
      ass_http: false,
      ass_mqtt: true,
      mac_address: "",
      transmission: "",
      ble: {
        hci_device_num: 0,
        measured_tx_power_global_enable: true,
        measured_tx_power_value: -62,
        inactivity_timeout: 3600,
        rssi_limit: -100,
        rssi_limit_usb1: -100,
        rssi_limit_usb2: -100,
        rssi_limit_usb3: -100,
        rssi_limit_usb4: -100
      },
      app: {
        id: "",
        logging_level: "INFO",
        name: "",
        pwd: "",
        send_battery_interval: "30000",
        send_data_interval: "5000",
        send_health_interval: "600000",
        send_status_interval: "10000",
        server: "",
      },
      http: {
        battery_route: "/v0/battery",
        data_route: "/v0/sensors",
        entries: 30,
        health_route: "/v0/health",
        status_route: "/v0/status",
        token: "",
        url: "",
      },
      mqtt: {
        status_topic: "status",
        battery_topic: "battery",
        data_topic: "sensors",
        health_topic: "health",
        host: "",
        keepalive: "10",
        password: "",
        port: "1886",
        qos: "2",
        username: "",
      },
      gateway: {
        gateway_model: "",
        gateway_name: "",
        gateway_type: "",
        mac_address: "",
        serial_number: "",
        gateway_state: "",
      },
      selectedAutoprovision: "",
      selectedStatus: "",
      statusOptions: [
        { text: "Sim", value: "true" },
        { text: "Não", value: "false" },
      ],
      statusAutoprovision: [
        { text: "Sim", value: "true" },
        { text: "Não", value: "false" },
      ],
      showAppPassword: false,
      showMqttPassword: false,
      showHttpToken: false,
    };
  },
  created() {
    this.mac_address = this.$route.params.mac_address;

    if (this.$route.params.gateway.gateway_config) {
      this.$store.dispatch("gateway/getConfig", {
        mac_address: this.mac_address,
      });
    }

    this.updateList();
    this.$store.dispatch("gateway/getModels");
    this.$store.dispatch("gateway/getTypes");
    this.$store.dispatch("gateway/getStatusGateway", {
      mac_address: this.mac_address,
    });
  },
  mounted() {
    if (this.passmqtt && this.DEFAULT) {
      this.transmission == "mqtt";
      this.passmqtt = true;
      this.ass_mqtt = true;
      document.getElementById("check-mqtt").checked = true;
      this.selectedAutoprovision = "true";
    }
    if (this.passantena && this.DEFAULT) {
      this.antena = true;
      this.selectedStatus = "true";
      document.getElementById("check-antena").checked = true;
      this.selectedAutoprovision = "true";
    }
  },
  watch: {
    $route() {
      this.updateList();
    },
    gatewayconfig() {
      localStorage.setItem("gatewayconfig", JSON.stringify(this.gatewayconfig));
      this.DEFAULT = false;
      this.app = this.gatewayconfig.app;
      this.http = this.gatewayconfig.http;
      this.mqtt = this.gatewayconfig.mqtt;
      this.ble = this.gatewayconfig.ble;
      this.antena = this.gatewayconfig.antena;
      this.autoprovision = this.gatewayconfig.autoprovision;
      this.transmission = this.gatewayconfig.transmission;

      const secret = new fernet.Secret()

      const app_id_token = new fernet.Token({
        secret: secret,
        token: this.app.id,
        ttl: 0
      })
      
      const app_pwd_token = new fernet.Token({
        secret: secret,
        token: this.app.pwd,
        ttl: 0
      })

      this.app.id = app_id_token.decode()
      this.app.pwd = app_pwd_token.decode()

      if (this.antena) {
        this.selectedStatus = "true";
      } else {
        this.selectedStatus = "false";
      }
      if (this.autoprovision) {
        this.selectedAutoprovision = "true";
      } else {
        this.selectedAutoprovision = "false";
      }
      if (this.ble.inactivity_timeout == -1){
        this.use_timeout = false;
      }
      if (this.transmission == "http") {
        this.passhttp = true;
        this.ass_http = true;
        this.passmqtt = false;
        this.ass_mqtt = false;
        document.getElementById("check-http").checked = true;
      } else {
        this.transmission == "mqtt";
        this.passmqtt = true;
        this.ass_mqtt = true;
        this.passhttp = false;
        this.ass_http = false;
        document.getElementById("check-mqtt").checked = true;
      }
      if (this.app.send_health_interval) {
        this.send_health_interval = true;
        this.passinais = true;
      }
      if (this.http.health_route) {
        this.passinais_http = true;
        this.ass_sinais_http = true;
      }
      this.updateList();
    },
    gatewayStatus() {
      this.gateway = this.gatewayStatus;
      localStorage.setItem("gateway", JSON.stringify(this.gateway));
      this.updateList();
    },
    use_timeout(val){
      /*Used to improve user experience: Now valid timeout value is "remembered" and 
      -1 is never shown (all other behavior remains the same)*/
      if(val==true&&(this.ble.inactivity_timeout < 180 || this.ble.inactivity_timeout > 45000)){
        /*All invalid values are change into default value*/
        this.ble.inactivity_timeout=3600;
      }
    },
  },
  methods: {
    Show_http() {
      this.passhttp = true;
      this.passmqtt = false;
    },
    Show_mqtt() {
      this.passmqtt = true;
      this.passhttp = false;
    },
    sinaisvitais() {
      this.passinais = !this.passinais;
    },
    sinaisvitais_mqtt() {
      this.passinais_mqtt = !this.passinais_mqtt;
    },
    sinaisvitais_http() {
      this.passinais_http = !this.passinais_http;
    },
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    isGatewayValid(gatewayconfig) {
      if (this.passantena) {
        this.antena = "true";
        gatewayconfig.antena = "true";
      }

      if (this.passhttp && !this.passmqtt) {
        this.transmission = "http";
      } else {
        this.transmission = "mqtt";
        this.passhttp = false;
        this.passmqtt = true;
      }

      if (this.ble.rssi_limit == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Limite RSSI" }
        );
        return false;
      }

      else if (parseInt(this.ble.rssi_limit) != this.ble.rssi_limit) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Limite RSSI" }
        );
        return false;
      }
     
      if (this.ble.rssi_limit>this.MAX_RSSI || this.ble.rssi_limit<this.MIN_RSSI) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Limite RSSI",
          numbercondition: "inteiro entre "+ this.MIN_RSSI+" e "+ this.MAX_RSSI ,
        });
        return false;
      }      

      if (this.ble.rssi_limit_usb1 == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Limite RSSI (antena 1)" }
        );
        return false;
      }

      else if (parseInt(this.ble.rssi_limit_usb1) != this.ble.rssi_limit_usb1) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Limite RSSI (antena 1)" }
        );
        return false;
      }
           
      if (this.ble.rssi_limit_usb1>this.MAX_RSSI || this.ble.rssi_limit_usb1<this.MIN_RSSI) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Limite RSSI (antena 1)",
          numbercondition: "inteiro entre "+ this.MIN_RSSI+" e "+ this.MAX_RSSI ,
        });
        return false;
      }      

      if (this.ble.rssi_limit_usb2 == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Limite RSSI (antena 2)" }
        );
        return false;
      }

      else if (parseInt(this.ble.rssi_limit_usb2) != this.ble.rssi_limit_usb2) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Limite RSSI (antena 2)" }
        );
        return false;
      }
                 
      if (this.ble.rssi_limit_usb2>this.MAX_RSSI || this.ble.rssi_limit_usb2<this.MIN_RSSI) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Limite RSSI (antena 2)",
          numbercondition: "inteiro entre "+ this.MIN_RSSI+" e "+ this.MAX_RSSI ,
        });
        return false;
      }    

      if (this.ble.rssi_limit_usb3 == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Limite RSSI (antena 3)" }
        );
        return false;
      }

      else if (parseInt(this.ble.rssi_limit_usb3) != this.ble.rssi_limit_usb3) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Limite RSSI (antena 3)" }
        );
        return false;
      }
                       
      if (this.ble.rssi_limit_usb3>this.MAX_RSSI || this.ble.rssi_limit_usb3<this.MIN_RSSI) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Limite RSSI (antena 3)",
          numbercondition: "inteiro entre "+ this.MIN_RSSI+" e "+ this.MAX_RSSI ,
        });
        return false;
      } 

      if (this.ble.rssi_limit_usb4 == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Limite RSSI (antena 4)" }
        );
        return false;
      }

      else if (parseInt(this.ble.rssi_limit_usb4) != this.ble.rssi_limit_usb4) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Limite RSSI (antena 4)" }
        );
        return false;
      }
                       
      if (this.ble.rssi_limit_usb4>this.MAX_RSSI || this.ble.rssi_limit_usb4<this.MIN_RSSI) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Limite RSSI (antena 4)",
          numbercondition: "inteiro entre "+ this.MIN_RSSI+" e "+ this.MAX_RSSI ,
        });
        return false;
      } 

      if (this.use_timeout){
        if (this.ble.inactivity_timeout == ""){
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
            { field: "Tempo de inatividade das antenas" }
          );

          return false;
        }

        else if (this.ble.inactivity_timeout < 180 || this.ble.inactivity_timeout > 45000 ){
          window.frontMsg(
            "FRNT_NUM_RANGE_WARNING",
            { field: "Tempo de inatividade das antenas", 
              numbercondition: "inteiro entre 180 e 45000"} 
          );
          return false;
        }

        else if (parseInt(this.ble.inactivity_timeout) != this.ble.inactivity_timeout) {
          window.frontMsg(
            "FRNT_INVALID_FIELD_WARNING",
            { field: "Tempo de inatividade das antenas" }
          );
          return false;
        }
      }

      if (
        (this.passhttp && this.passmqtt) ||
        (!this.passhttp && !this.passmqtt)
      ) {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Transmissão" }
        );
        return false;
      }
      //TODO: Refactor this madness
      /** App validation **/
      if (this.app.id === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "App ID em Servidor" }
        );
        return false;
      }
      if (
        /^([0-9A-Fa-f]{8}[-])([0-9A-Fa-f]{4}[-]){4}([0-9A-Fa-f]{12})$/i.test(
          this.app.id
        )
      ) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "App Id" }
        );
        return false;
      }
      if (this.app.name === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome da Aplicação em Servidor" }
        );
        return false;
      }

      if (this.app.pwd === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Senha da Aplicação em Servidor" }
        );
        return false;
      }
      if (this.app.server === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Endereço da API em Servidor" }
        );
        return false;
      }
      /** Send information validation **/
      if (this.app.send_status_interval == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Intervalo de Envio" }
        );
        return false;
      }
      if (this.app.send_data_interval.trim == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Intervalo de Envio" }
        );
        return false;
      }
      if (this.app.send_battery_interval == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Intervalo de Envio" }
        );
        return false;
      }
      if (this.app.send_health_interval == "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Sinais Vitais" }
        );
        return false;
      }
      // Check for transmission. If it's mqtt, check if all fields are filled
      if (this.transmission == "mqtt") {
        if (this.mqtt.host === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Host em MQTT" }
          );
          return false;
        }
        if (this.mqtt.port === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Porta em MQTT" }
          );
          return false;
        }
        if (this.mqtt.username === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Usuário em MQTT" }
          );
          return false;
        }
        if (this.mqtt.password === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Senha em MQTT" }
          );
          return false;
        }
        if (this.mqtt.keepalive === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Keepalive em MQTT" }
          );
          return false;
        }
        if (this.mqtt.qos === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "QoS em MQTT" }
          );
          return false;
        }
        if (this.mqtt.status_topic === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Tópico de Status em MQTT" }
          );
          return false;
        }
        if (this.mqtt.battery_topic === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Tópico de Bateria em MQTT" }
          );
          return false;
        }
        if (this.mqtt.data_topic === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Tópico de Dados em MQTT" }
          );
          return false;
        }
        if (this.mqtt.health_topic === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Sinais Vitais em MQTT" }
          );
          return false;
        }
      } else {
        if (this.http.url === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "URL em HTTP" }
          );
          return false;
        }
        if (this.http.token === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Token em HTTP" }
          );
          return false;
        }
        if (this.http.entries === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Entradas em HTTP" }
          );
          return false;
        }
        if (this.http.status_route === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Rota de Status em HTTP" }
          );
          return false;
        }
        if (this.http.battery_route === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Rota de Bateria em HTTP" }
          );
          return false;
        }
        if (this.http.data_route === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Rota de Dados em HTTP" }
          );
          return false;
        }
        if (this.http.health_route === "") {
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
                        { field: "Rota de Sinais Vitais em HTTP" }
          );
          return false;
        }
      }
      return true;
    },
    updateGateway() {
      event.preventDefault();
      this.antena = this.selectedStatus;
      this.autoprovision = this.selectedAutoprovision;

      if (!this.isGatewayValid(this.gatewayconfig)) {
        return;
      }

      let mac_address = this.mac_address.replace(/:|-/g, "");

      this.ble.inactivity_timeout = this.use_timeout ? this.ble.inactivity_timeout : -1;
      if (this.DEFAULT) {
        this.$store.dispatch("gateway/saveConfigGateway", {
          mac_address: mac_address,
          gatewayconfig: this.gatewayconfig,
          app: this.app,
          ble: {
            inactivity_timeout: this.ble.inactivity_timeout,
            rssi_limit: this.ble.rssi_limit,
            rssi_limit_usb1: this.ble.rssi_limit_usb1,
            rssi_limit_usb2: this.ble.rssi_limit_usb2,
            rssi_limit_usb3: this.ble.rssi_limit_usb3,
            rssi_limit_usb4: this.ble.rssi_limit_usb4,
          },
          transmission: this.transmission,
          antena: this.selectedStatus,
          autoprovision: this.autoprovision,
          send_health_interval: this.send_health_interval,
          http: this.http,
          mqtt: this.mqtt,
        });
      } else {
        this.$store.dispatch("gateway/updateConfigGateway", {
          gateway: this.gatewayconfig,
          mac_address: mac_address,
          transmission: this.transmission,
          app: this.app,
          ble: this.ble,
          http: this.http,
          mqtt: this.mqtt,
          antena: this.selectedStatus,
          autoprovision: this.autoprovision,
        });
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    servidorSelected() {
      return this.tabSelected === "servidor";
    },
    transmissãoSelected() {
      return this.tabSelected === "transmissão";
    },
    addGatewaySelected() {
      return this.tabSelected === "addGateway";
    },
    infoTabSelected() {
      return this.tabSelected === "infoTabSelected";
    },
    load() {
      return this.$store.getters["load"];
    },
    models() {
      return this.$store.getters["gateway/models"];
    },
    config() {
      return this.$store.getters["gateway/config"];
    },
    gatewayconfig() {
      return this.$store.getters["gateway/gatewayconfig"];
    },
    types() {
      return this.$store.getters["gateway/types"];
    },
    gateways() {
      return this.$store.getters["gateway/gateways"];
    },
    gatewayStatus() {
      return this.$store.getters["gateway/gatewayStatus"];
    },
  },
};
</script>
<style>
.margin-left-zero {
  margin-left: 0;
}
.eyesight {
  margin-left: 5px;
  position: absolute;
  right: 36px;
  top: 42px;
  cursor: pointer;
}
.same-size-ps {
  width: 150px;
  text-align: left;
  background-color: #e9ecef;
}
</style>
