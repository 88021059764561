<template>
    <div data-dismiss="modal" aria-label="Close">
      <!-- Modal -->
      <div
        class="modal fade"
        id="modalMonitoringAlerts"
        tabindex="-1"
        aria-labelledby="id"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-width"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalTitleLabel">
                Alertas de monitoramento
              </h5>
              <button
                id="ast-config-close-modal"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" id="ast-config-close-modal-text"
                  >&times;</span
                >
              </button>
            </div>
  
              <div class="modal-body modal-height">
                <div class="col-12 col-sm-12 p-0 col-md-12">
                  <div class="inside-card">
                    <div class="row">
                      <TwInput label="Busca" col_lg="12">
                        <b-input-group style="display: flex" >
                          <b-input-group-prepend is-text
                            ><i class="fas fa-search"></i>
                          </b-input-group-prepend>
                          <input
                            v-model="filter"
                            type="search"
                            class="form-control"
                            id="filterSeachMonitoringAlerts"
                            placeholder="Buscar"
                          />
                        </b-input-group>
                      </TwInput>
                    </div>
                    <div>
                      <b-table
                        striped
                        hover
                        :filter="filter"
                        sticky-header="500px"
                        :items="itemsAlertsMonitorings"
                        :fields="alertsMonitoringFields"
                        ref="alertsMonitoringTable"
                        :per-page="perPage"
                        :current-page="currentPage"
                        @filtered="onFiltered"
                      >
                        <template v-slot:table-colgroup>
                          <col
                            v-for="field in alertsMonitoringFields"
                            :key="field.key"
                            :style="{
                              width: field.key === 'actions' ? '90px' :
                                    field.key !== 'frequency' ? '220px' :
                                    '550px',
                            }"
                          />
                        </template>
                        <template
                          v-slot:cell(schedule)="data"
                        >
                          <div v-if="data.item.schedule" id="monitoringAlertsSchedule">
                            {{data.item.schedule}}
                          </div>
                        </template>
                        <template
                          v-slot:cell(expected)="data"
                        >
                          <div v-if="data.item.expected" id="monitoringAlertsExpected">
                            {{data.item.expected}}
                          </div>
                        </template>
                        <template v-slot:cell(detected)="data">
                          <div v-if="data.item.detected" id="monitoringAlertsDetected">
                            {{data.item.detected}}
                          </div>
                        </template>
                        <template v-slot:cell(geofence)="data">
                          <div v-if="data.item.geofence" id="monitoringAlertsGeofence">
                            {{data.item.geofence}}
                          </div>
                        </template>
                        <template v-slot:cell(datetime)="data">
                          <div v-if="data.item.datetime" id="monitoringAlertsDatetime">
                            {{data.item.datetime}}
                          </div>
                        </template>
                        <template #cell(note)="data">
                            <b-button
                              data-toggle="modal"
                              :id="`justificationButton${data.index}`"
                              :ref="`justificationButton${data.index}`"
                              :class="data.item.errors?.note ? 'error-input button-errorg' : ''"
                              :title="`${data.detailsShowing ? 'Esconder' : 'Mostrar'} motivo do alerta`"
                              :disabled="hasRole('USER') && !data.item.note"
                              :variant="`${(data.item?.note) ? 'primary' : ''}`"
                              @click="data.toggleDetails"
                            >
                              <i v-if="data.detailsShowing" class="fa-solid fa-chevron-up"></i>
                              <i v-else class="fa-solid fa-chevron-down"></i>
                            </b-button>
                        </template>
                        <template #row-details="data">
                          <div v-if="!hasRole('USER')"> 
                            <input
                              class="form-control mr-1"
                              type="text"
                              placeholder="Justificativa do Alerta"
                              v-model="data.item.note"
                              :class="data.item.errors?.note ? 'error-input' : ''"
                            >
                            <div v-if="data.item.errors?.note" class="error-message">
                              {{ data.item.errors?.note }}
                            </div>
                          </div>
                          <div v-else>
                            {{ data.item.note }}
                          </div>
                        </template>
                      </b-table>
                      <div class="row">
                        <div v-if="rows" class="col-12 col-md-6 mb-2 pt-1">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="pagination"
                          ></b-pagination>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="btn-group" role="group">
                            <b-dropdown
                              variant="primary"
                              v-model="perPage"
                              :text="`Linhas: ${perPage}`"
                            >
                              <b-dropdown-item
                                href="#"
                                @click="
                                  () => {
                                    this.perPage = 10;
                                  }
                                "
                                >10</b-dropdown-item
                              >
                              <b-dropdown-item
                                href="#"
                                @click="
                                  () => {
                                    this.perPage = 20;
                                  }
                                "
                                >20</b-dropdown-item
                              >
                              <b-dropdown-item
                                href="#"
                                @click="
                                  () => {
                                    this.perPage = 50;
                                  }
                                "
                                >50</b-dropdown-item
                              >
                              <b-dropdown-item
                                href="#"
                                @click="
                                  () => {
                                    this.perPage = '1000';
                                  }
                                "
                                >1000</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; justify-content: flex-end;" v-cant="['USER']">
                      <b-button
                        variant="primary"
                        @click="saveMonitoringAlerts()"
                        :data-dismiss="this.isAllValid ? 'modal' : ''"
                        aria-label="Close"
                        id="saveMonitoringAlertsReasonButton"
                      >
                        Salvar
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
</template>
    
<script>
import TwInput from "@/components/TwInput.vue";
import { formatDate, formatDateAPI } from "../../utils/format-fields";
import Modal from "../../components/Modal.vue";
import hasRole from "../../utils/hasRole";

export default {
    name: "MonitoringAlertsReason",
    props: {
    customer: {
        type: Object,
        required: false,
    },
    },
    components: {
    TwInput,
    Modal,
    },
    data: function () {
    return {
        filter: null,
        perPage: 10,
        currentPage: 1,
        rows: 1,
        filteredTraining: [],
        sortByMonitorings: "id",
        alertsMonitoringFields: [
        { label: "Agendamento", key: "schedule", sortable: true },
        { label: "Colaboradores esperados", key: "expected", sortable: true },
        { label: "Colaboradores detectados", key: "detected", sortable: true },
        { label: "Sala da Ocorrência", key: "geofence", sortable: true },
        { label: "Hora da Ocorrência", key: "datetime", sortable: true },
        { label: "Motivo", key: "note", sortable: false},
        ],
        itemsAlertsMonitorings: [],
        isAllValid: true,
    };
    },
    watch: {
    customer() {
        if (this.customer) {
            this.$store.dispatch("monitoring/getScheduleAlerts", {
            customer_id: this.customer.id,
            });
        }
    },
    customerAlertsMonitorings(){
        //this.itemsAlertsMonitorings.length = 0;
        this.itemsAlertsMonitorings = [];
        this.customerAlertsMonitorings.map((alert) => {
        this.itemsAlertsMonitorings = [...this.itemsAlertsMonitorings, {...alert}]
        })
        this.customerAlertsMonitorings?.map((alert) => {
        alert.errors = {
            note: "",
        }
        })
        this.$refs.alertsMonitoringTable.refresh()

        if (this.$options.name) {
            const ctx = JSON.parse(
              localStorage.getItem(`bstable-state-${this.$options.name}`)
            );
            if (!ctx){
              this.perPage = 10;
              this.currentPage = 1;
              this.sortBy = "";
              this.rows = this.customerAlertsMonitorings.length;
              return;
            }
            if (ctx.perPage) {
              this.perPage = ctx.perPage;
            }
            if (ctx.sortBy !== "") {
              this.sortBy = ctx.sortBy;
            }
          }

          this.rows = this.customerAlertsMonitorings.length;
    },
    },
    methods: {
      saveMonitoringAlerts(){
          let sendAlerts = [];
          this.isAllValid = true;
          this.itemsAlertsMonitorings.forEach((alert) => {
              alert.note = alert.note?.trim();
              if(!this.isAlertValid(alert)) {
              this.isAllValid = false; 
              }
              else if (alert.note && alert.note != ""){
              let unit = { 'id': alert.id, 'note': alert.note};
              sendAlerts = [...sendAlerts, {...unit}]
              }
          })
          if (sendAlerts.length > 0 && this.isAllValid){             
          this.$store.dispatch("monitoring/setScheduleAlertsReason", {
              customer_id: this.customer.id,
              alerts_list: { 'logs': sendAlerts}
          }).then(() => {
              this.toggleMonitoring()
          })
          }
      },
      onFiltered(alerts) {
        this.rows = alerts.length;
      },
      isAlertValid(alert){
          let valid = true;
          alert.errors = {
          note: "",
          }
          if(alert.note?.length > 256){
          alert.errors.note = "Motivo não pode ter mais do que 256 caracteres."
          valid = false;
          }
          return valid;
      },
      toggleMonitoring(){
          this.$emit('clearMonitoringTarget', true)
      },
      formatDateAPI,
      hasRole
      },
      computed: {
      customerAlertsMonitorings(){
          return this.$store.getters["monitoring/monitoringsAlerts"];
      },
    }
};
</script>
<style>
    .modal-width{
    max-width: 1000px !important;
    
    }
    
    .modal-height{
    max-height: 800px !important;
    overflow-y: auto;
    }

    .inside-card{
    padding: 15px;
    }
    .error-message{
    font-size: 10px;
    color: red;
    padding-left: 3px;
    }
    .error-input{
    border: solid 2px red;
    }
    .error-input.button-error{
    color: red;
    }
</style>
