import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";
import router from "../router";

export default {
  namespaced: true,
  state: {
    tags: [],
    models: [],
    tagTypes: [],
    bulkLoad: false,
    bulkErrors: [],
  },

  mutations: {
    SET_BULK_LOAD(state, value) {
      state.bulkLoad = value;
    },
    SET_BULK_ERRORS(state, value) {
      state.bulkErrors = value;
    },
    PUSH_BULK_ERRORS(state, value) {
      state.bulkErrors.push(value);
    },
    SAVE_TAG(state, data) {
      state.tags.push(data);
    },
    SAVE_LIST(state, data) {
      state.tags.push(data);
    },
    DEL_TAG(state, id) {
      state.tags = state.tags.filter((tag) => {
        return tag.id !== id;
      });
    },
    UPDATE_TAG(state, payload) {
      const index = state.tags.findIndex((tag) => {
        return payload.id === tag.id;
      });
      if (index > -1) {
        state.tags[index] = payload;
      } else {
        state.tags.push(payload);
      }
    },
    async DELETE_TAG(state, { data }) {
      await api
        .delete(`tags/mac_address/${data.mac_address}`)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_DELETE', 'Tag', "DELETE_TAG");
          state.tags = state.tags.filter(
            (user) => user.mac_address !== data.mac_address
          );
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Tag', "DELETE_TAG");
        });
    },
    async SET_TAG(state, payload) {
      state.tags = payload;
    },
    async GET_MODELS(state) {
      await api.get("tags/models").then((response) => {
        state.models = response.data;
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, 'Tag', 'GET_MODELS');
      });
    },
    async GET_TAG_TYPES(state) {
      await api.get("tags/types").then((response) => {
        state.tagTypes = response.data;
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, 'Tag', 'GET_TAG_TYPES');
      });
    },
  },
  getters: {
    tags: (state) => state.tags,
    models: (state) => state.models,
    tagTypes: (state) => state.tagTypes,
    bulkLoad: (state) => state.bulkLoad,
    bulkErrors: (state) => state.bulkErrors,
  },
  actions: {
    deleteTag(context, data) {
      context.commit("DELETE_TAG", { data });
    },
    getModels(context, data) {
      context.commit("GET_MODELS", { data });
    },
    getTagTypes(context, data) {
      context.commit("GET_TAG_TYPES", { data });
    },
    async saveTag(context, payload) {
      try {
        const res = await api.post(`tags`, payload);
        let { data } = res;
        context.commit("SAVE_TAG", data);
        displayErrMsg('GLOBAL_SUCCESS_ADD', 'Tag', 'SAVE_TAG');
        router.push({ path: "/tag" });
      } catch (err) {
        displayErrMsg(err.response.data.code, 'Tag', 'SAVE_TAG');
      }
    },
    async saveList(context, payload) {
      try {
        const res = await api.post(`tags/list`, payload);
        let { data } = res;
        context.commit("SAVE_LIST", data);
        displayErrMsg('GLOBAL_SUCCESS_ADD', 'Tag', 'SAVE_LIST');
        router.push({ path: "/tag" });
        await new Promise((resolve) => setTimeout(resolve, 500));
        window.location.reload();
      } catch (err) {
        displayErrMsg(err.response.data.code, 'Tag', 'SAVE_LIST');
      }
    },
    async updateTag(context, data) {
      const sendTag = {
        id: data.id,
        mac_address: data.mac_address,
        meas_pwr: data.meas_pwr,
        ncap_cut: data.ncap_cut,
        rssi_cut: data.rssi_cut,
        tag_label: data.tag_label,
        tag_model: data.tag_model,
        latitude: data.latitude,
        longitude: data.longitude,
        altitude: data.altitude,
        tag_msg: {
          text: data.tag_msg.text,
          voice: data.tag_msg.voice,
        },
        tag_state: {
          administrative_state: data.tag_state.administrative_state,
          battery_level: data.tag_state.battery_level,
          operational_state: data.tag_state.operational_state,
        },
        tag_type: data.tag_type,
      };

      try{
        const res = await api.put(`tags`, sendTag);

        if (res.status === 200 && res.data.id) {
          // TODO: check if there's a better way of doing this
          const res = await api.get("tags?sortBy=tag_label");
          context.commit("SET_TAG", res.data);
          displayErrMsg('GLOBAL_SUCCESS_MODIFY', 'Tag', 'UPDATE_TAG');
          localStorage.setItem("tagedit", JSON.stringify(data));
          router.push({ path: "/tag" });
        }
      } catch (err) {
        // displayErrMsg("GLOBAL_FAILED_MODIFY", 'Tag', 'UPDATE_TAG');
        displayErrMsg(err.response.data.code, 'Tag', 'UPDATE_TAG');
      }
    },
    async get(context) {
      try {
        const res = await api.get("tags?sortBy=tag_label");
        context.commit("SET_TAG", res.data);
      } catch (err) {
        displayErrMsg(err.response.data.code, 'Tag', 'GET_TAGS');
      }
    },
    async bulkSaveTags(context, data) {
      let tags = [];
      await data.forEach(async (tag) => {
        const sendTag = {
          id: tag.id,
          mac_address: tag.mac_address,
          meas_pwr: tag.meas_pwr,
          ncap_cut: tag.ncap_cut,
          rssi_cut: tag.rssi_cut,
          tag_label: tag.tag_label,
          tag_model: tag.tag_model,
          tag_state: {
            administrative_state: tag.tag_state.administrative_state,
            battery_level: tag.tag_state.battery_level,
            operational_state: tag.tag_state.operational_state,
          },
          tag_type: tag.tag_type,
        };
        tags.push(sendTag);
      });

      try {
        await api.put(`tags/list`, { tags: tags });
        const res = await api.get("tags?sortBy=tag_label");
        context.commit("SET_TAG", res.data);
        displayErrMsg('GLOBAL_SUCCESS_MODIFY', 'Tag', 'BULK_SAVE_TAGS');
      } catch (err) {
        displayErrMsg(err.response.data.code, 'Tag', 'BULK_SAVE_TAGS');
      }
    },
    async bulkDeleteTags(context, data) {
      let query = "";
      data.forEach((tag) => {
        query += `id=${tag.id}&`;
      });
      try {
        const resp = await api.delete(`tags/list?${query}`);
        if (resp.status === 204) {
          data.forEach((tag) => {
            context.commit("DEL_TAG", tag.id);
          });
          displayErrMsg('GLOBAL_SUCCESS_DELETE', 'Tag', 'BULK_DELETE_TAGS');
        }
      } catch (err) {
        displayErrMsg(err.response.data.code, 'Tag', 'BULK_DELETE_TAGS');
      }
    },
  },
};
