import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";
import router from "../router";

export default {
    namespaced: true,
    state: {
        selectedTraining: null,
        trainings:[],
        categories:[],
        alertTrainings: [],
    },
    getters: {
        selectedTraining: (state) => state.selectedTraining,
        trainings: (state) => state.trainings,
        categories: (state) => state.categories,
        alertTrainings: (state) => state.alertTrainings,
    },
    mutations: {
        async SAVE_TRAINING(state,  data ) {
          state.trainings.push(data);
        },
        async GET_CUSTOMER_TRAINING_ALERTS(state,  data ) {
          state.alertTrainings = data;
        }, 
        async GET_CUSTOMER_TRAINING(state,  data ) {
          state.selectedTraining = data;
        },     
        async GET_CUSTOMER_ALL_TRAININGS(state,  data ) {
          state.trainings = data;
        },
        async UPDATE_CUSTOMER_TRAINING(state, { data }) {
          router.push({
            name: "customer-edit",
            params: router.currentRoute.params,
          });
        },
        async DELETE_CUSTOMER_CATEGORIES(state,  data ) {
          state.categories = state.categories.filter((elem) => elem.name !== data);
        },
        async GET_CUSTOMER_CATEGORIES(state,  data ) {
          state.categories = data;
        },
        async SAVE_CATEGORIES(state,  data ) {
          state.categories.push(data);

        },
        async DELETE_CUSTOMER_TRAININGS(state,  deletedId ) {
          state.trainings = state.trainings.filter((elem) => elem.id !== deletedId);
        },
        async SAVE_TRAINING_SCHEDULES(state, data){
          await api.patch(`customers/${data.customer_id}/trainings/schedules/assign`, {
            schedules: data.schedules
          })
          .then((res) => {
            let training_id = data.schedules[0].training_id // Getting only the first training id from list, because the rest are the same.

            let stateTraining = state.trainings.filter((training) => training.id === training_id)
                     
            data.schedules.map((schedule) => {
              stateTraining[0].schedules.push(schedule)
            })

            displayErrMsg("GLOBAL_SUCCESS_ADD", "Agendamento", "SAVE_TRAINING_SCHEDULES")
          })
          .catch((err) => {
            displayErrMsg(err.response.data.code, "Agendamento", "SAVE_TRAINING_SCHEDULES")
          })
        },
        async DELETE_TRAINING_SCHEDULES(state, data){
          await api.patch(`customers/${data.customer_id}/trainings/schedules/unassign`, {
            schedules: data.schedules
          })
          .then((res) => {
            let training_id = data.schedules[0].training_id
            let stateTraining = state.trainings.filter((training) => training.id === training_id)

            displayErrMsg("GLOBAL_SUCCESS_DELETE", "Agendamento", "DELETE_TRAINING_SCHEDULES")

            data.schedules.forEach((deleteSchedule) => {              
              stateTraining[0].schedules = stateTraining[0].schedules.filter((stateSchedule) => 
                !(stateSchedule.t_start === deleteSchedule.t_start && stateSchedule.t_end === deleteSchedule.t_end)
              )
            })
          })
          .catch((err) => {
            displayErrMsg(err.response.data.code, "Agendamento", "SAVE_TRAINING_SCHEDULES")
          })
        },
        async SAVE_LIST_TRAININGS(state, data){
          let trainings_data = {
            trainings: []
          };

          let schedules_data = {
            schedules: []
          }

          data.trainings.forEach((training) => {
            trainings_data.trainings.push({
              area: training.area,
              category: training.category,
              description: training.description,
              duration: training.duration,
              expires_at: training.expires_at,
              min_grade: training.min_grade,
              min_attendence: training.min_attendence,
              name: training.name,
            })
          })

          await api
            .post("customers/" +data.customer_id+ "/trainings",trainings_data)
            .then(async (res) => {
              let sendedData = res.data

              for (let i in data.trainings){
                data.trainings[i].schedules.forEach((schedule) => {
                  schedules_data.schedules.push({
                    instructor: schedule.instructor,
                    t_start: schedule.t_start,
                    t_end: schedule.t_end,
                    training_id: sendedData[i].id
                  })
                })

                state.trainings.push({
                  name: data.trainings[i].name,
                  area: data.trainings[i].area,
                  category: data.trainings[i].category,
                  description: data.trainings[i].description,
                  duration: data.trainings[i].duration,
                  id: sendedData[i].id,
                  schedules: [],
                })
              }
              schedules_data.customer_id = data.customer_id;

              displayErrMsg('GLOBAL_SUCCESS_ADD', "Treinamentos", "SAVE_TRAINING");
              
              await api.patch(`customers/${data.customer_id}/trainings/schedules/assign`, {
                schedules: schedules_data.schedules
              })
              .then((res) => {
                schedules_data.schedules.forEach((schedule) => {
                  let stateTraining = state.trainings.filter((training) => training.id === schedule.training_id)

                  stateTraining[0].schedules.push({...schedule})
                })
              })
              .catch((err) => {
                displayErrMsg(err.response.data.code, "Agendamento", "SAVE_TRAINING_SCHEDULES")
              })
            }).catch((err) => {
              displayErrMsg(err.response.data.code, "Treinamentos", "SAVE_TRAINING");
            })
        }
    },
    actions: {
        async saveTraining(context, data) {
          let trainings_data = {
            trainings: data.trainings
          };

          await api
            .post("customers/" +data.customer_id+ "/trainings",trainings_data)
            .then((res) => {
              context.commit("SAVE_TRAINING", res.data);
              displayErrMsg('GLOBAL_SUCCESS_ADD', "Treinamento", "SAVE_TRAINING");

              router.push({
                name: "customer-edit",
                params: router.currentRoute.params,
              });
            }).catch((err) => {
              displayErrMsg(err.response.data.code, "Treinamento", "SAVE_TRAINING");
            })
        },
        async saveListTrainings(context, data) {
          context.commit("SAVE_LIST_TRAININGS", data);
        },
        async getCustomerTraining(context, data){
          await api
            .get(`customers/trainings/${data.trainingId}`)
            .then((res) => {
              context.commit("GET_CUSTOMER_TRAINING", res.data);
            })
            .catch((err) => {
              displayErrMsg(err.response.data.code, "Treinamento", "GET_CUSTOMER_TRAINING");
              router.push({
                name: "customer-edit",
                params: router.currentRoute.params,
              });
            })
        },
        async getCustomerTrainingAlerts(context, data){
          await api
            .get(`customers/${data.customer_id}/trainings/logs`)
            .then((res) => {
              context.commit("GET_CUSTOMER_TRAINING_ALERTS", res.data);
            })
            .catch((err) => {
              displayErrMsg(err.response.data.code, "Treinamento", "GET_CUSTOMER_TRAINING_ALERTS");
              router.push({
                name: "customer-edit",
                params: router.currentRoute.params,
              });
            })
        },
        async updateCustomerTrainingAlerts(context, data){
          await api
            .put(`customers/${data.customer_id}/trainings/logs`, data.alerts_list)
            .then(() => {
              displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Treinamento", "UPDATE_CUSTOMER_TRAINING");
            })
            .catch((err) => {
              displayErrMsg(err.response.data.code, "Treinamento", "UPDATE_CUSTOMER_TRAINING");
            })
        },
        async getCustomerAllTrainings(context, data) {
          let queryParam = data.training_id ? `?id=${data.training_id}` : ""

          await api
            .get(`customers/${data.customer_id}/trainings${queryParam}`)
            .then((res) => {
              if(queryParam){
                context.commit("GET_CUSTOMER_TRAINING", res.data);
              }
              else {
                context.commit("GET_CUSTOMER_ALL_TRAININGS", res.data);
              }
              
            })
            .catch((err) => {
              displayErrMsg(err.response.data.code, 'Treinamentos', "GET_CUSTOMER_TRAININGS");
            });
        },

        async deleteCustomerCategories(context, data) {
          await api
          .delete( `customers/${data.customer_id}/trainings/categories?name=${encodeURIComponent(data.name)}`)
          .then(() => {
            displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Categoria', "DELETE_CUSTOMER_CATEGORIES");
            context.commit("DELETE_CUSTOMER_CATEGORIES", data.name);
          })
          .catch((err) => {
            displayErrMsg(err.response.data.code, 'Categoria', "DELETE_CUSTOMER_CATEGORIES");
          });
        },

        async getCustomerCategories(context, data) {
          await api
            .get("customers/" + data.customer_id + "/trainings/categories")
            .then((res) => {
              context.commit("GET_CUSTOMER_CATEGORIES", res.data);
            })
            .catch((err) => {
              displayErrMsg(err.response.data.code, 'Categorias', "GET_CUSTOMER_CATEGORIES");
            });
        },
        async saveCategories(context, data) {
          await api
            .post("customers/" +data.customer_id+ "/trainings/categories",data.name[0])
            .then((res) => {
              context.commit("SAVE_CATEGORIES", res.data);
              displayErrMsg("GLOBAL_SUCCESS_ADD", "Categoria", "SAVE_CATEGORY");
            }).catch((err) => {
              displayErrMsg(err.response.data.code, "Categoria", "SAVE_CATEGORY");
            })
        },
        async deleteCustomerTrainings(context, data) {
          await api
          .delete( `/customers/${data.customer_id}/trainings?id=${data.id}`)
          .then(() => {
            displayErrMsg("GLOBAL_SUCCESS_DELETE", "Treinamento", "DELETE_CUSTOMER_TRAINING");
            context.commit("DELETE_CUSTOMER_TRAININGS", data.id);
          })
          .catch((err) => {
            if(err.response.data.code === 409 && err.response.data.message.includes("employee-training-association-exists")){
              displayErrMsg(err.response.data.code, "Treinamento", "DELETE_CUSTOMER_TRAINING", { reason: "esta associado a um colaborador"});
            } else if (err.response.data.code === 409 && err.response.data.message.includes("geofence-training-association-exists")) {
              displayErrMsg(err.response.data.code, "Treinamento", "DELETE_CUSTOMER_TRAINING", { reason: "esta associado a uma geofence"});
            } else {
              displayErrMsg(err.response.data.code, "Treinamento", "DELETE_CUSTOMER_TRAINING");
            }
          });
        },
        async updateCustomerTraining(context, data){
          await api
            .put(`customers/${data.customer_id}/trainings`, data.training)
            .then(() => {
              displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Treinamento", "UPDATE_CUSTOMER_TRAINING");
              context.commit("UPDATE_CUSTOMER_TRAINING", data.training)
            })
            .catch((err) => {
              displayErrMsg(err.response.data.code, "Treinamento", "UPDATE_CUSTOMER_TRAINING");
            })
        },
        async saveTrainingSchedules(context, data){
          data.schedules.forEach((schedule) => {
            delete schedule.action;
            delete schedule.errors;
          })

          context.commit("SAVE_TRAINING_SCHEDULES", data);
        },
        async deleteTrainingSchedules(context, data){
          context.commit("DELETE_TRAINING_SCHEDULES", data);
        }
     },
  };