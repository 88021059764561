<template>
  <div data-dismiss="modal" aria-label="Close">
    <!-- Modal -->
    <div
      class="modal fade"
      id="import-config-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="id"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
        style="max-width: 1200px !important"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Informações do Colaborador
            </h5>
            <button
              id="gtw-config-close-modal"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="col-12 col-sm-12 p-0 col-md-12">
              <div class="card-body">
                <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
                  <div class="row mb-2">
                    <div class="col-12">
                      <div
                        class="card direct-chat direct-chat-primary employee"
                      >
                        <div class="card-body custom-card overflow-visible">
                          <ul class="nav nav-tabs bg-main">
                            <li class="nav-item">
                              <a
                                id="functionalTab-tab"
                                class="nav-link first"
                                v-bind:class="{
                                  active: functionalTabSelected,
                                }"
                                v-on:click="changeTab('functionalTab')"
                                href="#"
                                aria-current="functionalTab"
                                tabindex="-1"
                                >Dados funcionais</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                id="anamnesisTab-tab"
                                class="nav-link"
                                v-bind:class="{
                                  active: anamnesisTabSelected,
                                }"
                                v-on:click="changeTab('anamnesisTab')"
                                href="#"
                                aria-current="anamnesisTab"
                                tabindex="-1"
                                >Anamnese</a
                              >
                            </li>
                          </ul>
                          <div class="p-4 bg-white rounded">
                            <div class="tab-content">
                              <div
                                class="tab-pane fade"
                                v-bind:class="{
                                  show: functionalTabSelected,
                                  active: functionalTabSelected,
                                }"
                              >
                                <div class="row">
                                  <div class="card-body">
                                    <div class="p-4 bg-white rounded">
                                      <div class="col-12">
                                        <div class="row">
                                          <!-- New code!!! -->
                                          <TwInput col_lg="4" label="Área">
                                            <select
                                              v-model="
                                                employee.functional_data.area
                                              "
                                              class="form-control"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option
                                                v-for="(
                                                  area, index
                                                ) in area_options"
                                                :key="index"
                                                :value="area.toUpperCase()"
                                              >
                                                {{ area }}
                                              </option>
                                              <option value="other">
                                                Outra
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput col_lg="4" label="Setor">
                                            <select
                                              v-model="
                                                employee.functional_data
                                                  .department
                                              "
                                              class="form-control"
                                              id="departmentSelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option
                                                v-for="(
                                                  department, index
                                                ) in department_options"
                                                :key="index"
                                                :value="
                                                  department.toUpperCase()
                                                "
                                              >
                                                {{ department }}
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput col_lg="4" label="Cargo">
                                            <select
                                              v-model="
                                                employee.functional_data
                                                  .position
                                              "
                                              class="form-control"
                                              id="positionSelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option
                                                v-for="(
                                                  position, index
                                                ) in position_options"
                                                :key="index"
                                                :value="position.toUpperCase()"
                                              >
                                                {{ position }}
                                              </option>
                                            </select>
                                          </TwInput>
                                        </div>
                                        <div class="row">
                                          <TwInput col_lg="4" label="Turno">
                                            <select
                                              v-model="
                                                employee.functional_data
                                                  .work_shift
                                              "
                                              class="form-control"
                                              id="workshiftSelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option
                                                v-for="(
                                                  work_shift, index
                                                ) in work_shift_options"
                                                :key="index"
                                                :value="
                                                  work_shift.toLowerCase()
                                                "
                                              >
                                                {{ work_shift }}
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Jornada (h)"
                                          >
                                            <input
                                              id="emp-work-journey-in"
                                              v-model="
                                                employee.functional_data
                                                  .work_week
                                              "
                                              type="number"
                                              class="form-control"
                                              placeholder="44"
                                              :max="168"
                                            />
                                          </TwInput>
                                          <TwInput col_lg="4" label="Gestor">
                                            <input
                                              id="emp-supervisor-in"
                                              v-model="employeeSupervisor"
                                              type="text"
                                              autocomplete="off"
                                              class="form-control filter-input"
                                              placeholder="Gestor"
                                            />
                                            <div
                                              v-if="filteredSupervisors.length"
                                              class="autocomplete"
                                            >
                                              <div
                                                class="sup"
                                                @click="
                                                  onSelectSupervisor('none')
                                                "
                                              >
                                                <b>LIMPAR SELEÇÃO</b>
                                              </div>
                                              <div
                                                v-for="sup in filteredSupervisors"
                                                class="sup"
                                                :key="sup.id"
                                                @click="onSelectSupervisor(sup)"
                                              >
                                                <b>{{ sup.name }}</b>
                                              </div>
                                            </div>
                                          </TwInput>
                                        </div>
                                        <div class="row">
                                          <TwInput
                                            col_lg="4"
                                            label="Plano de saúde"
                                          >
                                            <select
                                              v-model="
                                                employee.functional_data
                                                  .health_plan
                                              "
                                              class="form-control"
                                              id="healthplanSelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="S">Sim</option>
                                              <option value="N">Não</option>
                                            </select>
                                          </TwInput>

                                          <TwInput col_lg="4" label="Sexo">
                                            <select
                                              v-model="employee.gender"
                                              class="form-control"
                                              id="genderSelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="FEMININO">
                                                Feminino
                                              </option>
                                              <option value="MASCULINO">
                                                Masculino
                                              </option>
                                              <option value="NAO-IDENTIFICADO">
                                                Não informado
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Data de nascimento"
                                          >
                                            <input
                                              id="emp-birthdate-in"
                                              class="form-control"
                                              type="date"
                                              :min="'1900-01-01'"
                                              :max="
                                                new Date().toISOString().split('T').shift()
                                              "
                                              v-model="employee.date_of_birth"
                                            />
                                          </TwInput> 
                                        </div>
                                        <div class="row">
                                          <TwInput col_lg="4" label="Data de admissão">
                                            <input
                                              id="add-employee-hire-date"
                                              class="form-control"
                                              type="date"
                                              :min="'1900-01-01'"
                                              :max="
                                                new Date().toISOString().split('T')[0]
                                              "
                                              v-model="employee.functional_data.date_of_hire"
                                            />
                                          </TwInput>
                                          <TwInput col_lg="4" label="Estado">
                                            <select
                                              disabled
                                              v-model="employee.functional_data.state"
                                              class="form-control"
                                              id="stateSelect"
                                              @change="changeState(employee.functional_data.state)"
                                            >
                                            <option value="HIRED">Ativo</option>
                                            </select>
                                          </TwInput>
                                          <TwInput col_lg="4" label="Data de desligamento">
                                            <input
                                              id="add-employee-dismissal-date"
                                              class="form-control"
                                              type="date"
                                              :min="employee.functional_data.date_of_hire"
                                              :max="new Date().toISOString().split('T')[0]"
                                              v-model="employee.functional_data.date_of_dismissal"
                                              :disabled="employee.functional_data.state !== 'FIRED'"
                                            />
                                          </TwInput>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                v-bind:class="{
                                  show: anamnesisTabSelected,
                                  active: anamnesisTabSelected,
                                }"
                              >
                                <div class="row">
                                  <div class="card-body">
                                    <div class="p-4 bg-white rounded">
                                      <div class="col-12">
                                        <div class="row">
                                          <TwInput col_lg="4" label="Peso (kg)">
                                            <input
                                              v-model="
                                                employee.anamnesis_data.weight
                                              "
                                              type="number"
                                              class="form-control"
                                              placeholder="Peso"
                                              id="employeeWeight"
                                              :max="1000"
                                            />
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Altura (m)"
                                          >
                                            <input
                                              v-model="
                                                employee.anamnesis_data.height
                                              "
                                              type="number"
                                              class="form-control"
                                              placeholder="Altura"
                                              id="employeeHeight"
                                              :step="0.01"
                                              :max="3"
                                            />
                                          </TwInput>
                                          <TwInput col_lg="4" label="Fumante">
                                            <select
                                              v-model="
                                                employee.anamnesis_data.smoking
                                              "
                                              class="form-control"
                                              id="smokerForm"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="S">Sim</option>
                                              <option value="N">Não</option>
                                            </select>
                                          </TwInput>
                                        </div>
                                        <div class="row">
                                          <TwInput
                                            col_lg="4"
                                            label="Doenças Pré-existentes"
                                          >
                                            <div class="input-group">
                                              <input
                                                id="diseases-list-in"
                                                type="text"
                                                class="form-control"
                                                v-model="diseasesList"
                                              />
                                              <div class="input-group-append">
                                                <button
                                                  class="btn btn-outline-secondary dropdown-toggle"
                                                  type="button"
                                                  data-toggle="dropdown"
                                                  id="diseaseDropdown"
                                                ></button>
                                                <div class="dropdown-menu">
                                                  <span
                                                    class="dropdown-item"
                                                    v-for="(
                                                      disease, index
                                                    ) in preexisting_conditions_options"
                                                    :key="index"
                                                    v-on:click="
                                                      selectDisease(disease)
                                                    "
                                                  >
                                                    {{ disease }}
                                                  </span>
                                                  <span
                                                    class="dropdown-item"
                                                    v-on:click="
                                                      selectDisease('Outra')
                                                    "
                                                    >Outra</span
                                                  >
                                                </div>
                                              </div>
                                            </div>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Atividade Física"
                                          >
                                            <select
                                              v-model="
                                                employee.anamnesis_data
                                                  .physical_activity
                                              "
                                              class="form-control"
                                              id="physicalactivitySelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="0x">
                                                Não pratica exercícios
                                              </option>
                                              <option value="1x">
                                                1x por semana
                                              </option>
                                              <option value="2x">
                                                2x por semana
                                              </option>
                                              <option value="3x">
                                                3x por semana
                                              </option>
                                              <option value="4x">
                                                4x por semana
                                              </option>
                                              <option value="5+">
                                                5+ por semana
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Medicamentos de uso contínuo"
                                          >
                                            <select
                                              v-model="
                                                employee.anamnesis_data
                                                  .medicines_for_continuous_use
                                              "
                                              class="form-control"
                                              id="medicinesSelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="S">Sim</option>
                                              <option value="N">Não</option>
                                            </select>
                                          </TwInput>
                                        </div>
                                        <div class="row">
                                          <TwInput
                                            class="blood-pressure"
                                            col_lg="4"
                                            label="Pressão arterial"
                                          >
                                            <select
                                              v-model="
                                                employee.anamnesis_data
                                                  .blood_pressure_level
                                              "
                                              class="form-control"
                                              id="pressureSelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option
                                                v-for="(
                                                  blood_pressure_level, index
                                                ) in blood_pressure_level_options"
                                                :key="index"
                                                :value="
                                                  blood_pressure_level.val
                                                "
                                              >
                                                {{
                                                  blood_pressure_level.option
                                                }}
                                              </option>
                                            </select>
                                          </TwInput>
                                          <TwInput col_lg="4" label="Gravidez">
                                            <select
                                              v-model="
                                                employee.anamnesis_data
                                                  .pregnancy
                                              "
                                              class="form-control"
                                              id="pregnancySelect"
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              <option value="S">Sim</option>
                                              <option value="N">Não</option>
                                            </select>
                                          </TwInput>
                                          <TwInput
                                            col_lg="4"
                                            label="Data (último ASO)"
                                          >
                                            <input
                                              class="form-control"
                                              type="date"
                                              :min="'1900-01-01'"
                                              :max="
                                                new Date().toISOString().split('T').shift()
                                              "
                                              v-model="employee.functional_data.last_ohc"
                                              id="ohcSelect"
                                            />
                                          </TwInput>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row p-1">
                    <button
                      id="saveEditEmployee"
                      class="btn btn-primary px-5"
                      v-on:click="updateEmployee"
                    >
                      <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
export default {
  name: "EmployeeImportConfig",
  props: {
    entireEmployee: {
      type: Object,
      required: false,
    },
    area_options: {
      type: Array,
      required: true,
    },
    department_options: {
      type: Array,
      required: true,
    },
    position_options: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      tabSelected: "functionalTab",
      preexisting_conditions_options: [
        "Asma",
        "Cardiopatia",
        "Diabetes",
        "Hepatite",
      ],
      work_shift_options: ["Administrativo", "Primeiro", "Segundo", "Terceiro"],
      blood_pressure_level_options: [
        { val: "BAIXO", option: "BAIXA" },
        { val: "NORMAL", option: "NORMAL" },
        { val: "ELEVADO", option: "ELEVADO" },
        { val: "HIPERTENSAO-ESTAGIO-1", option: "HIPERTENSÃO Estágio I" },
        { val: "HIPERTENSAO-ESTAGIO-2", option: "HIPERTENSÃO Estágio II" },
        { val: "CRISE-HIPERTENSIVA", option: "CRISE HIPERTENSIVA" },
      ],
      breadcrumbList: [],
      DEFAULT: true,

      employee: {
        name: "",
        email: "",
        cellphone: "",
        cpf: "",
        registration_number: "",
        gender: "",
        date_of_birth: "",
        anamnesis_data: {
          height: "",
          weight: "",
          smoking: "",
          preexisting_conditions: [],
          physical_activity: "",
          medicines_for_continuous_use: "",
          blood_pressure_level: "",
          pregnancy: "",
        },
        functional_data: {
          area: "",
          department: "",
          position: "",
          work_shift: "",
          work_week: "",
          state: "",
          date_of_hire: "",
          date_of_dismissal: "",
          supervisor: {
            name: "",
            registration_number: "",
          },
          last_ohc: "",
          health_plan: "",
        },
      },
      employeeSupervisor: "",
    };
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getEmployees", {
        customer_id: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getAreas", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getDepartments", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getPositions", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }

    this.updateList();
  },
  created() {
    this.updateList();
    // If user presses outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == document.getElementById("employeeModal")) {
      }
    };
  },
  watch: {
    entireEmployee() {
      this.employee = this.entireEmployee;
      
      this.employeeSupervisor = this.employee.functional_data.supervisor.name ?? ""
    },
    $route() {
      this.updateList();
    },
  },
  methods: {
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    updateEmployee() {
      document.getElementById("gtw-config-close-modal").click();
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    onSelectSupervisor(supervisor) {
      if (supervisor == "none") {
        this.employeeSupervisor = "";
        this.employee.functional_data.supervisor = {
          name: null,
          registration_number: null,
        };
        return;
      }
      this.employeeSupervisor = supervisor.name;
      this.employee.functional_data.supervisor = {
        name: supervisor.name,
        registration_number: supervisor.registration_number,
      };
    },
    selectDisease(disease) {
      if (this.employee.anamnesis_data.preexisting_conditions.includes(disease))
        this.employee.anamnesis_data.preexisting_conditions =
          this.employee.anamnesis_data.preexisting_conditions.filter(
            (x) => x !== disease
          );
      else this.employee.anamnesis_data.preexisting_conditions.push(disease);
    },
    changeState(state){
      this.employee.functional_data.date_of_dismissal = 
          state !== "FIRED" ? 
              this.employee.functional_data.date_of_dismissal : "";
    }
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customer_employees() {
      return this.$store.getters["employee/employees"];
    },
    anamnesisTabSelected() {
      return this.tabSelected === "anamnesisTab";
    },
    functionalTabSelected() {
      return this.tabSelected === "functionalTab";
    },
    diseasesList() {
      return this.employee.anamnesis_data.preexisting_conditions.join(", ");
    },
    filteredSupervisors() {
      if (this.employeeSupervisor) {
        return this.customer_employees.filter((employee) => {
          return (
            employee.name
              .toLowerCase()
              .includes(this.employeeSupervisor.toLowerCase()) &&
            employee.id !== this.employee.id &&
            employee.functional_data?.position?.toLowerCase() === "supervisor"
          );
        });
      }
      return this.customer_employees.filter(
        (employee) =>
          employee.id !== this.employee.id &&
          employee.functional_data?.position?.toLowerCase() === "supervisor"
      );
    },
  },
};
</script>
<style>
.margin-left-zero {
  margin-left: 0;
}
.eyesight {
  margin-left: 5px;
  position: absolute;
  right: 36px;
  top: 42px;
  cursor: pointer;
}
.same-size-ps {
  width: 150px;
  text-align: left;
  background-color: #e9ecef;
}
</style>
