<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1>Importar Tags</h1>
    </div>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <div class="col-12 pr-4">
                <div class="form-group">
                  <UploadExcel
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :draggable="true"
                  />
                </div>
              </div>
            </div>

            <div v-show="tableData.length">
              <div class="content-header px-0">
                <h4>Tags encontradas</h4>
              </div>
              <b-table
                responsive="sm"
                striped
                borderless
                hover
                :fields="fields"
                :items="tableData"
                class="mt-3 rounded"
                selectable
                @row-selected="onRowSelected"
                select-mode="multi"
                ref="selectableTable"
                editableFields
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <select
                    class="form-control"
                    v-if="field.key === 'tag_model'"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'tag-model-' + field.key"
                    @change="isTagValid(item)"
                  >
                    <option v-for="opt in models" :key="opt.name">
                      {{ opt }}
                    </option>
                  </select>
                  <select
                    class="form-control"
                    v-if="field.key === 'tag_type'"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'tag-type-' + field.key"
                  >
                    <option v-for="opt in types" :key="opt.name">
                      {{ opt }}
                    </option>
                  </select>

                  <input
                    type="text"
                    class="form-control"
                    v-if="
                      field.key !== 'mac_address' &&
                      field.key !== 'tag_type' &&
                      field.key !== 'tag_model' &&
                      field.key !== 'longitude' && 
                      field.key !== 'altitude' &&
                      field.key !== 'latitude'
                    "
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-in-' + field.key"
                    @change="isTagValid(item)"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-mask="'NN:NN:NN:NN:NN:NN'"
                    v-model="item[field.key]"
                    :placeholder="field.key"
                    v-if="field.key === 'mac_address'"
                    :key="field.key"
                    :id="'imp-inmac-' + field.key"
                    @change="isTagValid(item)"
                  />
                  <span
                    v-if="item.errors"
                    style="color: red"
                    :key="'erro-' + field.key"
                    >{{ item.errors[field.key] }}</span
                  >
                </template>
                <template v-slot:cell(latitude)="data">
                  <input
                    type="number"
                    step="0.000001"
                    class="form-control"
                    :disabled="data.item.tag_type !== 'STATIC'"
                    id="imp-in-latitude"
                    placeholder="Latitude"
                    v-model="data.item.latitude"
                    @change="isTagValid(item)"
                  />

                  <span
                    v-if="data.item.errors"
                    style="color: red"
                    :key="'erro-latitude'"
                    >{{ data.item.errors.latitude }}</span
                  >
                </template>
                <template v-slot:cell(longitude)="data">
                  <input
                    type="number"
                    step="0.000001"
                    class="form-control"
                    :disabled="data.item.tag_type !== 'STATIC'"
                    id="imp-in-longitude"
                    placeholder="Longitude"
                    v-model="data.item.longitude"
                    @change="isTagValid(item)"
                  />
                  <span
                    v-if="data.item.errors"
                    style="color: red"
                    :key="'erro-longitude'"
                    >{{ data.item.errors.longitude }}</span
                  >
                </template>
                <template v-slot:cell(altitude)="data">
                  <input
                    type="number"
                    step="1"
                    class="form-control"
                    :disabled="data.item.tag_type !== 'STATIC'"
                    id="imp-in-altitude"
                    placeholder="Altitude"
                    v-model="data.item.altitude"
                    @change="isTagValid(item)"
                  />
                  <span
                    v-if="data.item.errors"
                    style="color: red"
                    :key="'erro-altitude'"
                    >{{ data.item.errors.altitude }}</span
                  >
                </template>
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true" class="check-symbol">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{
                      width:
                        field.key === 'selected' || field.key === 'actions'
                          ? '90px'
                          : '550px',
                    }"
                  />
                </template>
                <template v-slot:cell(invalid)="data">
                  <span
                    v-if="data.item.invalid"
                    class="valid-import badge badge-danger"
                    >Inválido</span
                  >
                  <span
                    v-if="!data.item.invalid"
                    class="valid-import badge badge-success"
                    >Válido</span
                  >
                </template>
                <template v-slot:cell(actions)="data">
                  <a
                    @click="user = data.item"
                    href="#"
                    class="mr-3"
                    data-toggle="modal"
                    data-target="#modalDeleteUser"
                    title="Excluir"
                    v-if="
                      storage
                        ? storage.getItem('email') != data.item.email
                        : false
                    "
                  >
                    <i class="far fa-trash-alt"></i>
                  </a>
                  <router-link
                    :to="{ name: 'user-edit', params: { user: data.item } }"
                    title="Editar"
                    id="imp-user-edit-data"
                  >
                    <i class="fas fa-pen"></i>
                  </router-link>
                </template>
              </b-table>

              <div class="row p-1">
                <button
                  id="selectAllTags"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="selectAllRows"
                >
                  Selecionar tudo
                </button>
                <button
                  id="clearAllTags"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="clearSelected"
                >
                  Desselecionar tudo
                </button>
                <button
                  id="removerTags"
                  type="button"
                  class="btn btn-outlined btn-danger mr-2"
                  @click="deleteSelected"
                >
                  Remover selecionados
                </button>
                <button
                  id="salvarTags"
                  :disabled="load"
                  @click="saveTags"
                  class="btn btn-success px-5"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                  Salvar
                </button>
              </div>
            </div>
            <span v-if="!tableData.length" style="color: blue">
              Selecione um arquivo de tags |
              <a v-bind:href="item.loc" download>Modelo de importação xlsx</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel/index.vue";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: { Breadcrumb, UploadExcel },
  data() {
    return {
      breadcrumbList: [],
      tableData: [],
      tableHeader: [],
      storage: null,
      user: "",
      sortBy: "tag_label",
      currentPage: 1,
      fields: [
        { key: "selected", sortable: false, label: "Selecionado" },
        {
          key: "tag_model",
          editable: true,
          sortable: true,
          label: "Modelo",
        },
        { key: "tag_label", editable: true, sortable: false, label: "Nome" },
        { key: "tag_type", editable: true, sortable: false, label: "Tipo" },
        {
          key: "mac_address",
          editable: true,
          sortable: true,
          label: "MAC Address",
        },
        {
          key: "latitude",
          editable: true,
          sortable: true,
          label: "Latitude",
        },
        {
          key: "longitude",
          editable: true,
          sortable: true,
          label: "Longitude",
        },
        {
          key: "altitude",
          editable: true,
          sortable: true,
          label: "Altitude",
        },
        {
          key: "invalid",
          label: "Validade",
        },
      ],
      selected: [],
      item: {
        title: "Excel template",
        loc: "/assets/files/model_import_tags.xlsx",
      },
      fieldsHeader: [
        {
          key: "tag_label",
          label: "Nome",
        },
        {
          key: "tag_model",
          label: "Modelo",
        },
        {
          key: "tag_type",
          label: "Tipo",
        },
        {
          key: "mac_address",
          label: "MAC Address",
        },
        {
          key: "latitude",
          label: "Latitude",
        },
        {
          key: "longitude",
          label: "Longitude",
        },
        {
          key: "altitude",
          label: "Altitude",
        },
      ],
    };
  },
  mounted() {
    this.updateList();
    this.$store.dispatch("tag/getModels");
    this.$store.dispatch("tag/getTagTypes");
    this.$store.dispatch("tag/get");
    this.storage =
      localStorage.getItem("rememberMe") === "true"
        ? window.localStorage
        : window.sessionStorage;
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    saveTags() {
      if (this.selected.length > 0 && this.validateSelected()) {
        let sendTags = [...this.selected];
        sendTags.forEach((element) => {
          if (element.mac_address[element.mac_address.length - 1] === ":")
            element.mac_address = element.mac_address.slice(0, -1);

          if (element.tag_type !== "STATIC"){
            element.latitude = null;
            element.longitude = null;
            element.altitude = null;
          }

          else {
            element.latitude = Number(element.latitude).toFixed(6);
            element.longitude = Number(element.longitude).toFixed(6);
            element.altitude = Number(element.altitude).toFixed(0);
          }
          
          delete element.invalid;
          delete element.errors;
        });
        this.$store.dispatch("changeLoad", true);
        this.$store.dispatch("tag/saveList", {
          tags: sendTags,
        });

        // delete the selected tags from the table
        this.tableData = this.tableData.filter(
          (tag) => !this.selected.includes(tag)
        );
        this.selected = [];
      } else {
        this.$store.dispatch("changeLoad", false);
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          {
            field: "Dados das Tags",
          }
        );
        return;
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteSelected() {
      this.tableData = this.tableData.filter((e) => {
        return !this.selected.includes(e);
      });
      this.$refs.selectableTable.refresh();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.tableData = [];
      this.tableHeader = [];
      if (results.length < 50) {
        this.tableData = this.transformDotNotation(this.changeKeys(results));
        this.tableHeader = header;
        this.validate(this.tableData);
      } else {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "a quantidade de linhas na planilha excede o limite de 50",
          }
        );
      }
    },
    isTagValid(tag) {
      tag.errors = {
        tag_model: null,
        tag_label: null,
        tag_type: null,
        mac_address: null,
        latitude: null,
        longitude: null,
        altitude: null,
      };

      tag.invalid = false;

      try {
        /** Tag model validation **/
        if (!this.models.includes(tag.tag_model)) {
          tag.invalid = true;
          tag.errors["tag_model"] = "Modelo inválido";
        }

        /** Tag label validation **/
        if (!tag.tag_label || tag.tag_label.trim() === "") {
          tag.invalid = true;
          tag.errors["tag_label"] = "Nome não pode ser vazio";
        }

        /** Tag type validation **/
        if (!tag.tag_type || tag.tag_type.trim() === "") {
          tag.invalid = true;
          tag.errors["tag_type"] = "Tipo inválido";
        }

        /** Tag MAC address validation **/
        let validCaracters = /^[0-9a-fA-F]+$/;
        let mac_address = tag.mac_address.toString().replace(/:|-/g, "");

        if (!validCaracters.test(mac_address)) {
          tag.invalid = true;
          tag.errors["mac_address"] = "MAC Address possui caractere inválido";
        }

        if (mac_address.length !== 12) {
          tag.invalid = true;
          tag.errors["mac_address"] = "MAC Address inválido";
        }

        let checkMac = (obj) => obj.mac_address === mac_address;

        let checkMac2 = (obj) =>
          obj.mac_address ? obj.mac_address.toString().replace(/:|-/g, "") === mac_address : "";

        const repeat_mac = this.tableData.filter(checkMac2);

        tag.mac_address = mac_address.replace(/(.{2})/g, "$1:");
        tag.mac_address = tag.mac_address.toLowerCase();

        if (repeat_mac.length > 1) {
          tag.invalid = true;
          tag.errors["mac_address"] = "MAC Address duplicado nessa importação";
        }

        if (this.tags.some(checkMac)) {
          tag.invalid = true;
          tag.errors["mac_address"] = "MAC Address já cadastrado";
        }

        if (tag.tag_type === "STATIC"){
          if (!tag.latitude || tag.latitude === ""){
            tag.invalid = true;
            tag.errors["latitude"] = "Latitude não pode ser vazia";
          }

          else if (!/^[-+]?\d+(\.\d*)?$/.test(tag.latitude)){
            tag.invalid = true;
            tag.errors["latitude"] = "Latitude inválida";
          }

          else if (tag.latitude >= 180 || tag.latitude <= -180){
            tag.invalid = true;
            tag.errors["latitude"] = "Valor deve estar entre -180 e 180";
          }

          if (!tag.longitude || tag.longitude === ""){
            tag.invalid = true;
            tag.errors["longitude"] = "Latitude não pode ser vazia";
          }

          else if (!/^[-+]?\d+(\.\d*)?$/.test(tag.longitude)){
            tag.invalid = true;
            tag.errors["longitude"] = "Longitude inválida";
          }

          else if (tag.longitude >= 180 || tag.longitude <= -180){
            tag.invalid = true;
            tag.errors["longitude"] = "Valor deve estar entre -180 e 180";
          }

          if (!tag.altitude || tag.altitude === ""){
            tag.invalid = true;
            tag.errors["altitude"] = "Altitude não pode ser vazia";
          }

          if (tag.altitude && !/^[-+]?\d+(\.\d*)?$/.test(tag.altitude)){
            tag.invalid = true;
            tag.errors["altitude"] = "Altitude inválida";
          }

          else if (tag.altitude && tag.altitude < 0){
            tag.invalid = true;
            tag.errors["altitude"] = "Valor deve ser igual ou maior que 0";
          }
        }
      } catch (e) {
        if (!tag.mac_address)
          tag.errors["mac_address"] = "MAC Address não importado";
      }

      this.$refs.selectableTable.refresh()
      return !tag.invalid;
    },
    validate() {
      this.tableData.forEach((e) => this.isTagValid(e));

      const valido = !this.tableData.some((e) => e.invalid === true);

      this.$refs.selectableTable.refresh();
      return valido;
    },
    validateSelected(){
      this.selected.forEach((e) => this.isTagValid(e));
      const valido = !this.selected.some((e) => e.invalid === true);

      this.$refs.selectableTable.refresh();
      return valido;
    },
    changeKeys(obj_array) {
      obj_array.forEach((elem) => {
        Object.keys(elem).forEach((key) => {
          for (let y in this.fieldsHeader) {
            if (key.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
              /* Create new key with the same values/properties */
              Object.defineProperty(
                elem,
                this.fieldsHeader[y].key,
                Object.getOwnPropertyDescriptor(elem, key)
              );
              /*Delete old key*/
              delete elem[key];
              break;
            }
          }
        });
      });

      return obj_array;
    },
    transformDotNotation(data) {
      let transformedData = [];
      data.forEach((element) => {
        let transformedElement = {
          tag_label: "",
          tag_model: "",
          tag_type: "",
          mac_address: "",
        };
        for (let key in element) {
          let keyArray = key.split(".");
          let currentElement = transformedElement;
          for (let i = 0; i < keyArray.length; i++) {
            if (i === keyArray.length - 1) {
              currentElement[keyArray[i]] = element[key];
            } else {
              if (!currentElement[keyArray[i]]) {
                currentElement[keyArray[i]] = {};
              }
              currentElement = currentElement[keyArray[i]];
            }
          }
        }

        transformedElement.tag_model = transformedElement.tag_model ? transformedElement.tag_model.toUpperCase() : "";
        transformedElement.tag_type = transformedElement.tag_type ? transformedElement.tag_type.toUpperCase() : "";

        if(transformedElement.tag_type !== "STATIC"){
          transformedElement.latitude = null;
          transformedElement.longitude = null;
          transformedElement.altitude = null;
        }
        
        transformedData.push(transformedElement);
      });

      return transformedData;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    editableFields() {
      return this.fields.filter((field) => field.editable);
    },
    models() {
      return this.$store.getters["tag/models"].map((e) => {
        return e.name;
      });
    },
    types() {
      return this.$store.getters["tag/tagTypes"].map((e) => {
        return e.name;
      });
    },
    tags() {
      return this.$store.getters["tag/tags"];
    },
  },
};
</script>

<style lang="scss" scoped>
.check-symbol {
  font-size: 3rem;
  margin-top: -30px;
  margin-left: 15px;
  position: absolute;
  display: block;
  text-align: center;
  user-select: none;
}

.valid-import {
  width: 100px;
  font-size: 20px;
  padding: 10px;
  border-radius: 50px;
  transition: none;
}
</style>
