<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Adicionar Tag</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <div class="card-body">
            <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="card direct-chat direct-chat-primary">
                    <div class="card-body custom-card overflow-visible">
                      <ul class="nav nav-tabs bg-main">
                        <li class="nav-item">
                          <a
                            id="addTag-tab"
                            class="nav-link first"
                            v-bind:class="{
                              active: addTagSelected,
                            }"
                            v-on:click="changeTab('addTag')"
                            href="#"
                            aria-current="addTag"
                            tabindex="-1"
                            >Adicionar Tag</a
                          >
                        </li>
                        <li class="nav-item active">
                          <a
                            class="nav-link"
                            v-bind:class="{
                              active: this.tabSelected === 'vocalizationData',
                            }"
                            v-on:click="changeTab('vocalizationData')"
                            href="#"
                            aria-current="vocalizationData"
                            tabindex="-1"
                            id="vocalizationData"
                            >Dados de Vocalização</a
                          >
                        </li>
                      </ul>

                      <div class="p-4 bg-white rounded">
                        <div class="tab-content">
                          <div
                            class="tab-pane fade"
                            v-bind:class="{
                              show: addTagSelected,
                              active: addTagSelected,
                            }"
                          >
                            <div>
                              <form @submit="saveTag" novalidate="true">
                                <div class="col-12">
                                  <div class="row">
                                    <TwInput label="Nome da Tag" required="false">
                                      <input
                                        required
                                        v-model="tag.tag_label"
                                        type="text"
                                        maxlength="50"
                                        class="form-control"
                                        placeholder="Nome da Tag"
                                        id="tagname"
                                      />
                                    </TwInput>
                                    <TwInput
                                      label="Identificador"
                                      required="false"
                                    >
                                      <input
                                        v-model="tag.mac_address"
                                        v-mask="'NN:NN:NN:NN:NN:NN'"
                                        type="text"
                                        class="form-control"
                                        placeholder="Identificador"
                                        id="identifierinput"
                                      />
                                    </TwInput>
                                  </div>
                                  <div class="row">
                                    <TwInput label="Modelo" required="false">
                                      <select
                                        id="modelform"
                                        class="form-control"
                                        v-model="tag.tag_model"
                                      >
                                        <option
                                          v-for="(model, index) in models"
                                          :key="index"
                                          class="p-2"
                                          :value="model.name"
                                          :id="model.name"
                                        >
                                          {{ model.name }}
                                        </option>
                                      </select>
                                    </TwInput>
                                    <TwInput label="Tipo" required="false">
                                      <select
                                        required
                                        id="typeform"
                                        class="form-control"
                                        v-model="tag.tag_type"
                                        placeholder="Tipo de Tag"
                                      >
                                        <option
                                          v-for="(tagType, index) in tagTypes"
                                          :key="index"
                                          class="p-2"
                                          :value="tagType.name"
                                          :id="tagType.name"
                                        >
                                          {{ tagType.name }}
                                        </option>
                                      </select>
                                    </TwInput>
                                  </div>
                                </div>
                                <div v-if="tag.tag_type === 'STATIC'">
                                  <div class="col-12">
                                    <div class="row">
                                      <TwInput
                                        col_lg="4"
                                        label="Latitude"
                                      >
                                        <input
                                          v-model="tag.latitude"
                                          type="number"
                                          class="form-control"
                                          placeholder="Latitude"
                                          id="latitudeInput"
                                        />
                                      </TwInput>
                                      <TwInput
                                        col_lg="4"
                                        label="Longitude"
                                      >
                                        <input
                                          v-model="tag.longitude"
                                          type="number"
                                          class="form-control"
                                          placeholder="Longitude"
                                          id="longitudeInput"
                                        />
                                      </TwInput>
                                      <TwInput
                                        col_lg="4"
                                        label="Altitude"
                                      >
                                        <input
                                          v-model="tag.altitude"
                                          type="number"
                                          class="form-control"
                                          placeholder="Altitude"
                                          id="altitudeInput"
                                        />
                                      </TwInput>
                                    </div>
                                  </div>
                                </div>
                                <div class="row p-1">
                                  <button
                                    id="saveTag"
                                    type="submit"
                                    class="btn btn-primary px-5"
                                    :disabled="load"
                                  >
                                    <i
                                      v-if="load"
                                      class="fas fa-sync-alt fa-spin"
                                    ></i>
                                    Salvar
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>

                          <div
                            class="tab-pane fade"
                            v-bind:class="{
                              show: vocalizationDataSelected,
                              active: vocalizationDataSelected,
                            }"
                          >
                            <div class="row">
                              <TwInput label="Text message" required="false">
                                <input
                                  required
                                  v-model="tag.tag_msg.text"
                                  type="text"
                                  class="form-control"
                                  placeholder="Text message"
                                  id="textmessage"
                                />
                              </TwInput>
                              <TwInput label="Voice message" required="false">
                                <input
                                  required
                                  v-model="tag.tag_msg.voice"
                                  type="text"
                                  class="form-control"
                                  placeholder="Voice message"
                                  id="voicemessage"
                                />
                              </TwInput>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwInput from "@/components/TwInput.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import "./style.scss";
export default {
  name: "AddTag",
  data: function () {
    return {
      tabSelected: "addTag",
      breadcrumbList: [],
      modelsSelecteds: [],
      tag: {
        tag_type: "",
        mac_address: "",
        tag_label: "",
        altitude: "",
        longitude: "",
        latitude: "",
        tag_msg: {
          text: "",
          voice: "",
        },
      },
    };
  },
  mounted() {
    this.updateList();
    this.$store.dispatch("tag/getModels");
    this.$store.dispatch("tag/getTagTypes");
  },
  watch: {
    $route() {
      this.updateList;
    },
  },
  methods: {
    isTagValid(tag) {
      if (!tag.tag_model || tag.tag_model.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Modelo da Tag" }
        );
        return false;
      }

      if (!tag.tag_label || tag.tag_label.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome da Tag" }
        );
        return false;
      }

      if (!tag.tag_type || tag.tag_type.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Tipo da Tag" }
        );
        return false;
      }

      if (!tag.mac_address || tag.mac_address.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Identificador" }
        );
        return false;
      }

      if (
        /^[0-9a-f]{1,2}([.:-])[0-9a-f]{1,2}(?:\1[0-9a-f]{1,2}){4}$/i.test(
          tag.mac_address
        ) === false
      ) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Identificador" }
        );
        return false;
      }

      if (this.tag.tag_type === "STATIC"){
        if (!this.tag.latitude || this.tag.latitude === ""){
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
            { field: "Latitude"}
          );

          return false;
        }

        else if (!/^[-+]?\d+(\.\d*)?$/.test(this.tag.latitude)){
          window.frontMsg(
            "FRNT_INVALID_FIELD_WARNING",
            { field: "Latitude", extra: "Digite um número válido" }
          );

          return false;
        }

        else if ((this.tag.latitude >= 180 || this.tag.latitude <= -180)){
            window.frontMsg(
            "FRNT_NUM_RANGE_WARNING",
            { field: "Latitude", numbercondition: "entre -180 e 180" }
          );

          return false;
        }

        if (!this.tag.longitude || this.tag.latitude === ""){
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
            { field: "Longitude"}
          );

          return false;
        }

        else if (this.tag.longitude && !/^[-+]?\d+(\.\d*)?$/.test(this.tag.longitude)){
          window.frontMsg(
            "FRNT_INVALID_FIELD_WARNING",
            { field: "Longitude", extra: "Digite um número válido" }
          );

          return false;
        }

        else if (this.tag.longitude >= 180 || this.tag.longitude <= -180){
            window.frontMsg(
            "FRNT_NUM_RANGE_WARNING",
            { field: "Longitude", numbercondition: "entre -180 e 180" }
          );

          return false;
        }

        if (!this.tag.altitude || this.tag.latitude === ""){
          window.frontMsg(
            "FRNT_EMPTY_WARNING",
            { field: "Altitude"}
          );

          return false;
        }

        else if (!/^[-+]?\d+(\.\d*)?$/.test(this.tag.altitude)){
          window.frontMsg(
            "FRNT_INVALID_FIELD_WARNING",
            { field: "Altitude", extra: "Digite um número válido" }
          );

          return false;
        }

        else if (this.tag.altitude < 0){
            window.frontMsg(
            "FRNT_NUM_RANGE_WARNING",
            { field: "Altitude", numbercondition: "maior do que 0" }
          );

          return false;
        }
      }

      return true;
    },
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    saveTag: function () {
      event.preventDefault();
      if (!this.isTagValid(this.tag)) {
        return;
      }
      if (this.tag_state === null) {
        this.tag_state = {
          administrative_state: null,
          battery_level: null,
          operational_state: null,
        };
      }

      if (this.tag.tag_type !== "STATIC"){
        this.tag.altitude = null;
        this.tag.longitude = null;
        this.tag.latitude = null;
      }

      else {
        this.tag.altitude = Number(this.tag.altitude).toFixed(0);
        this.tag.longitude =  Number(this.tag.longitude).toFixed(6);
        this.tag.latitude = Number(this.tag.latitude).toFixed(6);
      }

      this.$store.dispatch("tag/saveTag", {
        ...this.tag,
        mac_address: this.tag.mac_address.replace(/:|-/g, ""),
      });
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    models() {
      return this.$store.getters["tag/models"];
    },
    tagTypes() {
      return this.$store.getters["tag/tagTypes"];
    },
    addTagSelected() {
      return this.tabSelected === "addTag";
    },
    vocalizationDataSelected() {
      return this.tabSelected === "vocalizationData";
    },
  },
};
</script>
