<template>
  <div>
    <div class="content-header px-0 pb-0">
      <h1 class="title-I">Coletores cadastrados</h1>
    </div>
    <div class="row px-0 d-flex align-items-center col-12 p-0">
      <TwInput col_lg="3" label="">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control"
            id="filterInput"
            placeholder="Buscar"
          />
        </b-input-group>
      </TwInput>

      <TwInput col_lg="3" label="">
        <b-form-select
          class="form-control d-block"
          v-model="customer"
          :options="customers"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>
              Buscar por Cliente
            </b-form-select-option>
          </template>
        </b-form-select>
      </TwInput>

      <TwInput col_lg="6" label="">
        <div
          style="height: 100%; width: 100%"
          class="d-flex align-items-center justify-content-center"
        >
          <router-link
            id="addGateway"
            v-can="['ADMIN']"
            title="Adicionar coletor"
            to="/gateway/add"
            class="btn btn-primary add-user col-4 ml-5 align-self-center"
          >
            <span class="d-none d-md-block d-lg-block">Adicionar</span>
            <span class="d-block d-md-none d-lg-none">
              <i class="fas fa-plus-circle"></i>
            </span>
          </router-link>
          <router-link
            v-can="['ADMIN']"
            title="Adicionar coletores"
            to="/gateway/import"
            class="btn btn-primary col-4 ml-2 align-self-center"
            id="importGateways"
          >
            <span class="d-none d-md-block d-lg-block">Importar</span>
            <span class="d-block d-md-none d-lg-none">
              <i class="fas fa-file-import"></i>
            </span>
          </router-link>
          <router-link
            v-can="['ADMIN']"
            title="Application ID"
            to="/appID"
            class="btn btn-primary col-4 ml-2 align-self-center"
            id="manageAppID"
          >
            <span class="d-none d-md-block d-lg-block">App-ID</span>
            <span class="d-block d-md-none d-lg-none">
              <i class="fas fa-file-import"></i>
            </span>
          </router-link>
        </div>
      </TwInput>
    </div>

    <b-table
      id="selectableTable"
      responsive="sm"
      striped
      borderless
      hover
      class="mt-0 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum registro encontrado para essa busca"
      selectable
      select-mode="multi"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      @context-changed="changeBsTableState"
      :filter="filter"
      :busy="load"
      :items="gateways"
      :fields="fields"
      @filtered="onFiltered"
      @row-selected="onRowSelected"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :id="field.key"
          :key="field.key"
          :style="{
            width: ['actions', 'error'].includes(field.key) ? '130px' : '550px'
          }"
        />
      </template>

      <template v-slot:cell(gateway_config)="data">
          <router-link
            id="openConfig"
            :to="{ name: 'gateways-config', params: { gateway: data.item, mac_address: data.item.mac_address } }"
            v-can="['ADMIN']"
            title="Abrir configurações"
          >
            <i class="fas fa-xl fa-gear fa-1x" style="margin-left: 15px; color: gray"></i>
            <i v-if="data.item.gateway_config" class="fas fa-check-circle fa-1x" style="color: green; margin: 2px;" title="Configuração existente"></i>
            <i v-else class="fas fa-times-circle fa-1x" style="color: red; margin: 2px;" title="Configuração não existente"></i>
          </router-link>
      </template>

      <template v-slot:cell(actions)="data">
        <button
          id="admStateGateway"
          type="button"
          @click="gatewayChange = data.item"
          v-can="['ADMIN', 'SYSADMIN']"
          class="mr-3"
          data-toggle="modal"
          data-target="#ModalStateAdminstrative"
          title="Alterar Estado Administrativo"
        >
          <i
            v-if="
              data.item.gateway_state &&
              data.item.gateway_state.administrative_state === 'IN-SERVICE'
            "
            class="fas fa-lg fa-toggle-on iconspace"
            title="Ligado"
          ></i>
          <i
            v-if="
              data.item.gateway_state &&
              data.item.gateway_state.administrative_state === 'OUT-OF-SERVICE'
            "
            class="fas fa-lg fa-toggle-off iconspace"
            title="Desligado"
          ></i>
        </button>
        <a
          id="deleteGateway"
          @click="gatewayDelete = data.item"
          v-can="['ADMIN']"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#ModalDeleteGateway"
          title="Excluir"
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>

        <router-link
          id="editGateway"
          :to="{ name: 'gateways-edit', params: { gateway: data.item, mac_address: data.item.mac_address} }"
          v-can="['ADMIN']"
          title="Editar"
        >
          <i class="fas fa-pen iconspace"></i>
        </router-link>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(gateway_state.operational_state)="data">
          <router-link
            id="StatusGateway"
            :to="{ name: 'gateways-status', params: { gateway: data.item } }"
            v-can="['ADMIN']"
            title="Status"
          >
            <i
              class="fas fa-xl fa-file-alt fa-1x"
              style="margin-left: 15px; color: orange"
            ></i>
            <i
              class="fas fa-check-circle fa-1x"
              style="color: green; margin: 2px;"
              v-if="
                data.item.gateway_state &&
                data.item.gateway_state.operational_state === 'ONLINE'
              "
              title="Online"
            ></i>
            <i
              class="fas fa-circle-play fa-1x"
              style="color: green; margin: 2px;"
              v-if="
                data.item.gateway_state &&
                data.item.gateway_state.operational_state == 'STARTED'
              "
              title="STARTED"
            ></i> 
            <i
              class="fas fa-times-circle fa-1x"
              style="color: red; margin: 2px;"
              v-if="
                data.item.gateway_state && (data.item.gateway_state.operational_state === 'OFFLINE' || data.item.gateway_state.operational_state === 'FAILED' || data.item.gateway_state.operational_state === 'STOPPED')
              "
              :title="data.item.gateway_state.operational_state"
            ></i>
          </router-link>
      </template>
    </b-table>

    <Modal
      id="ModalDeleteGateway"
      @click="gatewayDelete = data.item"
      title="Excluir coletor"
      v-bind:msg="
        'Tem certeza que deseja excluir ' + gatewayDelete.gateway_name + '?'
      "
      button="Excluir"
      :method="deleteGateway"
    />
    <Modal
      id="ModalDeleteMultipleGateways"
      @click="gatewayDelete = data.item"
      title="Excluir coletores"
      v-bind:msg="
        'Tem certeza que deseja excluir os coletores selecionados?'
      "
      button="Excluir"
      :method="bulkDelete"
    />
    <Modal
      id="ModalStateAdminstrative"
      @click="gatewayChange = data.item"
      title="Alterar Estado Administrativo"
      v-bind:msg="
        'Tem certeza que deseja alterar o estado administrativo ' +
        gatewayChange.gateway_name +
        '?'
      "
      button="Alterar"
      buttonMod="info"
      :method="stateAdministrative"
    />
    <!-- Modal for paste config -->
    <Modal
      id="ModalPasteConfig"
      title="Colar configuração"
      v-bind:msg="
        'Você tem certeza que deseja distribuir a configuração para '+ selected.length +' coletor(es)?' 
      "
      button="Colar"
      buttonMod="info"
      :method="bulkPasteConfig"
    />
    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          id="gateman-pagination"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            id="linhasDropdown"
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              id="dez"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              id="vinte"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              id="cinquenta"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              id="mil"
              @click="
                () => {
                  this.perPage = 1000;
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown id="selecionar" variant="primary" v-model="perPage" :text="`Selecionar`">
            <b-dropdown-item
              href="#"
              id="todos"
              @click="
                () => {
                  this.selectAllRows();
                }
              "
              >Todos</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              id="nenhum"
              @click="
                () => {
                  this.clearSelected();
                }
              "
              >Nenhum</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <b-dropdown
          :disabled="!this.selected.length"
          class="ml-2"
          variant="primary"
          text="Ação"
          id="acao"
        >
          <b-dropdown-item 
            href="#" 
            id="excluir" 
            data-toggle="modal"
            data-target="#ModalDeleteMultipleGateways"
            >Excluir</b-dropdown-item
          >
          <b-dropdown-item href="#" id="ligarEstado" @click="bulkTurnOnAdm"
            >Ligar Estado Adm.</b-dropdown-item
          >
          <b-dropdown-item href="#" id="desligarEstado" @click="bulkTurnOffAdm"
            >Desligar Estado Adm.</b-dropdown-item
          >
          <!-- "Copiar config"-->
          <div>
            <b-dropdown-item v-if="checkBeforeCopy" href="#" id="copiarConfig"
              @click="copy_config = selected[0], $refs.selectableTable.clearSelected()" data-toggle="" data-target="">Copiar
              Config.</b-dropdown-item>
         </div>

          <!-- "Colar config" -->
          <div>
            <b-dropdown-item v-if="checkBeforePaste" href="#" id="colarConfig" data-toggle="modal"
              data-target="#ModalPasteConfig">Distribuir Config.</b-dropdown-item>
          </div>

        </b-dropdown>

      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import TwInput from "@/components/TwInput.vue";

export default {
  name: "ManagerGateway",
  components: {
    Modal,
    TwInput,
  },
  data() {
    return {
      copy_config: null,
      filter: null,
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortBy: "",
      gatewayDelete: "",
      gatewayChange: "",
      customer: null,
      fields: [
        { key: "gateway_name", sortable: true, label: "Nome" },
        { key: "customer_name", sortable: true, label: "Cliente" },
        { key: "gateway_model", sortable: true, label: "Modelo" },
        { key: "gateway_type", sortable: true, label: "Tipo" },
        { key: "serial_number", sortable: true, label: "Serial" },
        { key: "mac_address", sortable: true, label: "Identificador" },
        { key: "gateway_config", label: "Configuração", sortable: true },
        { key: "gateway_state.operational_state", label: "Status", class: "status", sortable: true},
        { key: "actions", label: "Ações", class: "actions" },
      ],
      selected: [],
    };
  },
  mounted() {
    this.$store.dispatch("gateway/get");
    this.$store.dispatch("customer/get");
  },
  methods: {
    bulkPasteConfig() {
      this.$store.dispatch("gateway/bulkPasteConfig", { selected: this.selected, copy_config: this.copy_config });
      this.$refs.selectableTable.clearSelected();
      this.selected = [];
    },
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(gateways) {
      this.rows = gateways.length;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    stateAdministrative() {
      //("ESTADO ADMINISTRATIVO",this.gatewayChange)

      this.gatewayChange.gateway_state.administrative_state =
        this.gatewayChange.gateway_state.administrative_state === "IN-SERVICE"
          ? "OUT-OF-SERVICE"
          : "IN-SERVICE";

      this.$store.dispatch("gateway/updateGateway", this.gatewayChange);
    },
    async deleteGateway() {
      event.preventDefault();
      //First get the gateway status
      // then we check on the watcher if there's a geofence and a customer id.
      await this.$store.dispatch("gateway/getStatusGateway", this.gatewayDelete);
    },
    async bulkTurnOffAdm() {
      let previouslySelected = this.selected; // Backup variable of the number of selected rows.

      // Filter to get only the selected gateways that are on
      this.selected = this.selected.filter(
        (selected) => selected.gateway_state.administrative_state === "IN-SERVICE"
      );

      if(this.selected.length == 0){
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "nenhum Coletor selecionado está ligado",
          }
        );
        this.selected = previouslySelected; // Restore the number of selected rows.
        return;
      }

      this.selected.forEach((selected) => {
        selected.gateway_state.administrative_state = "OUT-OF-SERVICE";
      });

      await this.$store.dispatch("gateway/bulkSaveGateways", this.selected);
    },
    async bulkTurnOnAdm() {
      let previouslySelected = this.selected; // Backup variable of the number of selected rows.

      // Filter to get only the selected gateways that are off
      this.selected = this.selected.filter(
        (selected) => selected.gateway_state.administrative_state === "OUT-OF-SERVICE"
      );

      if(this.selected.length == 0){
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "nenhum Coletor selecionado está desligado",
          }
        );
        this.selected = previouslySelected; // Restore the number of selected rows.
        return;
      }
      
      this.selected.forEach((selected) => {
        selected.gateway_state.administrative_state = "IN-SERVICE";
      });

      await this.$store.dispatch("gateway/bulkSaveGateways", this.selected);
    },
    async bulkDelete() {
      await this.$store.dispatch("gateway/bulkDeleteGateways", this.selected);
    },
  },
  watch: {
    gateways() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.gateways.length;
          return;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.gateways.length;
    },
    // Checks for any changes in the state variable
    // and then tries to delete the config first
    gatewayStatus(){
      if (this.gatewayStatus.customer_id === null && this.gatewayStatus.geofences === null) {
        this.$store.dispatch("gateway/deleteGateway", this.gatewayDelete);
      }
      else{
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "Coletores com Geofences ou Clientes associados não podem ser excluídos",
          }
        );
      }
    },
    // After the config is deleted, we delete the gateway
    deletedConfig(){
      this.$store.dispatch("gateway/deleteGateway", this.gatewayDelete);
    }
  },
  computed: {
    checkBeforeCopy(){
      return this.selected.length == 1 && this.selected[0].gateway_config != null;
    },
    checkBeforePaste(){
      if(this.copy_config == null) return false;
      if(this.selected.length == 1 && this.copy_config == this.selected[0]) return false;
      return !(this.selected.includes(this.copy_config));
    },
    bulkLoad() {
      return this.$store.getters["gateway/bulkLoad"];
    },
    bulkErrors() {
      return this.$store.getters["gateway/bulkErrors"];
    },
    load() {
      return this.$store.getters["load"];
    },
    gateways() {
      let filteredGateways = [];
      if (this.customer !== null) {
        filteredGateways = this.$store.getters["gateway/gateways"].filter(
          (gateway) => {
            return gateway.customer_id === this.customer;
          }
        );
        return filteredGateways;
      } else {
        filteredGateways = this.$store.getters["gateway/gateways"];
      }
      filteredGateways.forEach((element) => {
        const index = this.customers.findIndex((customer) => {
          return customer.value === element.customer_id;
        });
        if (index > 0)
          element.customer_name = this.customers[index]?.text || "";
      });
      return filteredGateways;
    },
    customers() {
      let customers = this.$store.getters["customer/customers"].map(
        (customer) => {
          return {
            value: customer.id,
            text: customer.company_name,
          };
        }
      );
      customers = [{ value: null, text: "TODOS" }, ...customers];
      return customers;
    },
    gatewayStatus() {
      return this.$store.getters["gateway/gatewayStatus"];
    },
    deletedConfig() {
      return this.$store.getters["gateway/deletedConfig"];
    },
  },
};
</script>

<style>
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
</style>
