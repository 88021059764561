<template>
    <div data-dismiss="modal" aria-label="Close">
      <Modal
        id="deleteMonitoringModal"
        title="Excluir Agendamento"
        :msg="'Tem certeza que deseja excluir o Agendamento?'"
        button="Excluir"
        style="z-index: 2000;"
        :method="deleteExclusion"
      />
      
      <!-- Modal -->
      <div
        class="modal fade"
        id="modalMonitoringExclusion"
        tabindex="-1"
        aria-labelledby="id"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-width"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Excluir Horários do Agendamento
              </h5>
              <button
                id="ast-config-close-modal"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" id="ast-config-close-modal-text"
                  >&times;</span
                >
              </button>
            </div>
  
              <div class="modal-body modal-height">
                <div class="col-12 col-sm-12 p-0 col-md-12">
                  <div class="inside-card">
                    <div class="row">
                      <TwInput label="Busca" col_lg="12">
                        <b-input-group style="display: flex" >
                          <b-input-group-prepend is-text
                            ><i class="fas fa-search"></i>
                          </b-input-group-prepend>
                          <input
                            v-model="filter"
                            type="search"
                            class="form-control"
                            id="filterInputExclusion"
                            placeholder="Buscar"
                          />
                        </b-input-group>
                      </TwInput>
                    </div>
                    <div>
                      <b-table
                        striped
                        hover
                        :filter="filter"
                        :items="itemsExclusion"
                        :fields="fieldsExclusion"
                        ref="exclusionTable"
                      >
                        <template v-slot:table-colgroup>
                          <col
                            v-for="field in fieldsExclusion"
                            :key="field.key"
                            :style="{
                              width: field.key === 'actions' ? '90px' :
                                    field.key !== 'frequency' ? '220px' :
                                    '550px',
                            }"
                          />
                        </template>
                        <template
                          v-slot:cell(start_date)="data"
                        >
                          <div v-if="data.item.action">
                            <b-form-input
                              type="date"
                              v-model="data.item.start_date"
                              placeholder="Instrutor"
                              maxlength="64"
                              :id="`monitoringExclusionStartDate${data.index}`"
                              :class="data.item.errors?.start_date ? 'error-input' : ''"
                              :min="'1900-01-01'"
                              :max="'2099-12-31'"
                            />
                            <div v-if="data.item.errors?.start_date" class="error-message">
                              {{ data.item.errors?.start_date }}
                            </div>
                          </div>
                          <div v-else style="padding: 5px">
                            {{ formatDateAPI(data.item.start_date) }}
                          </div>
                        </template>
                        <template
                          v-slot:cell(stop_date)="data"
                        >
                          <div v-if="data.item.action">
                            <b-form-input
                              type="date"
                              v-model="data.item.stop_date"
                              placeholder="Instrutor"
                              maxlength="64"
                              :id="`monitoringExclusionStopDate${data.index}`"
                              :class="data.item.errors?.stop_date ? 'error-input' : ''"
                              :min="'1900-01-01'"
                              :max="'2099-12-31'"
                            />
                            <div v-if="data.item.errors?.stop_date" class="error-message">
                              {{ data.item.errors?.stop_date }}
                            </div>
                          </div>
                          <div v-else style="padding: 5px">
                            {{ formatDateAPI(data.item.stop_date) }}
                          </div>
                        </template>
                        <template v-slot:cell(frequency)="data">
                          <div v-if="data.item.action">
                            <div style="display: flex">
                              <input
                                class="form-control mr-1"
                                :class="data.item.errors?.interval_of_repetition ? 'error-input' : ''"
                                :id="`monitoringExclusionRepeatInterval${data.index}`"
                                style="width: 60px"
                                type="number"
                                min="1"
                                max="15"
                                v-model="data.item.interval_of_repetition"
                              />
                              <b-form-select
                                style="width: 100px"
                                class="mr-3"
                                :id="`monitoringExclusionIntervalOfRepetition${data.index}`"
                                v-model="data.item.type_of_interval"
                              >
                                <option value="DIA">
                                  Dias
                                </option>
                                <option value="SEMANA">
                                  Semanas
                                </option>
                                <option value="MES">
                                  Meses
                                </option>
                                <option value="ANO">
                                  Anos
                                </option>
                              </b-form-select>
                              <WeekDays
                                v-if="data.item.type_of_interval === 'SEMANA'"
                                :selectedDays="data.item.week_days"
                                size="sm"
                                @updateSelectedDays="(newVector) => toggleWeekDays(data.item, newVector)"
                              />
                            </div>
                            <div v-if="data.item.errors?.interval_of_repetition" class="error-message">
                              {{ data.item.errors?.interval_of_repetition }}
                            </div>
                          </div>
                          <div v-else>
                            <div>
                              Excluir a cada <span style="font-weight: bold;">{{ data.item.interval_of_repetition }}</span>
                              <span style="font-weight: bold;"> {{ data.item.type_of_interval }}</span>
                            </div>
                            <div v-if="data.item.type_of_interval === 'SEMANA'">
                              {{ weekDaysString(data.index) }}
                            </div>
                          </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                          <b-button
                            v-cant="['USER']"
                            @click="selectExclusion(data)"
                            data-toggle="modal"
                            :id="`deleteButton${data.index}`"
                            :ref="`deleteButton${data.index}`"
                            title="Excluir exceção"
                            :data-target="
                              data.item.action !== 'new' ? '#deleteMonitoringModal' : ''
                            "
                          >
                            <i class="far fa-trash-alt"></i>
                          </b-button>
                        </template>
                        <template #cell(justification)="data">
                            <b-button
                              data-toggle="modal"
                              :id="`justificationButton${data.index}`"
                              :ref="`justificationButton${data.index}`"
                              :class="data.item.errors?.justification ? 'error-input button-errorg' : ''"
                              :title="`${data.detailsShowing ? 'Esconder' : 'Mostrar'} motivo da exceção`"
                              :variant="`${(!data.item.action && !data.item?.justification) ? '' : 'primary'}`"
                              :disabled="!data.item.action && !data.item.justification"
                              @click="data.toggleDetails"
                            >
                              <i v-if="data.detailsShowing" class="fa-solid fa-chevron-up"></i>
                              <i v-else class="fa-solid fa-chevron-down"></i>
                            </b-button>
                        </template>
                        <template #row-details="data">
                          <div v-if="data.item.action">
                            <input
                              class="form-control mr-1"
                              type="text"
                              placeholder="Motivo da exceção"
                              v-model="data.item.justification"
                              :id="`justificationText${data.index}`"
                              :class="data.item.errors?.justification ? 'error-input' : ''"
                              maxlength="256"
                            >
                            <div v-if="data.item.errors?.justification" class="error-message">
                              {{ data.item.errors?.justification }}
                            </div>
                          </div>
                          <div v-else>
                            {{ data.item.justification }}
                          </div>
                        </template>
                        <template #table-caption>
                          <div style="display: flex; justify-content: center;" v-cant="['USER']">
                            <b-button
                            id="addRowButtons"
                              class="btn btn-primary"
                              @click="addRow()"
                            >
                              <i class="fa fa-add"></i>
                            </b-button>
                          </div>
                        </template>
                      </b-table>
                    </div>
                    <div style="display: flex; justify-content: flex-end;">
                      <b-button
                        v-cant="['USER']"
                        variant="primary"
                        @click="saveExclusions()"
                        :data-dismiss="this.isAllValid ? 'modal' : ''"
                        aria-label="Close"
                        id="saveExclusionsButton"
                      >
                        Salvar
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
</template>
    
    <script>
    import TwInput from "@/components/TwInput.vue";
    import { formatDate, formatDateAPI } from "../../utils/format-fields";
    import Modal from "../../components/Modal.vue";
    import WeekDays from "../../components/WeekDays.vue";
    
    export default {
      name: "MonitoringExclusionModal",
      props: {
        entireMonitoring: {
          type: Object,
          required: false,
        },
      },
      components: {
        TwInput,
        Modal,
        WeekDays,
      },
      data: function () {
        return {
          filter: null,
          trainingDelete: "",
          perPage: 10,
          currentPage: 1,
          rows: 1,
          filteredTraining: [],
          selectedExclusion: {},
          sendExclusions: [],
          sortByTrainings: "instructor",
          customerId: 0,
          fieldsExclusion: [
            { key: "start_date", sortable: true, label: "Data de Início" },
            { key: "stop_date", sortable: true, label: "Data de Término" },
            { key: "frequency", label: "Frequência" },
            { key: "justification", label: "Motivo" },
            { key: "actions", label: "Ações" },
          ],
          itemsExclusion: [],
          newExclusionId: 0,
          isAllValid: true,
        };
      },
      watch: {
        entireMonitoring() {
          if (this.entireMonitoring){
            this.$store.dispatch("monitoring/getMonitoringExclusions", {
              schedule_id: this.entireMonitoring.id
            })
          }
        },
        exclusions(){
          this.itemsExclusion.length = 0;

          this.exclusions.map((exclusion) => {
            this.itemsExclusion = [...this.itemsExclusion, {...exclusion}]
          })

          this.exclusions?.map((exclusion) => {
            exclusion.errors = {
              start_date: "",
              stop_date: "",
              interval_of_repetition: "",
              justification: "",
            }
          })

          this.$refs.exclusionTable.refresh()
        }
      },
      methods: {
        addRow() {
          document.activeElement.blur(); /* focus out the element (macumba) */

          this.itemsExclusion.push({
            interval_of_repetition: null,
            start_date: "",
            stop_date: "",
            type_of_interval: "SEMANA",
            week_days: [],
            justification: "",
            action: "new",
            errors: {
              start_date: "",
              stop_date: "",
              interval_of_repetition: "",
              justification: "",
            },
            exclusionId: this.newExclusionId
          });

          this.newExclusionId++;
        },
        removeRow() {
          this.itemsExclusion.splice(this.selectedExclusion.exclusionIndex, 1);
        },
        selectExclusion(data){
          this.selectedExclusion = {
            exclusionData: data.item,
            exclusionIndex: data.index
          }

          const button = this.$refs[`deleteButton${data.index}`]
          if ((this.selectedExclusion.exclusionData.action && this.selectedExclusion.exclusionData.action === "new")){
            button.setAttribute("data-target", "")
            this.removeRow();
            return;
          }

          button.setAttribute("data-target", "#deleteMonitoringModal")
        },
        toggleWeekDays(exclusionData, newVector){
          exclusionData.week_days = newVector;
        },
        saveExclusions(){
          let sendExclusions = [];
          this.isAllValid = true;

          this.itemsExclusion.forEach((exclusion) => {
            if(exclusion.action === "new"){
              exclusion.justification = exclusion.justification?.trim();
              if(!this.isExclusionValid(exclusion)) {
                this.isAllValid = false; 
              }

              else {
                sendExclusions = [...sendExclusions, {...exclusion}]
              }
            }
          })

          if (sendExclusions.length > 0 && this.isAllValid){
            sendExclusions.forEach((exclusion) => {
              delete exclusion.action
              delete exclusion.errors
              delete exclusion.exclusionId
              delete exclusion._showDetails
            })
            // exclusion.interval_of_repetition = parseInt(exclusion.interval_of_repetition)
              
            this.$store.dispatch("monitoring/createMonitoringExclusion", {
              schedule_id: this.entireMonitoring.id,
              exclusion_list: sendExclusions
            }).then(() => {
              this.toggleMonitoring()
            })
          }
        },
        isExclusionValid(exclusion){
          let valid = true;
          exclusion.errors = {
            start_date: "",
            stop_date: "",
            interval_of_repetition: "",
          }

          if(exclusion.justification?.length > 256){
            exclusion.errors.justification = "Motivo não pode ter mais do que 256 caracteres."
            valid = false;
          }

          if (!exclusion.start_date){
            exclusion.errors.start_date = "Data de início não pode ser vazia.";
            valid = false;
          }

          if (!exclusion.stop_date){
            exclusion.errors.stop_date = "Data de fim não pode ser vazia.";
            valid = false;
          }

          if (!exclusion.interval_of_repetition){
            exclusion.errors.interval_of_repetition = "Intervalo de repetição não pode ser vazio.";
            valid = false;
          }

          else if (exclusion.interval_of_repetition < 1){
            exclusion.errors.interval_of_repetition = "Intervalo de repetição deve ser um valor positivo.";
            valid = false;
          }

          if (new Date(exclusion.start_date) > new Date(exclusion.stop_date)){
            exclusion.errors.start_date = "Data de início não pode ser posterior à data de fim.";
            exclusion.errors.stop_date = "Data de fim não pode ser anterior à data de início.";
            valid = false;
          }

          if (new Date(exclusion.start_date) < new Date(formatDate(this.entireMonitoring.start_date.split(" ")[0]))){
            exclusion.errors.start_date = "Data de início deve estar dentro do intervalo de agendamento.";
            valid = false;
          }

          if (this.entireMonitoring.stop_date && 
              (new Date(exclusion.stop_date) > new Date(formatDate(this.entireMonitoring.stop_date)))
          ){
            exclusion.errors.stop_date = "Data de fim deve estar dentro do intervalo de agendamento.";
            valid = false;
          }

          if (
            !exclusion.errors.start_date &&
            !exclusion.errors.stop_date &&
            !exclusion.errors.interval_of_repetition
          ) {
            this.itemsExclusion.forEach((otherExclusion) => {
              if (
                exclusion.interval_of_repetition == otherExclusion.interval_of_repetition &&
                formatDateAPI(exclusion.start_date) === formatDateAPI(otherExclusion.start_date) &&
                formatDateAPI(exclusion.stop_date) === formatDateAPI(otherExclusion.stop_date) &&
                exclusion.type_of_interval === otherExclusion.type_of_interval
              ){
                if (otherExclusion.action && exclusion.exclusionId !== otherExclusion.exclusionId){
                  otherExclusion.errors.start_date = "Este agendamento já está na lista para ser cadastrado.";
                  otherExclusion.errors.stop_date = " ";
                  otherExclusion.errors.interval_of_repetition = " ";
                  exclusion.errors.start_date = "Este agendamento já está na lista para ser cadastrado.";
                  exclusion.errors.stop_date = " ";
                  exclusion.errors.interval_of_repetition = " ";
                  valid = false;
                }

                else if (!otherExclusion.action){
                  exclusion.errors.start_date = "Este agendamento já foi cadastrado.";
                  exclusion.errors.stop_date = " ";
                  exclusion.errors.interval_of_repetition = " ";
                  valid = false;
                }
              }
            })
          }

          return valid;
        },
        deleteExclusion(){
          if (this.selectedExclusion.exclusionData && this.selectedExclusion.exclusionDataData?.action !== "new"){
            delete this.selectedExclusion.exclusionData.action;
            delete this.selectedExclusion.exclusionData.errors;

            this.$store.dispatch("monitoring/deleteMonitoringExclusion", {
              monitoring_id: this.entireMonitoring.id,
              exclusion_id: this.selectedExclusion.exclusionData.id,
            })

            this.removeRow();
          }
        },
        toggleMonitoring(){
          this.$emit('clearMonitoringTarget', true)
        },
        formatDateAPI,
        weekDaysString(id){
          const weekDaysObj = [
            "Segundas-Feiras",
            "Terças-Feiras",
            "Quartas-Feiras",
            "Quintas-Feiras",
            "Sextas-Feiras",
            "Sábados",
            "Domingos"
          ]

          if (!this.itemsExclusion[id].week_days || this.itemsExclusion[id].week_days?.length === 0){
            return "";
          }

          else if (this.itemsExclusion[id].week_days?.length === 7){
            return "Todos os dias."
          }

          else {
            const noWeekendDaysLength = this.itemsExclusion[id].week_days.filter((day) => day < 5).length

            let phrase = noWeekendDaysLength > 0 ? "Todas as " : "Todos os "

            for (let i in this.itemsExclusion[id].week_days){
              if (
                  i == this.itemsExclusion[id].week_days.length - 1 && 
                  this.itemsExclusion[id].week_days.length > 1
              ){
                phrase = phrase + ' e ';
              }

              else if (i > 0) {
                phrase = phrase + ', ';
              }
              
              phrase = phrase + weekDaysObj[this.itemsExclusion[id].week_days[i]]
            }
            
            return phrase + ".";
          }
        },
      },
      mounted() {
        let modal = document.getElementById("modalMonitoringExclusion");
        let closeButton = document.getElementById("ast-config-close-modal");
        let closeButtonText = document.getElementById(
          "ast-config-close-modal-text"
        );
    
        window.onclick = (event) => {
          if (
            event.target == modal ||
            event.target == closeButton ||
            event.target == closeButtonText
          ) {
            // this.toggleOpened();
          }
        };
      },
      computed: {
        exclusions(){
          return this.$store.getters["monitoring/exclusions"];
        }
      }
    };
    </script>
    <style>
      .modal-width{
        max-width: 1000px !important;
        
      }
      
      .modal-height{
        max-height: 800px !important;
        overflow-y: auto;
      }
  
      .inside-card{
        padding: 15px;
      }

      .error-message{
        font-size: 10px;
        color: red;
        padding-left: 3px;
      }

      .error-input{
        border: solid 2px red;
      }

      .error-input.button-error{
        color: red;
      }
    </style>
    