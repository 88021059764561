<template>
    <div style="display: flex; align-items: center;">
        <button type="button" 
            v-for="day in weekDays"
            :key="day.id"
            :class="`days-${size} ${changedSelectDays.includes(day.id) ? 'selected' : 'unselected'}`"
            @click="toggleSelectDay(day.id)"
            :title="`${changedSelectDays.includes(day.id) ? 'Desmarcar' : 'Marcar'} ${day.day}`"
        >
            {{ day.label }}
        </button>
    </div>
</template>

<script>
export default {
    name: "WeekDays",
    props: {
        selectedDays: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            required: true,
        }
    },
    data: function (){
        return {
            weekDays: [
                {
                    id: 6,
                    label: "D",
                    day: "Domingo",
                },
                {
                    id: 0,
                    label: "S",
                    day: "Segunda-Feira"
                },
                {
                    id: 1,
                    label: "T",
                    day: "Terça-Feira"
                },
                {
                    id: 2,
                    label: "Q",
                    day: "Quarta-Feira"
                },
                {
                    id: 3,
                    label: "Q",
                    day: "Quinta-Feira"
                },
                {
                    id: 4,
                    label: "S",
                    day: "Sexta-Feira"
                },
                {
                    id: 5,
                    label: "S",
                    day: "Sábado"
                },
            ],
            changedSelectDays: [...this.selectedDays],
        };
    },
    watch: {
        selectedDays(){
            this.changedSelectDays = [...this.selectedDays];
        }
    },
    methods: {
        toggleSelectDay(dayId){
            if (this.changedSelectDays.includes(dayId)){
                this.changedSelectDays.splice(this.changedSelectDays.indexOf(dayId), 1);
            }

            else {
                this.changedSelectDays = [...this.changedSelectDays, dayId];
            }

            this.$emit('updateSelectedDays', this.changedSelectDays)
        },

    }
}
</script>

<style scoped>
    .days-sm{
        width: 30px;
        height: 30px;
    }

    .days-md{
        width: 60px;
        height: 60px;
    }

    .days-sm, .days-md{
        border: none;
        border-radius: 100%;
        color: black;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
    }

    .days-sm.unselected,  .days-md.unselected{
        background-color: #e9ecef;
    }

    .days-sm.unselected:hover,  .days-md.unselected:hover{
        background-color: #c2b1e9;
    }

    .days-sm.selected,  .days-md.selected{
        background-color: #4e01d6;
        color: white;
    }

    .days-sm.selected:hover,  .days-md.selected:hover{
        background-color: #804cde;
    }

    .days-sm.unselected:active,  .days-md.unselected:active,
    .days-sm.selected:active,  .days-md.selected:active{
        background-color: #9c77e3;
    }
</style>