<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Editar coletor</h1>
    </div>

    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card-body">
        <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
          <div class="row mb-2">
            <div class="col-12">
              <div class="card direct-chat direct-chat-primary employee">
                <div class="card-body custom-card overflow-visible">
                  <ul class="nav nav-tabs bg-main">
                    <li class="nav-item">
                      <a
                        id="addGateway-tab"
                        class="nav-link first"
                        v-bind:class="{
                          active: true,
                        }"
                        href="#"
                        aria-current="addGateway"
                        tabindex="-1"
                        >Editar coletor</a
                      >
                    </li>
                  </ul>
                  <div class="p-4 bg-white rounded">
                    <div class="tab-content">
                      <div
                        class="tab-pane fade"
                        v-bind:class="{
                          show: true,
                          active: true,
                        }"
                      >
                        <div class="row">
                          <div class="card-body">
                            <div class="p-4 bg-white rounded">
                              <form @submit="updateGateway" novalidate="true">
                                <div class="col-12">
                                  <div class="row">
                                    <TwInput
                                      label="Nome do coletor"
                                      required="false"
                                    >
                                      <input
                                        id="gatewayName"
                                        v-model="gateway.gateway_name"
                                        type="text"
                                        maxlength="40"
                                        class="form-control"
                                        placeholder="Nome do coletor"
                                      />
                                    </TwInput>
                                    <TwInput
                                      label="Identificador"
                                    >
                                      <input
                                        id="macGateway"
                                        disabled
                                        v-model="gateway.mac_address"
                                        v-mask="'NN:NN:NN:NN:NN:NN'"
                                        type="text"
                                        class="form-control"
                                        placeholder="Identificador do coletor"
                                      />
                                    </TwInput>

                                    <TwInput label="Tipo" required="false">
                                      <select
                                        id="gtw-edit-select-type"
                                        required
                                        class="form-control"
                                        v-model="gateway.gateway_type"
                                      >
                                        <option
                                          v-for="(gateway_type, index) in types"
                                          v-bind:value="gateway_type.name"
                                          v-bind:key="index"
                                          :id="'gtw-edit-opt-' + gateway_type.name + '-' + index"
                                        >
                                          {{ gateway_type.name }}
                                        </option>
                                      </select>
                                    </TwInput>
                                    <TwInput label="Número de série">
                                      <input
                                        id="serialnumberGateway"
                                        v-model="gateway.serial_number"
                                        type="text"
                                        maxlength="30"
                                        class="form-control"
                                        placeholder="Número serial do coletor"
                                      />
                                    </TwInput>
                                  </div>
                                  <div class="row">
                                    <TwInput label="Modelo" required="false">
                                      <select
                                        id="gtw-edit-select-model"
                                        class="form-control"
                                        v-model="gateway.gateway_model"
                                      >
                                        <option
                                          v-for="(model, index) in models"
                                          v-bind:value="model.name"
                                          v-bind:key="index"
                                          :id="'gtw-edit-mopt-' + model.name + '-' + index"
                                        >
                                          {{ model.name }}
                                        </option>
                                      </select>
                                    </TwInput>
                                  </div>
                                </div>
                                <div class="row p-1">
                                  <button
                                    id="saveEditGateway"
                                    class="btn btn-primary px-5"
                                    :disabled="load"
                                  >
                                    <i
                                      v-if="load"
                                      class="fas fa-sync-alt fa-spin"
                                    ></i>
                                    Salvar
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
export default {
  name: "AddGateway",
  data: function () {
    return {
      breadcrumbList: [],
      gateway: {
        gateway_model: "DEFAULT",
        gateway_name: "",
        gateway_type: "MOBILE",
        mac_address: "",
        serial_number: "",
      }
    };
  },
  created() {
    this.mac_address = this.$route.params.mac_address;
    this.updateList();

    this.$store.dispatch("gateway/getModels");
    this.$store.dispatch("gateway/getTypes");
    this.$store.dispatch("gateway/getStatusGateway", {
      mac_address: this.mac_address,
    });
  },
  watch: {
    $route() {
      this.updateList();
    },
    gatewayStatus(){
      this.gateway = this.gatewayStatus;
      localStorage.setItem('gateway',JSON.stringify(this.gateway));
      this.updateList();
    },
  },
  methods: {
    checkForEmpty(strings, msgs){
      for (let i = 0; i < strings.length; i++) {
        if (strings[i].trim() == "") {
          window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: msgs[i] }
        );
          return false;
        } 
      }
      return true;
    },
    isGatewayValid() {
      let strings = [this.gateway.gateway_model, this.gateway.gateway_name, this.gateway.gateway_type, this.gateway.mac_address];
      let msgs = ["Modelo", "Nome", "Tipo", "Identificador"];
      return this.checkForEmpty(strings, msgs);
    },
    updateGateway() {
      event.preventDefault();
      if (!this.isGatewayValid()) {
        return;
      }
      // parse json from localstorage
      let localCache = JSON.parse(localStorage.getItem("gateway"));
      let copyGateway = {
        gateway_model: this.gateway.gateway_model,
        gateway_name: this.gateway.gateway_name,
        gateway_type: this.gateway.gateway_type,
        id: localCache.id,
        mac_address: this.gateway.mac_address,
        serial_number: this.gateway.serial_number,
      };

      this.$store.dispatch("gateway/updateGateway", {
        ...copyGateway,
        mac_address: this.gateway.mac_address.replace(/:|-/g, ""),
      });
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    models() {
      return this.$store.getters["gateway/models"];
    },
    config() {
      return this.$store.getters["gateway/config"];
    },
    types() {
      return this.$store.getters["gateway/types"];
    },
    gateways() {
      return this.$store.getters["gateway/gateways"];
    },
    gatewayStatus() {
      return this.$store.getters["gateway/gatewayStatus"];
    }
  },
};
</script>
<style>
.margin-left-zero {
  margin-left: 0;
}
</style>