<template>
    <div data-dismiss="modal" aria-label="Close">
      <Modal
        id="modalDeleteSchedule"
        title="Excluir Agendamento"
        :msg="'Tem certeza que deseja excluir o Agendamento?'"
        button="Excluir"
        style="z-index: 2000;"
        :method="deleteSchedule"
      />
      
      <!-- Modal -->
      <div
        class="modal fade"
        id="training-schedule-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="id"
        aria-hidden="true"
        style="z-index: 1050"
      >
        <div
          class="modal-dialog modal-width"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Associar Horários ao Treinamento
              </h5>
              <button
                id="ast-config-close-modal"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" id="ast-config-close-modal-text"
                  >&times;</span
                >
              </button>
            </div>
  
              <div class="modal-body modal-height">
                <div class="col-12 col-sm-12 p-0 col-md-12">
                  <div class="inside-card">
                    <div class="row">
                      <TwInput label="Nome" col_lg="6">
                          <input
                            v-model="this.entireTraining.name"
                            type="search"
                            class="form-control"
                            id="trainingName"
                            disabled
                          />
                      </TwInput>
                      <TwInput label="Duração" col_lg="2">
                          <input
                            v-model="this.entireTraining.duration"
                            type="search"
                            class="form-control"
                            id="trainingDuration"
                            disabled
                          />
                      </TwInput>
                    </div>
                    <div class="row">
                      <TwInput label="Busca" col_lg="12">
                        <b-input-group style="display: flex" >
                          <b-input-group-prepend is-text
                            ><i class="fas fa-search"></i>
                          </b-input-group-prepend>
                          <input
                            v-model="filter"
                            type="search"
                            class="form-control"
                            id="filterInputEmployee"
                            placeholder="Buscar"
                          />
                        </b-input-group>
                      </TwInput>
                    </div>
                    <div>
                      <b-table
                        striped
                        hover
                        :filter="filter"
                        :items="schedules"
                        :fields="fieldsSchedules"
                        ref="scheduleTable"
                      >
                      <template v-slot:table-colgroup>
                        <col
                          v-for="field in fieldsSchedules"
                          :key="field.key"
                          :style="{
                            width: field.key === 'actions' ? '90px' : '550px',
                          }"
                        />
                      </template>
                        <template
                          v-slot:cell(instructor)="data"
                        >
                          <div v-if="data.item.action === 'new' || isImporting">
                            <b-form-input
                              type="text"
                              v-model="data.item.instructor"
                              placeholder="Instrutor"
                              maxlength="64"
                              id="trainingInstructorSchedule"
                              :class="data.item.errors.instructor ? 'error-input' : ''"
                            />
                            <div v-if="data.item.errors.instructor" class="error-message">
                              {{ data.item.errors.instructor }}
                            </div>
                          </div>
                          <div v-else style="padding: 5px">
                            {{ data.item.instructor }}
                          </div>
                        </template>
                        <template
                          v-slot:cell(t_start)="data"
                        >
                          <div v-if="data.item.action === 'new' || isImporting" style="display: flex; justify-content: space-between;">
                            <div class="date-input">
                              <b-form-input
                                type="date"
                                v-model="data.item.t_start"
                                id="trainingDateStartSchedule"
                                :min="'1900-01-01'"
                                :max="'2099-12-31'"
                                :required="true"
                                :class="data.item.errors.t_start ? 'error-input' : ''"
                              />
                              <div v-if="data.item.errors.t_start" class="error-message">
                                {{ data.item.errors.t_start }}
                              </div>
                            </div>
                            
                            <div class="date-input">
                              <b-form-input
                                type="text"
                                v-model="data.item.t_start_hour"
                                placeholder="hh:mm:ss"
                                maxlength="9"
                                :required="true"
                                id="trainingTimeStartSchedule"
                                v-mask="'NN:NN:NN'"
                                class="form-control"
                                :class="data.item.errors.t_start_hour ? 'error-input' : ''"
                              />
                              <div v-if="data.item.errors.t_start_hour" class="error-message">
                                {{ data.item.errors.t_start_hour }}
                              </div>
                            </div>
                          </div>
                          <div v-else style="padding: 5px">
                            {{ data.item.t_start }}
                          </div>
                        </template>
                        <template
                          v-slot:cell(t_end)="data"
                        >
                          <div v-if="data.item.action === 'new' || isImporting" style="display: flex; justify-content: space-between;">
                            <div class="date-input">
                              <b-form-input
                                type="date"
                                v-model="data.item.t_end"
                                id="trainingDateEndSchedule"
                                :min="'1900-01-01'"
                                :max="'2099-12-31'"
                                :class="data.item.errors.t_end ? 'error-input' : ''"
                              />
                              <div v-if="data.item.errors.t_end" class="error-message">
                                {{ data.item.errors.t_end }}
                              </div>
                            </div>
                            
                            <div class="date-input">
                              <b-form-input
                                type="text"
                                v-model="data.item.t_end_hour"
                                placeholder="hh:mm:ss"
                                maxlength="256"
                                :required="true"
                                id="trainingTimeEndSchedule"
                                v-mask="'NN:NN:NN'"
                                class="form-control"
                                :class="data.item.errors.t_end_hour ? 'error-input' : ''"
                              />
                              <div v-if="data.item.errors.t_end_hour" class="error-message">
                                {{ data.item.errors.t_end_hour }}
                              </div>
                            </div>
                          </div>
                          <div v-else style="padding: 5px">
                            {{ data.item.t_end }}
                          </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                          <b-button
                            @click="selectSchedule(data)"
                            v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']"
                            data-toggle="modal"
                            :id="`deleteButton${data.index}`"
                            :ref="`deleteButton${data.index}`"
                            :data-target="
                              data.item.action !== 'new' || isImporting ? '#modalDeleteSchedule' : ''
                            "
                          >
                            <i class="far fa-trash-alt"></i>
                          </b-button>
                        </template>
                        <template #table-caption>
                          <div v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']" style="display: flex; justify-content: center;">
                            <b-button
                              class="btn btn-primary"
                              @click="addRow()"
                            >
                              <i class="fa fa-add"></i>
                            </b-button>
                          </div>
                        </template>
                      </b-table>
                    </div>
                    <div style="display: flex; justify-content: flex-end;">
                      <b-button
                        v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']"
                        variant="primary"
                        @click="saveSchedules()"
                        :data-dismiss="this.isAllValid ? 'modal' : ''"
                        aria-label="Close"
                      >
                        Salvar
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
</template>
    
    <script>
    import TwInput from "@/components/TwInput.vue";
    import { formatDate, formatDateAPI } from "../../utils/format-fields";
    import Modal from "../../components/Modal.vue";
    
    export default {
      name: "TrainingScheduleModal",
      props: {
        entireTraining: {
          type: Object,
          required: false,
        },
        isImporting: {
          type: Boolean,
          required: false,
        }
      },
      components: {
        TwInput,
        Modal,
      },
      data: function () {
        return {
          filter: null,
          trainingDelete: "",
          perPage: 10,
          currentPage: 1,
          rows: 1,
          filteredTraining: [],
          selectedSchedule: {},
          sendSchedules: [],
          sortByTrainings: "instructor",
          customerId: 0,
          fieldsSchedules: [
            { key: "instructor", sortable: true, label: "Instrutor" },
            { key: "t_start", sortable: true, label: "Data de início" },
            { key: "t_end", sortable: true, label: "Data de término" },
            { key: "actions", label: "Ações", class: "actions" },
          ],
          schedules: [],
          isAllValid: true,
        };
      },
      watch: {
        entireTraining() {
          this.schedules.length = 0;
          if (this.isImporting){
            let importedTrainings = JSON.parse(JSON.stringify(this.entireTraining))

            importedTrainings.schedules.map((schedule) => {
              let t_start = schedule.t_start?.split(" ");
              let t_end = schedule.t_end?.split(" ");

              schedule.t_start_hour = t_start ? t_start[1] : "";
              schedule.t_start = t_start ? formatDate(t_start[0]) : "";
              
              schedule.t_end_hour = t_end ? t_end[1] : "";
              schedule.t_end = t_end ? formatDate(t_end[0]) : "";

              this.schedules = [...this.schedules, {...schedule}]
            })
          }
          
          else {
            this.entireTraining.schedules.map((schedule) => {
              this.schedules = [...this.schedules, {...schedule}]
            })
          }

          this.schedules.map((schedule) => {
            schedule.errors = {
              instructor: "",
              t_start: "",
              t_end: "",
              t_start_hour: "",
              t_end_hour: "",
            }
          })

          if (this.isImporting) {
            this.entireTraining.errors.schedules = false;
            this.schedules.map((schedule) => {
              if(!this.isScheduleValid(schedule)){
                this.entireTraining.errors.schedules = true;
                this.entireTraining.invalid = true;
              }
            })
          }

          this.customerId = this.$route.params.customerId;
          this.$refs.scheduleTable.refresh()
        },
      },
      methods: {
        addRow() {
          document.activeElement.blur(); /* focus out the element (macumba) */

          this.schedules.push({
            instructor: "",
            t_start: "",
            t_end: "",
            t_start_hour: "",
            t_end_hour: "",
            action: "new",
            errors: {
              instructor: "",
              t_start: "",
              t_end: "",
              t_start_hour: "",
              t_end_hour: "",
            }
          });
        },
        isScheduleValid(training) {
          training.errors = {
            instructor: "",
            t_start: "",
            t_end: "",
            t_start_hour: "",
            t_end_hour: "",
          }
          let valid = true;
          let schedule_regex = /^(([0-1]\d)|2[0-3])(:[0-5]\d){2}$/;

          if (training.action === "new" || this.isImporting){
            if(training.instructor){
              training.instructor = training.instructor.trim();
            }
            if (!training.instructor){
              training.errors.instructor = "Instrutor não pode ser vazio.";
              valid = false;
            }

            if (!training.t_start_hour?.trim()){
              training.errors.t_start_hour = "Horário não pode ser vazio.";
              valid = false;
            }

            else if (!schedule_regex.test(training.t_start_hour)) {
              training.errors.t_start_hour = "Formato de horário inválido.";
              valid = false;
            }

            if (!training.t_end_hour?.trim()){
              training.errors.t_end_hour = "Horário não pode ser vazio.";
              valid = false;
            }

            else if (!schedule_regex.test(training.t_end_hour)) {
              training.errors.t_end_hour = "Formato de horário inválido.";
              valid = false;
            }

            if (!training.t_start?.trim()){
              training.errors.t_start = "Data não pode ser vazia.";
              valid = false;
            }

            if (!training.t_end?.trim()){
              training.errors.t_end = "Data não pode ser vazia.";
              valid = false;
            }

            if (
                !training.errors.instructor &&
                !training.errors.t_start &&
                !training.errors.t_end &&
                !training.errors.t_start_hour &&
                !training.errors.t_end_hour
            ){
              let startDate = new Date(`${training.t_start} ${training.t_start_hour}`);
              let endDate = new Date(`${training.t_end} ${training.t_end_hour}`);

              if (startDate > endDate){
                training.errors.t_start = "Data e horário de início não podem ser posterior à data de fim.";
                training.errors.t_end = "Data e horário de fim não podem ser anterior à data de início.";
                valid = false;
              }
              //A diferença entre duas datas é retornada em TS, em milissegundos, por isso se é necessario multiplicar duration, que é em horas, por 3.600.000
              if(endDate - startDate < this.entireTraining.duration * 3600 * 1000){
                training.errors.t_start = "Intervalo especificado é incompativel com a duração do treinamento"
                training.errors.t_end = "Intervalo especificado é incompativel com a duração do treinamento"
                valid = false;
              }

              if (startDate > new Date("2099-12-31T23:59:59.999")){
                training.errors.t_start = "Data inicial acima da data maxima permitida";
                valid = false;
              }

              if (endDate > new Date("2099-12-31T23:59:59.999")){
                training.errors.t_end = "Data final acima da data maxima permitida";
                valid = false;
              }

              let repeatedExistenceSchedules = this.entireTraining.schedules?.filter((existenceSchedule) => 
                  existenceSchedule.instructor === training.instructor &&
                  existenceSchedule.t_start === `${formatDateAPI(training.t_start)} ${training.t_start_hour}` &&
                  existenceSchedule.t_end === `${formatDateAPI(training.t_end)} ${training.t_end_hour}`
              )

              let repeatedNewSchedules = this.sendSchedules?.filter((otherSchedule) => 
                otherSchedule.instructor === training.instructor &&
                otherSchedule.t_start === training.t_start &&
                otherSchedule.t_start_hour === training.t_start_hour &&
                otherSchedule.t_end === training.t_end && 
                otherSchedule.t_end_hour === training.t_end_hour
              )

              if(repeatedExistenceSchedules.length > 1){
                if (!this.isImporting){
                  training.errors.instructor = "Este agendamento já foi cadastrado!";
                }
                else {
                  training.errors.instructor = "Este agendamento já está na lista para ser cadastrado!";
                }
                valid = false;
              }

              else if (repeatedNewSchedules.length > 0){
                repeatedNewSchedules.map((newSchedule) => {
                  newSchedule.errors.instructor = "Este agendamento já está na lista para ser cadastrado!";
                })
                
                training.errors.instructor = "Este agendamento já está na lista para ser cadastrado!";
                valid = false;
              }
            }
          }

          this.$refs.scheduleTable.refresh();
          return valid;
        },
        removeRow() {
          this.schedules.splice(this.selectedSchedule.scheduleIndex, 1);
        },
        selectSchedule(data){
          this.selectedSchedule = {
            scheduleData: data.item,
            scheduleIndex: data.index
          }

          const button = this.$refs[`deleteButton${data.index}`]
          if ((this.selectedSchedule.scheduleData.action && this.selectedSchedule.scheduleData.action === "new") || this.isImporting){
            button.setAttribute("data-target", "")
            this.removeRow();
            return;
          }

          button.setAttribute("data-target", "#modalDeleteSchedule")
        },
        saveSchedules(){
          this.isAllValid = true;

          if (this.isImporting){
            this.entireTraining.schedules.length = 0;

            this.schedules.map((schedule) => {
              schedule.instructor = schedule.instructor?.toUpperCase()
              if (!this.isScheduleValid(schedule)){
                this.isAllValid = false;
              }
              
              this.entireTraining.schedules.push({
                instructor: schedule.instructor,
                t_start: `${formatDateAPI(schedule.t_start)} ${schedule.t_start_hour}`,
                t_end: `${formatDateAPI(schedule.t_end)} ${schedule.t_end_hour}`,
                errors: {
                  ...schedule.errors,
                }
              })
            })

            this.entireTraining.errors.schedules = !this.isAllValid;
            this.entireTraining.invalid = false;

            for (let error in this.entireTraining.errors){
              if (this.entireTraining.errors[error]){
                this.entireTraining.invalid = true;
                break;
              }
            }

            this.$attrs.tableRef.refresh()
            return;
          }
          
          this.sendSchedules.length = 0;

          this.schedules.map((schedule) => {
            schedule.instructor = schedule.instructor?.toUpperCase()
            if(schedule.action){
              if (this.isScheduleValid(schedule)){
                this.sendSchedules = [...this.sendSchedules, {...schedule}];
              }
              
              else {
                this.isAllValid = false;
              }
            }
          })

          if (this.sendSchedules.length > 0 && this.isAllValid){
            this.sendSchedules.map((schedule) => {
              if (schedule.action === "new"){
                schedule.t_start = `${formatDateAPI(schedule.t_start)} ${schedule.t_start_hour}`;
                schedule.t_end = `${formatDateAPI(schedule.t_end)} ${schedule.t_end_hour}`;

                delete schedule.t_start_hour;
                delete schedule.t_end_hour;
              }

              delete schedule.action;
              delete schedule.errors;
              
              schedule.training_id = this.entireTraining.id;
            })

            this.$store.dispatch("training/saveTrainingSchedules", {
              customer_id: this.customerId,
              schedules: this.sendSchedules
            })

            this.schedules.map((schedule) => {
              if (schedule.action){
                schedule.t_start = `${formatDateAPI(schedule.t_start)} ${schedule.t_start_hour}`;
                schedule.t_end = `${formatDateAPI(schedule.t_end)} ${schedule.t_end_hour}`;
              }
              delete schedule.action;
            })
          }

          else if (this.sendSchedules.length === 0 && this.isAllValid){
            window.frontMsg(
              "FRNT_ACTION_WARNING",
              {
                reason: "nenhuma adição de agendamento foi realizada",
              }
            );
          }

          else{
            window.frontMsg(
              "FRNT_INVALID_FIELD_WARNING",
              {
                field: "Dados dos Agendamentos",
              }
            );
          }
        },
        deleteSchedule(){
          if (this.selectedSchedule.scheduleData && this.selectedSchedule.scheduleData.action !== "new"){
            delete this.selectedSchedule.scheduleData.action;
            delete this.selectedSchedule.scheduleData.errors;
            delete this.selectedSchedule.scheduleData.t_end_hour;
            delete this.selectedSchedule.scheduleData.t_start_hour;

            this.selectedSchedule.scheduleData.training_id = this.entireTraining.id;

            this.$store.dispatch("training/deleteTrainingSchedules", {
              customer_id: this.customerId,
              schedules: [this.selectedSchedule.scheduleData]
            })

            this.removeRow();
          }
        },
      },
      computed: {
        messagesReverse(){
          return this.messages.slice().reverse();
        },
      },
      mounted() {
        let modal = document.getElementById("import-asset-config-modal");
        let saveButton = document.getElementById("saveEditAsset");
        let closeButton = document.getElementById("ast-config-close-modal");
        let closeButtonText = document.getElementById(
          "ast-config-close-modal-text"
        );
    
        window.onclick = (event) => {
          if (
            event.target == modal ||
            event.target == saveButton ||
            event.target == closeButton ||
            event.target == closeButtonText
          ) {
            this.closeModalVerifies();
          }
        };
      },
    };
    </script>
    <style>
      .modal-width{
        max-width: 1000px !important;
        
      }
      
      .modal-height{
        max-height: 800px !important;
        overflow-y: auto;
      }
      .card-background{
        background-color: #D7C9F1;
      }
  
      .inside-card{
        padding: 15px;
      }
  
      .empty-chatbox{
        height: 100%; 
        display: flex; 
        justify-content: center; 
        align-items: center;
      }
  
      .message-area{
        display: flex; 
        justify-content: space-between;
      }
  
      .text-box{
        resize: none; 
        background-color: #FFF; 
        width: 96%; 
        height: 40px; 
        overflow-y: auto; 
        padding: 8px; 
        box-sizing: border-box; 
        margin-bottom: 3px
      }
  
      .send-message-button{
        padding: 0; 
        height: 40px; 
        width: 40px; 
        border-radius: 100%;
      }

      .error-message{
        font-size: 10px;
        color: red;
        padding-left: 3px;
      }

      .error-input{
        border: solid 2px red;
      }
      .date-input{
        width: 49.5%;
      }
    </style>
    