// eslint-disable-next-line vue/script-setup-uses-vars
<template>
  <div
    id="total"
    class="login-body font"
    v-bind:class="{ signinjs: isActive, signupjs: isOk }"
  >
    <div id="flogin-container" class="container-login">
      <div id="flogin-second-con" class="content second-content">
        <div id="flogin-first-col" class="first-column">
          <img
            id="flogin-logo"
            :src="require('../../assets/img/TW-yellow.svg')"
            alt="Logotipo Truework"
            style="width: 300px"
          />
          <p id="flogin-desc" class="description description-primary mt-3">
            We make ideas happen!
          </p>
          <div>
            <router-link to="/login" id="flogin-back-but">
              <button id="flogin-return-but" class="btn-login btn-login-primary">Voltar</button>
            </router-link>
          </div>
        </div>
        <div id="flogin-second-column" class="second-column">
          <h2 id="flogin-title-second" class="title title-second">Nova senha</h2>
          <p id="flogin-expired-pass" style="color: red">Senha expirada, redefina uma nova senha</p>
          <form id="flogin-login-form" @submit="firstlogin" class="form mt-2 card">
            <div id="flogin-form-gp" class="form-group pass-show">
              <p id="flogin-email-p">Email:</p>
              <label id="flogin-label-for-form" class="label-input" for="">
                <input
                  id="email"
                  v-bind:style="
                    bad_email
                      ? 'border: 1px solid red;'
                      : 'height: 45px; width: 100%'
                  "
                  placeholder="Email"
                  class="formzinho form-control"
                  required
                  v-model="email"
                  style="height: 45px; width: 100%"
                />
              </label>
            </div>
            <div id="flogin-form-gp2" class="form-group pass-show">
              <p id="flogin-senha-p">Senha atual:</p>
              <label id="flogin-label-for-oldp" class="label-input" for="">
                <input
                  name="old_password"
                  id="old_password"
                  placeholder="Senha atual"
                  class="formzinho form-control"
                  v-bind:style="
                    bad_email
                      ? 'border: 1px solid red;'
                      : 'height: 45px; width: 100%'
                  "
                  v-model="old_password"
                  :type="!passShow ? 'password' : 'text'"
                  style="height: 45px; width: 100%"
                />
                <a id="flogin-show-pass" @click="showoldPass" href="/" class="icon2">
                  <i
                    id="flogin-open-eye"
                    v-bind:class="{
                      'far fa-eye': passShow,
                      'far fa-eye-slash': !passShow,
                    }"
                  ></i>
                </a>
              </label>
            </div>

            <div id="flogin-form-gp-newpass" class="form-group pass-show">
              <p id="flogin-new-pass-p">Nova senha:</p>
              <label id="flogin-label-newpass" class="label-input" for="">
                <input
                  name="new_password"
                  id="new_password"
                  v-bind:style="
                    new_passwordError && !bad_email
                      ? 'border: 1px solid red;'
                      : 'height: 45px; width: 100%'
                  "
                  placeholder="Nova senha"
                  class="formzinho form-control"
                  required
                  v-model="new_password"
                  :type="!passShow2 ? 'password' : 'text'"
                  style="height: 45px; width: 100%"
                />
                <a id="flogin-show-newp-a" @click="newshowPass" href="/" class="icon2">
                  <i
                    id="flogin-open-eye2"
                    v-bind:class="{
                      'far fa-eye': passShow2,
                      'far fa-eye-slash': !passShow2,
                    }"
                  ></i>
                </a>
              </label>
            </div>
            <div id="flogin-form-gp-confirmp" class="form-group pass-show">
              <p id="flogin-form-gp-confirmp-p">Confirmar nova senha:</p>
              <label id="flogin-label-for-cpass" class="label-input" for="">
                <input
                  name="confirmpassword"
                  id="confirmpassword"
                  placeholder="Confirmar senha"
                  class="formzinho form-control"
                  required
                  v-bind:style="
                    new_passwordError && !bad_email
                      ? 'border: 1px solid red;'
                      : 'height: 45px; width: 100%'
                  "
                  :type="!passShow3 ? 'password' : 'text'"
                  v-model="confirmpassword"
                  style="height: 45px; width: 100%"
                />
                <a id="flogin-showpass-3" @click="confirmshowPass" href="/" class="icon2">
                  <i
                    id="flogin-open-eye3"
                    v-bind:class="{
                      'far fa-eye': passShow3,
                      'far fa-eye-slash': !passShow3,
                    }"
                  ></i>
                </a>
              </label>
              <div>
                <small
                  id="flogin-form-gp-confirmp-small"
                  v-if="!new_passwordError"
                  class="form-text text-muted"
                  style="font-size: 105%"
                  >Mínimo 8 caracteres, 1 maiúscula, 1 minúscula, 1 especial e 1
                  número</small
                >

                <small
                  id="flogin-form-gp-confirmp-small2"
                  v-if="new_passwordError && !bad_email"
                  class="form-text"
                  style="color: red; font-size: 105%"
                  >Mínimo 8 caracteres, 1 maiúscula, 1 minúscula, 1 especial e 1
                  número</small
                >
                <small
                  id="flogin-form-gp-confirmp-small3"
                  class="form-text text-muted"
                  style="font-size: 85%; font-weight: bold"
                  >Exemplo: MMaa#*01</small
                >
              </div>
            </div>

            <button
              id="login-entrar"
              class="btn-login btn-login-pass"
              style="color: white"
            >
              Redefinir senha
            </button>

            <div id="flogin-loading-overlay" v-if="load" class="overlay text-center">
              <i id="flogin-loading-i" class="fas fa-sync-alt fa-spin"></i>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { api } from "../../services/api";
export default {
  name: "FirstLogin",
  data: function () {
    return {
      passwordnew: "",
      passwordconfirm: "",
      passShow: false,
      passShow2: false,
      passShow3: false,
      newpassShow: false,
      oldpassShow: false,
      passkey: false,
      isActive: true,
      isOk: false,
      old_passShow: false,
      email: "",
      new_passwordError: false,
      old_password: "",
      new_password: "",
      password: "",
      confirmpassword: "",
      rememberMe: true,
      Double_factor: false,
      key: "",
    };
  },
  // mounted() {
  //   const email =
  //     localStorage.getItem("rememberMe") === "true"
  //       ? localStorage.getItem("email")
  //       : sessionStorage.getItem("email");

  //   api.get(`/users/${email}`).then((response) => {
  //     this.email = response.data.email;
  //   });
  // },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    year: () => {
      const date = new Date();
      return date.getFullYear();
    },
    bad_email() {
      return this.$store.getters["login/bad_email"];
    },
  },
  created() {
    const { email } = this.$route.params.user;
    this.email = email;
    if (this.$store.getters["saveRememberUser"]) {
      this.email = this.$store.getters["saveUserLogged"];
    }
  },
  methods: {
    newshowPass() {
      event.preventDefault();
      this.passShow2 = !this.passShow2;
    },
    showoldPass() {
      event.preventDefault();
      this.passShow = !this.passShow;
    },
    confirmshowPass() {
      event.preventDefault();
      this.passShow3 = !this.passShow3;
    },
    showkey() {
      this.passkey = !this.passkey;
    },
    btnSingin() {
      this.isActive = false;
      this.isOk = true;
    },
    btnSingup() {
      this.isActive = true;
      this.isOk = false;
    },
    async firstlogin() {
      event.preventDefault();
      this.new_passwordError = false;
      if (this.new_password == this.old_password) {
        this.new_password = "";
        this.confirmpassword = "";
        this.new_passwordError = true;
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          {
            field: "Senha",
            extra: "A nova senha deve ser diferente da senha atual",
          }
        );
        this.new_passwordError = true;
      }
      if (this.new_password != this.confirmpassword) {
        this.new_password = "";
        this.confirmpassword = "";
        this.new_passwordError = true;
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          {
            field: "Senha de Confirmação",
            extra: "A senha de confirmação deve ser igual a nova senha",
          }
        );
        this.new_passwordError = true;
      }
      if (this.new_password == this.confirmpassword && !this.new_passwordError) {
        this.$store.dispatch("login/firstlogin", {
          email: this.email,
          new_password: this.new_password,
          old_password: this.old_password,
          // web_secret_token: process.env.VUE_APP_WEB_SECRET_TOKEN,
        });
        // this.email = "";
      }
      this.new_password = "";
      this.confirmpassword = "";
      this.new_passwordError = true;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card {
  box-shadow: none !important;
}
.font {
  font-family: "Montserrat";
}
.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;
}
.content {
  background-color: #fff;
  border-radius: 5px;
  width: 70vw;
  min-height: 65%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.content::before {
  padding: 30px;
  content: "";
  position: absolute;
  background-color: #021d3b;
  width: 42%;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.title {
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
}
.title-primary {
  color: #fff;
}

.description {
  font-size: 16px;
  font-weight: 300;
  line-height: 10px;
}
.description-primary {
  color: #fff;
}

.btn-login {
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 10px;
  padding: 10px 25px;
  cursor: pointer;
  font-weight: bold;
  width: 150px;
  align-self: center;
  border-width: 0;
  margin-top: 1rem;
  border: none;
}
.btn-login-primary {
  background-color: transparent;
  border: 1px solid #fff;
  transition: background-color 0.5s;
  color: #fff;
}
.btn-login-primary:hover {
  background-color: #021d3b;
  color: #021d3b;
}
.btn-login-pass {
  background-color: #021d3b;
  border: 1px solid black;
  transition: background-color 0.5s;
}

.first-content {
  display: flex;
}

.first-column {
  text-align: center;
  width: 40%;
  z-index: 10;
}

.form {
  display: flex;
  flex-direction: column;
  width: 55%;
}
.password {
  color: #34495e;
  font-size: 14px;
  margin: 15px 0;
  text-align: center;
}
.formzinho {
  border: none;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 5px 12px;
  width: 100%;
}
.label-input {
  display: flex;
  align-items: center;
  margin: 5px;
  position: relative;
  width: 100%;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ecf0f1 inset !important;
  -webkit-text-fill-color: #000 !important;
}
.icon {
  color: #7f8c8d;
  padding: 0 10px;
  font-size: 20px;
}
.icon2 {
  color: #7f8c8d;
  font-size: 18px;
  padding: 0 12px !important;
}

/* second content*/

.second-content {
  position: absolute;
  display: flex;
}
.second-content .first-column {
  order: 2;
  z-index: -1;
}
.second-content .second-column {
  order: 1;
  z-index: -1;
}
.password {
  color: #34495e;
  font-size: 14px;
  margin: 15px 0;
  text-align: center;
}
.password::first-letter {
  text-transform: capitalize;
}

.signinjs .first-content .first-column {
  z-index: -1;
}

.signinjs .second-content .second-column {
  z-index: 11;
}
.signinjs .second-content .first-column {
  z-index: 13;
}

.signinjs .content::before {
  left: 60%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  animation: slidein 1s; /*MODIFIQUEI DE 3s PARA 1.3s*/

  z-index: 12;
}

.signupjs .content::before {
  animation: slideout 1s; /*MODIFIQUEI DE 3s PARA 1.3s*/

  z-index: 12;
}

.signupjs .second-content .first-column,
.signupjs .second-content .second-column {
  z-index: -1;
}

.signupjs .first-content .second-column {
  z-index: 11;
}

.signupjs .first-content .first-column {
  z-index: 13;
}

/* DESLOCAMENTO CONTEÚDO ATRÁS DO CONTENT:BEFORE*/
.signinjs .first-content .second-column {
  z-index: -1;
  position: relative;
  animation: deslocamentoEsq 1.6s; /*MODIFIQUEI DE 3s PARA 1.3s*/
}

.signupjs .second-content .second-column {
  position: relative;
  z-index: -1;
  animation: deslocamentoDir 1.6s; /*MODIFIQUEI DE 3s PARA 1.3s*/
}
.signinjs .first-content .second-column {
  z-index: -1;
  position: relative;
  animation: deslocamentoEsq 1.6s; /*MODIFIQUEI DE 3s PARA 1.3s*/
}

.signupjs .second-content .second-column {
  position: relative;
  z-index: -1;
  animation: deslocamentoDir 1.6s; /*MODIFIQUEI DE 3s PARA 1.3s*/
}

/*ANIMAÇÃOO CSS PARA O CONTEÚDO*/

@keyframes deslocamentoEsq {
  from {
    left: 0;
    opacity: 1;
    z-index: 12;
  }

  25% {
    left: -80px;
    opacity: 0.5;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }
  35% {
    left: -90px;
    opacity: 0.5;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }

  50% {
    left: -100px;
    opacity: 0.2;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }

  to {
    left: -110px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes deslocamentoDir {
  from {
    left: 0;
    z-index: 12;
  }

  25% {
    left: 80px;
    /* z-index: 12;  NÃO HÁ NECESSIDADE*/
  }
  35% {
    left: 90px;
    opacity: 0.5;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }

  50% {
    left: 100px;
    /* z-index: 12; NÃO HÁ NECESSIDADE*/
    /* background-color: yellow;  Exemplo que dei no vídeo*/
  }

  to {
    left: 110px;
    z-index: -1;
  }
}

/*ANIMAÇÃO CSS*/

@keyframes slidein {
  from {
    left: 0;
    width: 40%;
  }

  to {
    left: 60%;
    width: 40%;
  }
}

@keyframes slideout {
  from {
    left: 60%;
    width: 40%;
  }

  25% {
    left: 45%;
    width: 50%;
  }

  50% {
    left: 25%;
    width: 60%;
  }

  75% {
    left: 5%;
    width: 50%;
  }

  to {
    left: 0;
    width: 40%;
  }
}
/*VERSÃO MOBILE*/
@media screen and (max-width: 1040px) {
  .content {
    width: 100%;
    height: 100%;
  }

  .content::before {
    width: 100%;
    height: 40%;
    top: 0;
    border-radius: 0;
  }

  .first-content,
  .second-content {
    flex-direction: column;
    justify-content: space-around;
  }

  .first-column,
  .second-column {
    width: 100%;
  }

  .signinjs .content::before {
    top: 60%;
    left: 0;
    border-radius: 0;
  }

  .form {
    width: 90%;
  }

  /* ANIMAÇÃO MOBILE CSS*/

  @keyframes deslocamentoEsq {
    from {
      top: 0;
      opacity: 1;
      z-index: 12;
    }

    25% {
      top: -80px;
      opacity: 0.5;
      /* z-index: 12; NÃO HÁ NECESSIDADE */
    }

    50% {
      top: -100px;
      opacity: 0.2;
      /* z-index: 12; NÃO HÁ NECESSIDADE */
    }

    to {
      top: -110px;
      opacity: 0;
      z-index: -1;
    }
  }

  @keyframes deslocamentoDir {
    from {
      top: 0;
      z-index: 12;
    }

    25% {
      top: 80px;
      /* z-index: 12;  NÃO HÁ NECESSIDADE*/
    }

    50% {
      top: 100px;
      /* z-index: 12; NÃO HÁ NECESSIDADE*/
      /* background-color: yellow;  Exemplo que dei no vídeo*/
    }

    to {
      top: 110px;
      z-index: -1;
    }
  }

  @keyframes slidein {
    from {
      top: 0;
      height: 40%;
    }

    25% {
      top: 5%;
      height: 50%;
    }

    50% {
      top: 25%;
      height: 60%;
    }

    75% {
      top: 45%;
      height: 50%;
    }

    to {
      top: 60%;
      height: 40%;
    }
  }

  @keyframes slideout {
    from {
      top: 60%;
      height: 40%;
    }

    25% {
      top: 45%;
      height: 50%;
    }

    50% {
      top: 25%;
      height: 60%;
    }

    75% {
      top: 5%;
      height: 50%;
    }

    to {
      top: 0;
      height: 40%;
    }
  }
}

@media screen and (max-width: 740px) {
  .form {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  .form {
    width: 100%;
  }
}
</style>
