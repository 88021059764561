import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";
import router from "../router";

export default {
  namespaced: true,
  state: {
    ids: [],
    app_id: null,
    app_id_result: null,
  },

  mutations: {
    async GET_APP_IDS(state) {
      await api.get(`apps`).then((response) => {
        state.ids = response.data;
      });
    },

    async GET_APP_ID(state, { data }) {
      await api.get(`apps?name=${data.app_id_name}`).then((response) => {
        state.app_id = response.data[0];
      }).catch(() => {
        displayErrMsg(err.response.data.code, "Aplicação", "GET_APP_ID");
      })
    },

    async SAVE_APP_IDS(state, { data }) {
      await api.post(`apps`, data).then((response) => {
        state.app_id_result = response.data.app_id;
        displayErrMsg("GLOBAL_SUCCESS_ADD", "Aplicação", "SAVE_APP_IDS");
      }).catch((err) => {
        displayErrMsg(err.response.data.code, "Aplicação", "SAVE_APP_IDS");
      }) 
    },

    async UPDATE_APP_ID(state, { data }) {
      await api.put(`apps`, data).then((response) => {
        displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Aplicação", "UPDATE_APP_IDS");
        router.push({
          name: "appID",
        });
      }).catch((err) => {
        displayErrMsg(err.response.data.code, "Aplicação", "UPDATE_APP_IDS");
      }) 
    },

    async DELETE_APP_IDS(state, { data }) {
      await api.delete(`apps?name=${data}`).then((response) => {
        state.ids = state.ids.filter((id) => id.name !== data)
        displayErrMsg("GLOBAL_SUCCESS_DELETE", "Aplicação", "DELETE_APP_IDS");
      }).catch((err) => {
        displayErrMsg(err.response.data.code, "Aplicação", "DELETE_APP_IDS");
      }) 
    },
  },
  getters: {
    appIdList: (state) => state.ids,
    app_id: (state) => state.app_id,
    app_id_result: (state) => state.app_id_result,
  },
  actions: {
    getAppIds(context) {
      context.commit("GET_APP_IDS");
    },

    saveAppIds(context, data){
      context.commit("SAVE_APP_IDS", { data });
    },

    deleteAppIds(context, data){
      context.commit("DELETE_APP_IDS", { data });
    },
    updateAppId(context, data){
      context.commit("UPDATE_APP_ID", { data });
    },
    getAppId(context, data) {
      context.commit("GET_APP_ID", { data });
    },
  },
};
